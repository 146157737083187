<template>
  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 option-padding">
    <div class="input-option" v-bind:class="{ selected: selected }">
      <div class="tick-wrapper"></div>
      <span class="tick ticked"></span>

      <div class="image-wrapper">
        <img v-bind:src="genderImage()" alt="mylastwill" />
      </div>
      <div class="person-details">
        <p>{{ name }}</p>
        <p class="relationship">({{ relationship }})</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PersonBlank',
  props: ['name', 'relationship', 'selected', 'gender'],
  computed: {},
  methods: {
    genderImage() {
      if (this.gender === 'Male')
        return 'https://mylastwill.s3.amazonaws.com/static/img/button-male.png'
      return 'https://mylastwill.s3.amazonaws.com/static/img/button-female.png'
    }
  }
}
</script>
