<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
    :background="'bg-personal-details'"
  >
    <template v-slot:contents>
      <div class="app-table fadeIn-2">
        <table class="assets-liabilities-table">
          <thead class="gifts-wrap-title-main">
            <tr>
              <th class="beneficiary-name">Asset/Liability</th>
              <th class="beneficiary-percentage">Total Value</th>
            </tr>
          </thead>
          <tbody>
            <AmountTableRow
              v-if="assets.residential_property"
              :text="
                moving
                  ? 'Current Property<br><span>(Full market value of the property)</span>'
                  : 'Residential Property<br><span>(Full market value of the property)</span>'
              "
              :imageUrl="'https://mylastwill.s3.amazonaws.com/static/img/button-asset-residential.png'"
              :initial="assets.residential_property_amount"
              :increment="10000"
              :property="{
                residential_property_amount: assets.residential_property_amount
              }"
              @change="save"
              @error="errorHandler"
            ></AmountTableRow>
            <AmountTableRow
              v-if="assets.new_residential_property && moving"
              :text="'New Residential Property<br><span>(Full market value of the property that you are purchasing)</span>'"
              :imageUrl="'https://mylastwill.s3.amazonaws.com/static/img/button-asset-residential.png'"
              :initial="assets.new_residential_property_amount"
              :increment="10000"
              :property="{
                new_residential_property_amount:
                  assets.new_residential_property_amount
              }"
              @change="save"
              @error="errorHandler"
            ></AmountTableRow>
            <AmountTableRow
              v-if="assets.household"
              :text="'Household contents'"
              :imageUrl="'https://mylastwill.s3.amazonaws.com/static/img/button-asset-houshold.png'"
              :initial="assets.household_amount"
              :increment="1000"
              :property="{
                household_amount: assets.household_amount
              }"
              @change="save"
              @error="errorHandler"
            ></AmountTableRow>
            <AmountTableRow
              v-if="assets.blt_property"
              :text="'Buy-to-Let Property(s)'"
              :imageUrl="'https://mylastwill.s3.amazonaws.com/static/img/button-asset-btl.png'"
              :initial="assets.blt_property_amount"
              :increment="10000"
              :property="{
                blt_property_amount: assets.blt_property_amount
              }"
              @change="save"
              @error="errorHandler"
            ></AmountTableRow>
            <AmountTableRow
              v-if="assets.savings_bank"
              :text="'Saving/Bank Account(s)'"
              :imageUrl="'https://mylastwill.s3.amazonaws.com/static/img/button-asset-savings.png'"
              :initial="assets.savings_bank_amount"
              :increment="5000"
              :property="{
                savings_bank_amount: assets.savings_bank_amount
              }"
              @change="save"
              @error="errorHandler"
            ></AmountTableRow>
            <AmountTableRow
              v-if="assets.life_insurance"
              :text="'Life Insurance Policies <br><span>(Including policies currently being underwritten)</span>'"
              :imageUrl="'https://mylastwill.s3.amazonaws.com/static/img/button-asset-insurance.png'"
              :initial="assets.life_insurance_amount"
              :increment="10000"
              :property="{
                life_insurance_amount: assets.life_insurance_amount
              }"
              @change="save"
              @error="errorHandler"
            ></AmountTableRow>
            <AmountTableRow
              v-if="assets.vehicle"
              :text="'Vehicle(s)'"
              :imageUrl="'https://mylastwill.s3.amazonaws.com/static/img/button-asset-vehicle.png'"
              :initial="assets.vehicle_amount"
              :increment="5000"
              :property="{
                vehicle_amount: assets.vehicle_amount
              }"
              @change="save"
              @error="errorHandler"
            ></AmountTableRow>
            <AmountTableRow
              v-if="assets.pension"
              :text="'Pension(s) <br><span>(Total fund value, enter 0 if unknown)</span>'"
              :imageUrl="'https://mylastwill.s3.amazonaws.com/static/img/button-asset-pension.png'"
              :initial="assets.pension_amount"
              :increment="10000"
              :property="{
                pension_amount: assets.pension_amount
              }"
              @change="save"
              @error="errorHandler"
            ></AmountTableRow>
            <AmountTableRow
              v-if="assets.shares_investments"
              :text="'Shares and Investments'"
              :imageUrl="'https://mylastwill.s3.amazonaws.com/static/img/button-asset-investments.png'"
              :initial="assets.shares_investments_amount"
              :increment="10000"
              :property="{
                shares_investments_amount: assets.shares_investments_amount
              }"
              @change="save"
              @error="errorHandler"
            ></AmountTableRow>
            <AmountTableRow
              v-if="assets.endowment"
              :text="'Endowment(s)'"
              :imageUrl="'https://mylastwill.s3.amazonaws.com/static/img/button-asset-endowment.png'"
              :initial="assets.endowment_amount"
              :increment="10000"
              :property="{
                endowment_amount: assets.endowment_amount
              }"
              @change="save"
              @error="errorHandler"
            ></AmountTableRow>
            <AmountTableRow
              v-if="assets.business"
              :text="'Business Assets'"
              :imageUrl="'https://mylastwill.s3.amazonaws.com/static/img/button-asset-business.png'"
              :initial="assets.business_amount"
              :increment="10000"
              :property="{
                business_amount: assets.business_amount
              }"
              @change="save"
              @error="errorHandler"
            ></AmountTableRow>
            <AmountTableRow
              v-if="assets.gold_jewellery"
              :text="'Gold/Jewellery'"
              :imageUrl="'https://mylastwill.s3.amazonaws.com/static/img/button-asset-goldvaluables.png'"
              :initial="assets.gold_jewellery_amount"
              :increment="1000"
              :property="{
                gold_jewellery_amount: assets.gold_jewellery_amount
              }"
              @change="save"
              @error="errorHandler"
            ></AmountTableRow>
            <AmountTableRow
              v-if="assets.loans"
              :text="'Bank Loan(s)'"
              :imageUrl="'https://mylastwill.s3.amazonaws.com/static/img/button-liability-loan.png'"
              :initial="assets.loans_amount"
              :increment="5000"
              :property="{
                loans_amount: assets.loans_amount
              }"
              :liability="true"
              @change="save"
              @error="errorHandler"
            ></AmountTableRow>
            <AmountTableRow
              v-if="assets.credit_cards"
              :text="'Credit Card(s)'"
              :imageUrl="'https://mylastwill.s3.amazonaws.com/static/img/button-liability-creditcard.png'"
              :initial="assets.credit_cards_amount"
              :increment="1000"
              :property="{
                credit_cards_amount: assets.credit_cards_amount
              }"
              :liability="true"
              @change="save"
              @error="errorHandler"
            ></AmountTableRow>
            <AmountTableRow
              v-if="assets.residential_mortgage"
              :text="moving ? 'Current Mortgage' : 'Residential Mortgage'"
              :imageUrl="'https://mylastwill.s3.amazonaws.com/static/img/button-liability-mortgage.png'"
              :initial="assets.residential_mortgage_amount"
              :increment="10000"
              :property="{
                residential_mortgage_amount: assets.residential_mortgage_amount
              }"
              :liability="true"
              @change="save"
              @error="errorHandler"
            ></AmountTableRow>

            <AmountTableRow
              v-if="assets.new_residential_mortgage && moving"
              :text="'New Residential Mortgage'"
              :imageUrl="'https://mylastwill.s3.amazonaws.com/static/img/button-liability-mortgage.png'"
              :initial="assets.new_residential_mortgage_amount"
              :increment="10000"
              :property="{
                new_residential_mortgage_amount:
                  assets.mew_residential_mortgage_amount
              }"
              :liability="true"
              @change="save"
              @error="errorHandler"
            ></AmountTableRow>
            <AmountTableRow
              v-if="assets.blt_mortgage"
              :text="'Buy-to-Let Mortgage(s)'"
              :imageUrl="'https://mylastwill.s3.amazonaws.com/static/img/button-liability-btlmortgage.png'"
              :initial="assets.blt_mortgage_amount"
              :increment="10000"
              :property="{
                blt_mortgage_amount: assets.blt_mortgage_amount
              }"
              :liability="true"
              @change="save"
              @error="errorHandler"
            ></AmountTableRow>
          </tbody>
        </table>
      </div>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="backTo"
        v-bind:center="false"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import AmountTableRow from './AmountTableRow'

export default {
  name: 'AssetsAmounts',
  components: {
    NavQuestion,
    NavQuestionButtons,
    AmountTableRow
  },
  computed: {
    assets() {
      return this.$store.getters.assets
    },
    number: () => '3',
    forwardTo() {
      if (
        this.assets.residential_property ||
        this.assets.new_residential_property ||
        this.assets.blt_property ||
        this.assets.savings_bank ||
        this.assets.life_insurance ||
        this.assets.shares_investments ||
        this.assets.endowment ||
        this.assets.residential_mortgage ||
        this.assets.new_residential_mortgagge ||
        this.assets.blt_mortgage
      )
        return '/assets/joint'
      return '/assets/outside_england_wales'
    },
    backTo: () => '/assets/liabilities',
    heading() {
      return 'What are the values of the below assets and liabilities?'
    },
    subHeading() {
      return (
        'These figures help us identify potential tax and administrative issues. We ' +
        'understand that valuing an asset can be difficult and that the value of an asset will change ' +
        'over the years, please just estimate the figures as best possible. Where assets are owned ' +
        'jointly enter the full value of the asset.'
      )
    },
    isValidated() {
      if (Object.values(this.errors).filter((value) => value === true).length)
        return false
      return true
    },
    moving() {
      return this.$store.getters.personalDetails.moving
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('saveAssets')
    next()
  },
  data() {
    return {
      errors: {
        residential_property_amount: null,
        new_residential_property_amount: null,
        household_amount: null,
        blt_property_amount: null,
        savings_bank_amount: null,
        life_insurance_amount: null,
        vehicle_amount: null,
        pension_amount: null,
        shares_investments_amount: null,
        endowment_amount: null,
        business_amount: null,
        gold_jewellery_amount: null,
        loans_amount: null,
        credit_cards_amount: null,
        residential_mortgage_amount: null,
        blt_mortgage_amount: null
      }
    }
  },
  methods: {
    save(value) {
      if (this.assets) {
        this.$store.commit('assets', value)
      }
    },
    errorHandler(data) {
      if (typeof data.residential_property_amount !== 'undefined')
        this.errors.residential_property_amount =
          data.residential_property_amount
      if (typeof data.new_residential_property_amount !== 'undefined')
        this.errors.new_residential_property_amount =
          data.new_residential_property_amount
      if (typeof data.household_amount !== 'undefined')
        this.errors.household_amount = data.household_amount
      if (typeof data.blt_property_amount !== 'undefined')
        this.errors.blt_property_amount = data.blt_property_amount
      if (typeof data.savings_bank_amount !== 'undefined')
        this.errors.savings_bank_amount = data.savings_bank_amount
      if (typeof data.life_insurance_amount !== 'undefined')
        this.errors.life_insurance_amount = data.life_insurance_amount
      if (typeof data.vehicle_amount !== 'undefined')
        this.errors.vehicle_amount = data.vehicle_amount
      if (typeof data.pension_amount !== 'undefined')
        this.errors.pension_amount = data.pension_amount
      if (typeof data.shares_investments_amount !== 'undefined')
        this.errors.shares_investments_amount = data.shares_investments_amount
      if (typeof data.endowment_amount !== 'undefined')
        this.errors.endowment_amount = data.endowment_amount
      if (typeof data.business_amount !== 'undefined')
        this.errors.business_amount = data.business_amount
      if (typeof data.gold_jewellery_amount !== 'undefined')
        this.errors.gold_jewellery_amount = data.gold_jewellery_amount
      if (typeof data.loans_amount !== 'undefined')
        this.errors.loans_amount = data.loans_amount
      if (typeof data.credit_cards_amount !== 'undefined')
        this.errors.credit_cards_amount = data.credit_cards_amount
      if (typeof data.residential_mortgage_amount !== 'undefined')
        this.errors.residential_mortgage_amount =
          data.residential_mortgage_amount
      if (typeof data.blt_mortgage_amount !== 'undefined')
        this.errors.blt_mortgage_amount = data.blt_mortgage_amount
    }
  }
}
</script>

<style></style>
