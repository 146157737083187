import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/store'
import VueNotification from '@kugatsu/vuenotification'
import VueFilter from 'vue-filter'
import VueScrollTo from 'vue-scrollto'

import 'core-js/stable'
import 'regenerator-runtime/runtime'


/*
import '@/assets/css/questionaire.css'

import '@/assets/css/base.css'
import '@/assets/css/grid.css'
import '@/assets/css/normalize.css'
import '@/assets/css/fonts.css'
import '@/assets/css/layouts.css'
import '@/assets/css/ui.css'
 */

Vue.config.productionTip = false

Vue.use(VueNotification, {
  timer: 5,
  showCloseIcn: true,
  position: 'topCenter',
  error: {
    background: '#e56781',
    color: 'white'
  },
  success: {
    background: '#345a7a',
    color: 'white'
  }
})
Vue.use(VueFilter)
Vue.use(VueScrollTo)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  template: '<App/>',
  components: { App },
  router
})
