<template>
  <div class="container-fluid" v-bind:class="background || 'bg-basket'">
    <div class="row">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="question-section">
              <div v-if="number" class="question-section-number">
                <h6>{{ number }}</h6>
                <i class="fa fa-long-arrow-alt-right"></i>
              </div>
              <h2
                v-if="heading"
                class="question-section-question"
                v-html="heading"
              ></h2>
              <div
                v-if="subHeading"
                class="question-section-intro"
                v-bind:class="introClasses"
                v-html="subHeading"
              ></div>
              <div
                class="row"
                v-bind:class="{
                  'question-section-options': questionOptions,
                  'share-section-options': shareOptions,
                  mb60: shareOptions
                }"
              >
                <slot name="contents"></slot>
              </div>
              <slot name="navButtons"></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NavQuestion',
  props: [
    'heading',
    'background',
    'subHeading',
    'number',
    'introClasses',
    'questionOptions',
    'shareOptions'
  ],
  data() {
    return {}
  }
}
</script>

<style></style>
