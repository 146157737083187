var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal is-active",attrs:{"id":_vm.slide}},[_c('div',{class:{ 'modal-background': !_vm.disableBackground },on:{"click":_vm.close}}),_c('div',{staticClass:"modal-card",class:{ 'medium-width': _vm.medium }},[_c('LoadingBar',{attrs:{"loading":_vm.loading}}),_c('header',{staticClass:"modal-card-head"},[_c('h2',{staticClass:"modal-card-title",class:{ center: _vm.center }},[_vm._t("header")],2),(!_vm.disableClose)?_c('button',{staticClass:"modal-close",attrs:{"aria-label":"Close modal","type":"button"},on:{"click":_vm.close}},[_c('svg',{staticStyle:{"display":"block","height":"26px","width":"26px"},attrs:{"viewBox":"0 0 384 512"}},[_c('path',{attrs:{"d":"M217.5 256l137.2-137.2c4.7-4.7 4.7-12.3 0-17l-8.5-8.5c-4.7-4.7-12.3-4.7-17 0L192 230.5 54.8 93.4c-4.7-4.7-12.3-4.7-17 0l-8.5 8.5c-4.7 4.7-4.7 12.3 0 17L166.5 256 29.4 393.2c-4.7 4.7-4.7 12.3 0 17l8.5 8.5c4.7 4.7 12.3 4.7 17 0L192 281.5l137.2 137.2c4.7 4.7 12.3 4.7 17 0l8.5-8.5c4.7-4.7 4.7-12.3 0-17L217.5 256z"}})])]):_vm._e()]),_c('section',{staticClass:"modal-card-body"},[_vm._t("body")],2),(!_vm.splitButtons)?_c('button',{staticClass:"btn btn-arrow modal-card-footer",class:{
        validated: _vm.isValidated,
        disabled: _vm.loading || !_vm.isValidated
      },on:{"click":_vm.save}},[_c('span',{staticClass:"text"},[_vm._t("button")],2),_c('i',{staticClass:"fa fa-arrow-right"})]):_c('div',{staticStyle:{"width":"100%"}},[_c('div',{staticClass:"left"},[_c('button',{staticClass:"btn btn-arrow modal-card-footer",class:{ validated: _vm.isValidated },on:{"click":_vm.cancel}},[_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.declineText)+" ")]),_c('i',{staticClass:"fa fa-arrow-right"})])]),_c('div',{staticClass:"right"},[_c('button',{staticClass:"btn btn-arrow modal-card-footer",class:{
            validated: _vm.isValidated,
            disabled: _vm.loading || !_vm.isValidated
          },on:{"click":_vm.save}},[_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.confirmText)+" ")]),_c('i',{staticClass:"fa fa-arrow-right"})])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }