<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
    :background="'bg-personal-details'"
  >
    <template v-slot:contents>
      <InputOptionSmall
        :letter="'a'"
        :option="'Bank Loan(s)'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-liability-loan.png'"
        :selected="assets.loans"
        @selected="save({ loans: !assets.loans })"
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'b'"
        :option="'Credit Card(s)'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-liability-creditcard.png'"
        :selected="assets.credit_cards"
        @selected="save({ credit_cards: !assets.credit_cards })"
      ></InputOptionSmall>
      <InputOptionSmall
        v-if="assets.residential_property"
        :letter="'c'"
        :option="moving ? 'Current Mortgage' : 'Residential Mortgage'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-liability-mortgage.png'"
        :selected="assets.residential_mortgage"
        @selected="save({ residential_mortgage: !assets.residential_mortgage })"
      ></InputOptionSmall>
      <InputOptionSmall
        v-if="assets.new_residential_property && moving"
        :letter="'d'"
        :option="'New Residential Mortgage'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-liability-mortgage.png'"
        :selected="assets.new_residential_mortgage"
        @selected="
          save({ new_residential_mortgage: !assets.new_residential_mortgage })
        "
      ></InputOptionSmall>
      <InputOptionSmall
        v-if="assets.blt_property"
        :letter="'d'"
        :option="'Buy-to-Let Mortgage(s)'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-liability-btlmortgage.png'"
        :selected="assets.blt_mortgage"
        @selected="save({ blt_mortgage: !assets.blt_mortgage })"
      ></InputOptionSmall>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="backTo"
        v-bind:center="false"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputOptionSmall from '@/common/ui/InputOptionSmall'

export default {
  name: 'AssetsLiabilities',
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputOptionSmall
  },
  computed: {
    number: () => '2',
    forwardTo() {
      if (
        this.assets.blt_property ||
        this.assets.residential_property ||
        this.assets.new_residential_property ||
        this.assets.credit_cards ||
        this.assets.loans ||
        this.assets.blt_property ||
        this.assets.savings_bank ||
        this.assets.life_insurance ||
        this.assets.vehicle ||
        this.assets.pension ||
        this.assets.shares_investments ||
        this.assets.endowment ||
        this.assets.business ||
        this.assets.household ||
        this.assets.gold_jewellery
      ) {
        return '/assets/amounts'
      }
      return '/assets/outside_england_wales'
    },
    backTo: () => '/assets/assets',
    heading() {
      return 'Which of these liabilities do you have?'
    },
    subHeading() {
      return ' We understand that your commitments will change going forwards, please select those that are currently applicable.'
    },
    isValidated() {
      return true
    },
    assets() {
      return this.$store.getters.assets
    },
    moving() {
      return this.$store.getters.personalDetails.moving
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('saveAssets')
    next()
  },
  methods: {
    save(value) {
      if (this.assets) {
        this.$store.commit('assets', value)
      }
    }
  }
}
</script>

<style></style>
