<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
    :background="'bg-personal-details'"
  >
    <template v-slot:contents>
      <InputSwitch
        :checked="assets.assets_outside_uk"
        @toggle="save"
      ></InputSwitch>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="backTo"
        v-bind:center="false"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputSwitch from '@/common/ui/InputSwitch'

export default {
  name: 'AssetsOutsideEnglandWales',
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputSwitch
  },
  computed: {
    assets() {
      return this.$store.getters.assets
    },
    requireJoint() {
      if (
        this.assets.blt_property ||
        this.assets.residential_property ||
        this.assets.residential_property ||
        this.assets.blt_property ||
        this.assets.savings_bank ||
        this.assets.life_insurance ||
        this.assets.shares_investments ||
        this.assets.endowment
      )
        return true
      return false
    },
    requireAmountsUrl() {
      if (
        this.assets.credit_cards ||
        this.assets.loans ||
        this.assets.business ||
        this.assets.household ||
        this.assets.vehicle ||
        this.assets.pension ||
        this.assets.gold_jewellery
      )
        return true
      return false
    },
    requireAmounts() {
      if (
        this.assets.blt_property ||
        this.assets.residential_property ||
        this.assets.residential_property ||
        this.assets.blt_property ||
        this.assets.savings_bank ||
        this.assets.life_insurance ||
        this.assets.shares_investments ||
        this.assets.endowment ||
        this.assets.credit_cards ||
        this.assets.loans ||
        this.assets.business ||
        this.assets.household ||
        this.assets.vehicle ||
        this.assets.pension ||
        this.assets.gold_jewellery
      )
        return true
      return false
    },
    number() {
      var questionNumber = 3
      if (this.assets.residential_property) questionNumber += 1
      if (this.requireJoint) questionNumber += 1
      if (this.requireAmounts) questionNumber += 1
      return questionNumber
    },
    forwardTo() {
      if (this.assets.assets_outside_uk) return '/assets/foreign_assets_country'
      else if (this.assets.business) return '/assets/business_type'
      return '/assets/aka'
    },
    backTo() {
      if (this.assets.residential_property) return '/assets/property_occupants'
      else if (this.requireJoint) return '/assets/joint'
      else if (this.requireAmountsUrl) return '/assets/amounts'
      return '/assets/liabilities'
    },
    heading() {
      return 'Do you own assets outside of the United Kingdom?'
    },
    subHeading() {
      return (
        'MyLastWill is designed to handle assets located in the UK. For assets such as property held in other ' +
        'countries, we strongly suggest seeking advice from a specialist in the country where the assets are located.'
      )
    },
    isValidated() {
      return true
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('saveAssets')
    next()
  },
  data() {
    return {
      show: {
        intro: true
      }
    }
  },
  methods: {
    save(value) {
      if (this.assets) {
        this.$store.commit('assets', {
          assets_outside_uk: !this.assets.assets_outside_uk
        })
      }
    }
  }
}
</script>

<style></style>
