export const countryOptions = {
    data () {
        return {
            countryOptions: [
                {
                    label: 'Afghanistan',
                    code: 'Afghanistan'
                },
                {
                    label: 'Albania',
                    code: 'Albania'
                },
                {
                    label: 'Algeria',
                    code: 'Algeria'
                },
                {
                    label: 'Andorra',
                    code: 'Andorra'
                },
                {
                    label: 'Angola',
                    code: 'Angola'
                },
                {
                    label: 'Anguilla',
                    code: 'Anguilla'
                },
                {
                    label: 'Antigua & Barbuda',
                    code: 'Antigua & Barbuda'
                },
                {
                    label: 'Argentina',
                    code: 'Argentina'
                },
                {
                    label: 'Armenia',
                    code: 'Armenia'
                },
                {
                    label: 'Australia',
                    code: 'Australia'
                },
                {
                    label: 'Austria',
                    code: 'Austria'
                },
                {
                    label: 'Azerbaijan',
                    code: 'Azerbaijan'
                },
                {
                    label: 'Bahamas',
                    code: 'Bahamas'
                },
                {
                    label: 'Bahrain',
                    code: 'Bahrain'
                },
                {
                    label: 'Bangladesh',
                    code: 'Bangladesh'
                },
                {
                    label: 'Barbados',
                    code: 'Barbados'
                },
                {
                    label: 'Belarus',
                    code: 'Belarus'
                },
                {
                    label: 'Belgium',
                    code: 'Belgium'
                },
                {
                    label: 'Belize',
                    code: 'Belize'
                },
                {
                    label: 'Benin',
                    code: 'Benin'
                },
                {
                    label: 'Bermuda',
                    code: 'Bermuda'
                },
                {
                    label: 'Bhutan',
                    code: 'Bhutan'
                },
                {
                    label: 'Bolivia',
                    code: 'Bolivia'
                },
                {
                    label: 'Bosnia & Herzegovina',
                    code: 'Bosnia & Herzegovina'
                },
                {
                    label: 'Botswana',
                    code: 'Botswana'
                },
                {
                    label: 'Brazil',
                    code: 'Brazil'
                },
                {
                    label: 'Brunei Darussalam',
                    code: 'Brunei Darussalam'
                },
                {
                    label: 'Bulgaria',
                    code: 'Bulgaria'
                },
                {
                    label: 'Burkina Faso',
                    code: 'Burkina Faso'
                },
                {
                    label: 'Burundi',
                    code: 'Burundi'
                },
                {
                    label: 'Cambodia',
                    code: 'Cambodia'
                },
                {
                    label: 'Cameroon',
                    code: 'Cameroon'
                },
                {
                    label: 'Canada',
                    code: 'Canada'
                },
                {
                    label: 'Cape Verde',
                    code: 'Cape Verde'
                },
                {
                    label: 'Cayman Islands',
                    code: 'Cayman Islands'
                },
                {
                    label: 'Central African Republic',
                    code: 'Central African Republic'
                },
                {
                    label: 'Chad',
                    code: 'Chad'
                },
                {
                    label: 'Chile',
                    code: 'Chile'
                },
                {
                    label: 'China',
                    code: 'China'
                },
                {
                    label: 'China - Hong Kong / Macau',
                    code: 'China - Hong Kong / Macau'
                },
                {
                    label: 'Colombia',
                    code: 'Colombia'
                },
                {
                    label: 'Comoros',
                    code: 'Comoros'
                },
                {
                    label: 'Congo',
                    code: 'Congo'
                },
                {
                    label: 'Congo, Democratic Republic of (DRC)',
                    code: 'Congo, Democratic Republic of (DRC)'
                },
                {
                    label: 'Costa Rica',
                    code: 'Costa Rica'
                },
                {
                    label: 'Croatia',
                    code: 'Croatia'
                },
                {
                    label: 'Cuba',
                    code: 'Cuba'
                },
                {
                    label: 'Cyprus',
                    code: 'Cyprus'
                },
                {
                    label: 'Czech Republic',
                    code: 'Czech Republic'
                },
                {
                    label: 'Denmark',
                    code: 'Denmark'
                },
                {
                    label: 'Djibouti',
                    code: 'Djibouti'
                },
                {
                    label: 'Dominica',
                    code: 'Dominica'
                },
                {
                    label: 'Dominican Republic',
                    code: 'Dominican Republic'
                },
                {
                    label: 'Ecuador',
                    code: 'Ecuador'
                },
                {
                    label: 'Egypt',
                    code: 'Egypt'
                },
                {
                    label: 'El Salvador',
                    code: 'El Salvador'
                },
                {
                    label: 'England',
                    code: 'England'
                },
                {
                    label: 'Equatorial Guinea',
                    code: 'Equatorial Guinea'
                },
                {
                    label: 'Eritrea',
                    code: 'Eritrea'
                },
                {
                    label: 'Estonia',
                    code: 'Estonia'
                },
                {
                    label: 'Eswatini',
                    code: 'Eswatini'
                },
                {
                    label: 'Ethiopia',
                    code: 'Ethiopia'
                },
                {
                    label: 'Fiji',
                    code: 'Fiji'
                },
                {
                    label: 'Finland',
                    code: 'Finland'
                },
                {
                    label: 'France',
                    code: 'France'
                },
                {
                    label: 'French Guiana',
                    code: 'French Guiana'
                },
                {
                    label: 'Gabon',
                    code: 'Gabon'
                },
                {
                    label: 'Gambia, Republic of The',
                    code: 'Gambia, Republic of The'
                },
                {
                    label: 'Georgia',
                    code: 'Georgia'
                },
                {
                    label: 'Germany',
                    code: 'Germany'
                },
                {
                    label: 'Ghana',
                    code: 'Ghana'
                },
                {
                    label: 'Greece',
                    code: 'Greece'
                },
                {
                    label: 'Grenada',
                    code: 'Grenada'
                },
                {
                    label: 'Guadeloupe',
                    code: 'Guadeloupe'
                },
                {
                    label: 'Guatemala',
                    code: 'Guatemala'
                },
                {
                    label: 'Guinea',
                    code: 'Guinea'
                },
                {
                    label: 'Guinea-Bissau',
                    code: 'Guinea-Bissau'
                },
                {
                    label: 'Guyana',
                    code: 'Guyana'
                },
                {
                    label: 'Haiti',
                    code: 'Haiti'
                },
                {
                    label: 'Honduras',
                    code: 'Honduras'
                },
                {
                    label: 'Hungary',
                    code: 'Hungary'
                },
                {
                    label: 'Iceland',
                    code: 'Iceland'
                },
                {
                    label: 'India',
                    code: 'India'
                },
                {
                    label: 'Indonesia',
                    code: 'Indonesia'
                },
                {
                    label: 'Iran',
                    code: 'Iran'
                },
                {
                    label: 'Iraq',
                    code: 'Iraq'
                },
                {
                    label: 'Israel and the Occupied Territories',
                    code: 'Israel and the Occupied Territories'
                },
                {
                    label: 'Italy',
                    code: 'Italy'
                },
                {
                    label: 'Ivory Coast',
                    code: 'Ivory Coast'
                },
                {
                    label: 'Jamaica',
                    code: 'Jamaica'
                },
                {
                    label: 'Japan',
                    code: 'Japan'
                },
                {
                    label: 'Jordan',
                    code: 'Jordan'
                },
                {
                    label: 'Kazakhstan',
                    code: 'Kazakhstan'
                },
                {
                    label: 'Kenya',
                    code: 'Kenya'
                },
                {
                    label: 'Korea, Democratic Republic of (North Korea)',
                    code: 'Korea, Democratic Republic of (North Korea)'
                },
                {
                    label: 'Korea, Republic of (South Korea)',
                    code: 'Korea, Republic of (South Korea)'
                },
                {
                    label: 'Kosovo',
                    code: 'Kosovo'
                },
                {
                    label: 'Kuwait',
                    code: 'Kuwait'
                },
                {
                    label: 'Kyrgyz Republic (Kyrgyzstan)',
                    code: 'Kyrgyz Republic (Kyrgyzstan)'
                },
                {
                    label: 'Laos',
                    code: 'Laos'
                },
                {
                    label: 'Latvia',
                    code: 'Latvia'
                },
                {
                    label: 'Lebanon',
                    code: 'Lebanon'
                },
                {
                    label: 'Lesotho',
                    code: 'Lesotho'
                },
                {
                    label: 'Liberia',
                    code: 'Liberia'
                },
                {
                    label: 'Libya',
                    code: 'Libya'
                },
                {
                    label: 'Liechtenstein',
                    code: 'Liechtenstein'
                },
                {
                    label: 'Lithuania',
                    code: 'Lithuania'
                },
                {
                    label: 'Luxembourg',
                    code: 'Luxembourg'
                },
                {
                    label: 'Madagascar',
                    code: 'Madagascar'
                },
                {
                    label: 'Malawi',
                    code: 'Malawi'
                },
                {
                    label: 'Malaysia',
                    code: 'Malaysia'
                },
                {
                    label: 'Maldives',
                    code: 'Maldives'
                },
                {
                    label: 'Mali',
                    code: 'Mali'
                },
                {
                    label: 'Malta',
                    code: 'Malta'
                },
                {
                    label: 'Martinique',
                    code: 'Martinique'
                },
                {
                    label: 'Mauritania',
                    code: 'Mauritania'
                },
                {
                    label: 'Mauritius',
                    code: 'Mauritius'
                },
                {
                    label: 'Mayotte',
                    code: 'Mayotte'
                },
                {
                    label: 'Mexico',
                    code: 'Mexico'
                },
                {
                    label: 'Moldova, Republic of',
                    code: 'Moldova, Republic of'
                },
                {
                    label: 'Monaco',
                    code: 'Monaco'
                },
                {
                    label: 'Mongolia',
                    code: 'Mongolia'
                },
                {
                    label: 'Montenegro',
                    code: 'Montenegro'
                },
                {
                    label: 'Montserrat',
                    code: 'Montserrat'
                },
                {
                    label: 'Morocco',
                    code: 'Morocco'
                },
                {
                    label: 'Mozambique',
                    code: 'Mozambique'
                },
                {
                    label: 'Myanmar/Burma',
                    code: 'Myanmar/Burma'
                },
                {
                    label: 'Namibia',
                    code: 'Namibia'
                },
                {
                    label: 'Nepal',
                    code: 'Nepal'
                },
                {
                    label: 'Netherlands',
                    code: 'Netherlands'
                },
                {
                    label: 'New Zealand',
                    code: 'New Zealand'
                },
                {
                    label: 'Nicaragua',
                    code: 'Nicaragua'
                },
                {
                    label: 'Niger',
                    code: 'Niger'
                },
                {
                    label: 'Nigeria',
                    code: 'Nigeria'
                },
                {
                    label: 'North Macedonia, Republic of',
                    code: 'North Macedonia, Republic of'
                },
                {
                    label: 'Northern Ireland',
                    code: 'Northern Ireland'
                },
                {
                    label: 'Norway',
                    code: 'Norway'
                },
                {
                    label: 'Oman',
                    code: 'Oman'
                },
                {
                    label: 'Pacific Islands',
                    code: 'Pacific Islands'
                },
                {
                    label: 'Pakistan',
                    code: 'Pakistan'
                },
                {
                    label: 'Panama',
                    code: 'Panama'
                },
                {
                    label: 'Papua New Guinea',
                    code: 'Papua New Guinea'
                },
                {
                    label: 'Paraguay',
                    code: 'Paraguay'
                },
                {
                    label: 'Peru',
                    code: 'Peru'
                },
                {
                    label: 'Philippines',
                    code: 'Philippines'
                },
                {
                    label: 'Poland',
                    code: 'Poland'
                },
                {
                    label: 'Portugal',
                    code: 'Portugal'
                },
                {
                    label: 'Puerto Rico',
                    code: 'Puerto Rico'
                },
                {
                    label: 'Qatar',
                    code: 'Qatar'
                },
                {
                    label: 'Reunion',
                    code: 'Reunion'
                },
                {
                    label: 'Republic of Ireland',
                    code: 'Republic of Ireland'
                },
                {
                    label: 'Romania',
                    code: 'Romania'
                },
                {
                    label: 'Russian Federation',
                    code: 'Russian Federation'
                },
                {
                    label: 'Rwanda',
                    code: 'Rwanda'
                },
                {
                    label: 'Saint Kitts and Nevis',
                    code: 'Saint Kitts and Nevis'
                },
                {
                    label: 'Saint Lucia',
                    code: 'Saint Lucia'
                },
                {
                    label: 'Saint Vincent and the Grenadines',
                    code: 'Saint Vincent and the Grenadines'
                },
                {
                    label: 'Samoa',
                    code: 'Samoa'
                },
                {
                    label: 'Sao Tome and Principe',
                    code: 'Sao Tome and Principe'
                },
                {
                    label: 'Saudi Arabia',
                    code: 'Saudi Arabia'
                },
                {
                    label: 'Scotland',
                    code: 'Scotland'
                },
                {
                    label: 'Senegal',
                    code: 'Senegal'
                },
                {
                    label: 'Serbia',
                    code: 'Serbia'
                },
                {
                    label: 'Seychelles',
                    code: 'Seychelles'
                },
                {
                    label: 'Sierra Leone',
                    code: 'Sierra Leone'
                },
                {
                    label: 'Singapore',
                    code: 'Singapore'
                },
                {
                    label: 'Slovak Republic (Slovakia)',
                    code: 'Slovak Republic (Slovakia)'
                },
                {
                    label: 'Slovenia',
                    code: 'Slovenia'
                },
                {
                    label: 'Solomon Islands',
                    code: 'Solomon Islands'
                },
                {
                    label: 'Somalia',
                    code: 'Somalia'
                },
                {
                    label: 'South Africa',
                    code: 'South Africa'
                },
                {
                    label: 'South Sudan',
                    code: 'South Sudan'
                },
                {
                    label: 'Spain',
                    code: 'Spain'
                },
                {
                    label: 'Sri Lanka',
                    code: 'Sri Lanka'
                },
                {
                    label: 'Sudan',
                    code: 'Sudan'
                },
                {
                    label: 'Suriname',
                    code: 'Suriname'
                },
                {
                    label: 'Sweden',
                    code: 'Sweden'
                },
                {
                    label: 'Switzerland',
                    code: 'Switzerland'
                },
                {
                    label: 'Syria',
                    code: 'Syria'
                },
                {
                    label: 'Tajikistan',
                    code: 'Tajikistan'
                },
                {
                    label: 'Tanzania',
                    code: 'Tanzania'
                },
                {
                    label: 'Thailand',
                    code: 'Thailand'
                },
                {
                    label: 'Timor Leste',
                    code: 'Timor Leste'
                },
                {
                    label: 'Togo',
                    code: 'Togo'
                },
                {
                    label: 'Trinidad & Tobago',
                    code: 'Trinidad & Tobago'
                },
                {
                    label: 'Tunisia',
                    code: 'Tunisia'
                },
                {
                    label: 'Turkey',
                    code: 'Turkey'
                },
                {
                    label: 'Turkmenistan',
                    code: 'Turkmenistan'
                },
                {
                    label: 'Turks & Caicos Islands',
                    code: 'Turks & Caicos Islands'
                },
                {
                    label: 'Uganda',
                    code: 'Uganda'
                },
                {
                    label: 'Ukraine',
                    code: 'Ukraine'
                },
                {
                    label: 'United Arab Emirates',
                    code: 'United Arab Emirates'
                },
                {
                    label: 'United States of America (USA)',
                    code: 'United States of America (USA)'
                },
                {
                    label: 'Uruguay',
                    code: 'Uruguay'
                },
                {
                    label: 'Uzbekistan',
                    code: 'Uzbekistan'
                },
                {
                    label: 'Venezuela',
                    code: 'Venezuela'
                },
                {
                    label: 'Vietnam',
                    code: 'Vietnam'
                },
                {
                    label: 'Virgin Islands (UK)',
                    code: 'Virgin Islands (UK)'
                },
                {
                    label: 'Virgin Islands (US)',
                    code: 'Virgin Islands (US)'
                },
                {
                    label: 'Wales',
                    code: 'Wales'
                },
                {
                    label: 'Yemen',
                    code: 'Yemen'
                },
                {
                    label: 'Zambia',
                    code: 'Zambia'
                },
                {
                    label: 'Zimbabwe',
                    code: 'Zimbabwe'
                }
            ],
        }
    }
}