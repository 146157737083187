<template>
  <div class="input-container">
    <select
      v-model="relationship"
      v-on:change="$emit('selected', relationship)"
      class="nice-select"
      :required="required"
      :class="{ validation: required }"
      id="relationship"
    >
      <option value selected>Select relationship</option>
      <option
        v-for="(value, index) in options"
        v-bind:key="index"
        v-bind:value="value"
      >
        {{ capitalise(value) }}
      </option>
      <label for="relationship">Relationship</label>
    </select>
  </div>
</template>

<script>
import { TextFormat } from '@/common/mixins/TextFormat'

export default {
  name: 'RelationshipSelect',
  mixins: [TextFormat],
  props: ['rel', 'required'],
  watch: {
    rel: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal && !(newVal in this.baseOptions) && newVal !== 'other')
          this.baseOptions.push(newVal)
        this.relationship = newVal
      }
    }
  },
  data() {
    return {
      relationship: '',
      baseOptions: [
        'friend',
        'mother',
        'father',
        'brother',
        'sister',
        'grandmother',
        'grandfather',
        'uncle',
        'aunt',
        'niece',
        'nephew',
        'grandson',
        'granddaughter',
        'cousin',
        'stepdaughter',
        'stepson',
        'stepfather',
        'stepmother',
        'goddaughter',
        'godson',
        'godmother',
        'godfather'
      ],
      additonalOptions: [
        'daughter',
        'son',
        'mother',
        'father',
        'brother',
        'sister',
        'grandmother',
        'grandfather',
        'uncle',
        'aunt',
        'niece',
        'nephew',
        'grandson',
        'granddaughter',
        'cousin',
        'stepdaughter',
        'stepson',
        'stepfather',
        'stepmother',
        'goddaughter',
        'godson',
        'godmother',
        'godfather'
      ]
    }
  },
  computed: {
    options() {
      var options = []
      this.baseOptions.forEach((el) => options.push(el))
      if (this.partnerRelationship) {
        var prefix = this.partnerRelationship + "'s "
        this.additonalOptions.forEach((el) => options.push(prefix + ' ' + el))
      }
      options.push('other')
      return options
    },
    partnerRelationship() {
      if (this.$store.getters.partner)
        return this.$store.getters.partner.relationship
      else return null
    }
  },
  methods: {}
}
</script>
