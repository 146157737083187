import axios from 'axios'
import store from './store/store'
import router from './router'

let isAlreadyFetchingAccessToken = false

export const http = axios.create({
  baseURL: store.getters.endpoints.baseUrl,
  xhrFields: {
    withCredentials: true
  },
  headers: {
    'Content-Type': 'application/json'
  }
})

export const httpLogin = axios.create({
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
    'Access-Control-Max-Age': '3600',
    'Access-Control-Allow-Headers': 'x-requested-with, accept, content-type'
  }
})

const errorInterceptor = (error) => {
  switch (error.response.status) {
    case 400:
      break
    case 401: // authentication error, logout the user
      if (error.response.data.code === 'user_not_found') {
        store.dispatch('logout')
      } else if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true
        return store
          .dispatch('inspectToken')
          .then((_) => {
            isAlreadyFetchingAccessToken = false
            error.config.headers[
              'Authorization'
            ] = `JWT ${store.getters.tokens.access}`
            return axios.request(error.config)
          })
          .catch((error) => {
            store.dispatch('logout')
            isAlreadyFetchingAccessToken = false
            return error
          })
      }

      store.commit(
        'loginError',
        'Authentication error ' + error.response.data.detail
      )
      break
    case 403: // Incorrect permission
      router.push('/login').catch((err) => {
        console.log(err)
      })
      store.commit(
        'loginError',
        'Incorrect permissions (User must be staff) ' +
          error.response.data.detail
      )
      break
    default:
      console.error('server error')
  }
  return Promise.reject(error)
}

const responseInterceptor = (response) => {
  switch (response.status) {
    case 200:
      // yay!
      break
    // any other cases
    default:
      // default case
      break
  }

  return response
}

http.interceptors.response.use(responseInterceptor, errorInterceptor)
