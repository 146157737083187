<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <InputOptionSmall
        :letter="'a'"
        :option="'Sole Trader'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-sole-trader.png'"
        :selected="assets.business_type === 'sole trader'"
        @selected="save('sole trader')"
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'b'"
        :option="'Partnership'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-partnership.png'"
        :selected="assets.business_type === 'partnership'"
        @selected="save('partnership')"
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'c'"
        :option="'Limited Company'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-limited.png'"
        :selected="assets.business_type === 'limited company'"
        @selected="save('limited company')"
      ></InputOptionSmall>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="backTo"
        v-bind:center="false"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputOptionSmall from '@/common/ui/InputOptionSmall'

export default {
  name: 'AssetsBusinessType',
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputOptionSmall
  },
  computed: {
    assets() {
      return this.$store.getters.assets
    },
    number() {
      var questionNumber = 4
      if (this.assets.residential_property) questionNumber += 1
      if (this.requireJoint) questionNumber += 1
      if (this.requireAmounts) questionNumber += 1
      return questionNumber
    },
    isValidated() {
      if (this.assets.business_type) return true
      return false
    },
    requireJoint() {
      if (
        this.assets.blt_property ||
        this.assets.residential_property ||
        this.assets.residential_property ||
        this.assets.blt_property ||
        this.assets.savings_bank ||
        this.assets.life_insurance ||
        this.assets.shares_investments ||
        this.assets.endowment
      )
        return true
      return false
    },
    requireAmounts() {
      if (
        this.assets.credit_cards ||
        this.assets.loans ||
        this.assets.business ||
        this.assets.household ||
        this.assets.vehicle ||
        this.assets.pension ||
        this.assets.gold_jewellery
      )
        return true
      return false
    },
    heading() {
      return 'What type of business do you own?'
    },
    subHeading() {
      return (
        'From shares in a Limited Company to assets owned by a Sole Trader, most ' +
        'business assets can be passed under a Will. The type of business you own can however ' +
        'influence the way your Will is structured so please clarify the nature of your business below.'
      )
    },
    forwardTo() {
      if (this.assets.business_type === 'limited company')
        return '/assets/business_shares'
      return '/assets/aka'
    },
    backTo() {
      if (this.assets.assets_outside_uk_country && this.assets.assets_outside_uk_country.length) return '/assets/foreign_assets_country'
      return '/assets/outside_england_wales'
    }
  },
  methods: {
    save(value) {
      if (this.assets) {
        this.$store.commit('assets', {
          business_type: value
        })
      }
    }
  }
}
</script>
