<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
    :background="'bg-personal-details'"
  >
    <template v-slot:contents>
      <InputOptionSmall
        :letter="'a'"
        :option="moving ? 'Current Property' : 'Residential Property'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-asset-residential.png'"
        :selected="assets.residential_property"
        @selected="
          saveResidentialProperty({
            residential_property: !assets.residential_property
          })
        "
      ></InputOptionSmall>

      <InputOptionSmall
          v-show="$store.getters.personalDetails.moving"
        :letter="'a2'"
        :key="'movingOption'"
        :option="'New Property'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-asset-residential.png'"
        :selected="assets.new_residential_property"
        @selected="
          saveNewResidentialProperty({
            new_residential_property: !assets.new_residential_property
          })
        "
      />

      <InputOptionSmall
        :letter="'b'"
        :option="'Household Contents'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-asset-houshold.png'"
        :selected="assets.household"
        @selected="save({ household: !assets.household })"
      />
      <InputOptionSmall
        :letter="'c'"
        :option="'Buy To Let Property'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-asset-btl.png'"
        :selected="assets.blt_property"
        @selected="save({ blt_property: !assets.blt_property })"
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'d'"
        :option="'Savings/Bank Account'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-asset-savings.png'"
        :selected="assets.savings_bank"
        @selected="save({ savings_bank: !assets.savings_bank })"
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'e'"
        :option="'Life Insurance'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-asset-insurance.png'"
        :selected="assets.life_insurance"
        @selected="save({ life_insurance: !assets.life_insurance })"
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'f'"
        :option="'Vehicles'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-asset-vehicle.png'"
        :selected="assets.vehicle"
        @selected="save({ vehicle: !assets.vehicle })"
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'g'"
        :option="'Pension'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-asset-pension.png'"
        :selected="assets.pension"
        @selected="save({ pension: !assets.pension })"
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'h'"
        :option="'Shares/Investments'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-asset-investments.png'"
        :selected="assets.shares_investments"
        @selected="save({ shares_investments: !assets.shares_investments })"
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'i'"
        :option="'Endowment'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-asset-endowment.png'"
        :selected="assets.endowment"
        @selected="save({ endowment: !assets.endowment })"
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'j'"
        :option="'Business'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-asset-business.png'"
        :selected="assets.business"
        @selected="save({ business: !assets.business })"
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'k'"
        :option="'Gold/Jewellery'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-asset-goldvaluables.png'"
        :selected="assets.gold_jewellery"
        @selected="save({ gold_jewellery: !assets.gold_jewellery })"
      ></InputOptionSmall>
      <AssetsIntroModal
        v-show="show.intro"
        :show="show.intro"
        @close="show.intro = false"
      ></AssetsIntroModal>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="backTo"
        v-bind:center="false"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputOptionSmall from '@/common/ui/InputOptionSmall'
import AssetsIntroModal from '../IntroModal'

export default {
  name: 'AssetsAssets',
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputOptionSmall,
    AssetsIntroModal
  },
  computed: {
    assets() {
      return this.$store.getters.assets
    },
    number: () => '1',
    forwardTo() {
      return '/assets/liabilities'
    },
    backTo: () => '/home',
    heading() {
      return 'Which of these assets do you own?'
    },
    subHeading() {
      return (
        'The type of assets you own can change the way your Will is structured, please select ' +
        'those that apply from the options below. Dont worry if you have assets of a type that are not listed.'
      )
    },
    isValidated() {
      return true
    },
    moving() {
      return this.$store.getters.personalDetails.moving
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('saveAssets')
    next()
  },
  data() {
    return {
      show: {
        intro: true
      }
    }
  },
  methods: {
    save(value) {
      if (this.assets) {
        this.$store.commit('assets', value)

      }
    },
    saveResidentialProperty(value) {
      console.log('res prop', value)
      // set mortgage to false if does not have a property
      if (!value.residential_property) {
        this.save({
          residential_mortgage: false
        })
      }
      this.save(value)

    },
    saveNewResidentialProperty(value) {
      console.log('new res prop', value)
      // set new mortgage to false if does not have a property
      if (!value.new_residential_property) {
        this.save({
          new_residential_mortgage: false
        })
      }
      this.save(value)

    }
  }
}
</script>

<style></style>
