<template>
  <div class="modal is-active" v-bind:id="slide">
    <div
      v-on:click="close"
      :class="{ 'modal-background': !disableBackground }"
    ></div>
    <div class="modal-card" v-bind:class="{ 'medium-width': medium }">
      <LoadingBar v-bind:loading="loading"></LoadingBar>
      <header class="modal-card-head">
        <h2 class="modal-card-title" v-bind:class="{ center: center }">
          <slot name="header"></slot>
        </h2>
        <button
          v-if="!disableClose"
          v-on:click="close"
          aria-label="Close modal"
          class="modal-close"
          type="button"
        >
          <svg
            style="display: block; height: 26px; width: 26px"
            viewBox="0 0 384 512"
          >
            <path
              d="M217.5 256l137.2-137.2c4.7-4.7 4.7-12.3 0-17l-8.5-8.5c-4.7-4.7-12.3-4.7-17 0L192 230.5 54.8 93.4c-4.7-4.7-12.3-4.7-17 0l-8.5 8.5c-4.7 4.7-4.7 12.3 0 17L166.5 256 29.4 393.2c-4.7 4.7-4.7 12.3 0 17l8.5 8.5c4.7 4.7 12.3 4.7 17 0L192 281.5l137.2 137.2c4.7 4.7 12.3 4.7 17 0l8.5-8.5c4.7-4.7 4.7-12.3 0-17L217.5 256z"
            ></path>
          </svg>
        </button>
      </header>
      <section class="modal-card-body">
        <slot name="body"></slot>
      </section>
      <button
        v-if="!splitButtons"
        v-on:click="save"
        class="btn btn-arrow modal-card-footer"
        v-bind:class="{
          validated: isValidated,
          disabled: loading || !isValidated
        }"
      >
        <span class="text">
          <slot name="button"></slot>
        </span>
        <i class="fa fa-arrow-right"></i>
      </button>
      <div v-else style="width: 100%">
        <div class="left">
          <button
            v-on:click="cancel"
            class="btn btn-arrow modal-card-footer"
            v-bind:class="{ validated: isValidated }"
          >
            <span class="text"> {{ declineText }} </span>
            <i class="fa fa-arrow-right"></i>
          </button>
        </div>
        <div class="right">
          <button
            v-on:click="save"
            class="btn btn-arrow modal-card-footer"
            v-bind:class="{
              validated: isValidated,
              disabled: loading || !isValidated
            }"
          >
            <span class="text"> {{ confirmText }} </span>
            <i class="fa fa-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingBar from './LoadingBar'

export default {
  name: 'BaseModalSmall',
  components: {
    LoadingBar
  },
  // props: [
  //   'isValidated',
  //   'loading',
  //   'medium',
  //   'center',
  //   'noSlide',
  //   'show',
  //   'disableBackground',
  //   'disableClose',
  //   'splitButtons'
  // ],
  props: {
    isValidated: {
      type: Boolean,
      required: false
    },
    loading: {
      type: Boolean,
      requiredL: false
    },
    medium: {
      type: Boolean,
      requiredL: false
    },
    noSlide: {
      type: Boolean,
      required: false
    },
    show: {
      type: Boolean,
      required: false
    },
    center: {
      type: Boolean,
      required: false
    },
    disableBackground: {
      type: Boolean,
      required: false
    },
    disableClose: {
      type: Boolean,
      required: false
    },
    splitButtons: {
      type: Boolean,
      required: false
    },
    confirmText: {
      type: String,
      default: 'Accept'
    },
    declineText: {
      type: String,
      default: 'Decline'
    }
  },
  watch: {
    show: {
      immediate: true,
      handler(value) {
        if (value) {
          document.documentElement.classList.add('is-clipped')
        }
      }
    }
  },
  computed: {
    slide() {
      if (this.noSlide) return ''
      else return 'modal'
    }
  },
  methods: {
    close() {
      this.$emit('close')
      this.clearClipped()
    },
    clearClipped() {
      document.documentElement.classList.remove('is-clipped')
    },
    save() {
      this.$emit('confirm')
      this.clearClipped()
    },
    cancel() {
      this.$emit('cancel')
      this.clearClipped()
    }
  }
}
</script>

<style scoped>
span {
  font-weight: bolder;
}

.left {
  display: inline-block;
  width: 50%;
  padding-right: 2px;
}
.right {
  display: inline-block;
  width: 50%;
  padding-left: 2px;
}
.left .btn {
  width: 100%;
  z-index: 4;
  opacity: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0 0 0 10px;
  background-color: #e56781;
}
.right .btn {
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0 0 10px 0;
  z-index: 4;
  opacity: 1;
}
</style>
