<template>
  <div v-show="showLoading" class="load-bar">
    <div class="bar"></div>
    <div class="bar"></div>
    <div class="bar"></div>
  </div>
</template>

<script>
export default {
  name: 'LoadingBar',
  props: ['loading'],
  watch: {
    loading(value) {
      this.showLoading = value
    }
  },
  data() {
    return {
      showLoading: false
    }
  }
}
</script>

<style scoped>
.load-bar {
  position: relative;
  margin-top: 0px;
  width: 100%;
  height: 4px;
  background-color: #fdba2c;
}
.load-bar .bar {
  content: '';
  display: inline;
  position: absolute;
  width: 0;
  height: 100%;
  left: 50%;
  text-align: center;
}
.load-bar .bar:nth-child(1) {
  background-color: #e56781;
  animation: loading 3s linear infinite;
}
.load-bar .bar:nth-child(2) {
  background-color: #4f9b7e;
  animation: loading 3s linear 1s infinite;
}
.load-bar .bar:nth-child(3) {
  background-color: #6a6a6a;
  animation: loading 3s linear 2s infinite;
}
</style>
