<template>
  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 option-padding">
    <div
      @click="select"
      class="input-option"
      v-bind:class="{ selected: selected, 'pricing-option': pricing }"
    >
      <div class="tick-wrapper"></div>
      <div v-if="!pricing" class="tick"></div>
      <div
        v-if="pricing"
        class="pricing-text"
        v-bind:class="{ package: subPricing }"
      >
        {{ pricing }}
      </div>
      <div v-if="subPricing" class="pricing-text-frequency">
        {{ subPricing }}
      </div>

      <div class="image-wrapper">
        <img v-bind:src="image" />
      </div>
      <div class="text">
        <div class="label">
          <div class="letter">
            <span> {{ letter }}</span>
          </div>
          <div class="caption">
            {{ option }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputOptionSmall',
  props: ['letter', 'option', 'pricing', 'image', 'selected', 'subPricing'],
  methods: {
    select() {
      console.log('selected')
      this.$emit('selected')
    }
  }
}
</script>
