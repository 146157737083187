var render = function render(){var _vm=this,_c=_vm._self._c;return _c('NavQuestion',{attrs:{"heading":_vm.heading,"number":_vm.number,"subHeading":_vm.subHeading,"questionOptions":true,"background":'bg-personal-details'},scopedSlots:_vm._u([{key:"contents",fn:function(){return [_c('div',{staticClass:"app-table fadeIn-1"},[_c('table',{staticClass:"assets-liabilities-table"},[_c('thead',{staticClass:"gifts-wrap-title-main"},[_c('tr',[_c('th',{staticClass:"beneficiary-name"},[_vm._v("Asset/Liability")]),_c('th',{staticClass:"beneficiary-percentage"},[_vm._v("Single/Joint Ownership")])])]),_c('tbody',[(_vm.assets.residential_property)?_c('OwnerTableRow',{attrs:{"initial":_vm.assets.residential_property_owner,"title":_vm.moving ? 'Current Property' : 'Residential Property'},on:{"ownerSet":function($event){return _vm.saveOwner('residential_property_owner', $event)}}}):_vm._e(),(_vm.assets.new_residential_property)?_c('OwnerTableRow',{attrs:{"initial":_vm.assets.new_residential_property_owner,"title":'New Residential Property'},on:{"ownerSet":function($event){return _vm.saveOwner('new_residential_property_owner', $event)}}}):_vm._e(),(_vm.assets.blt_property)?_c('JointTableRow',{attrs:{"text":'Buy-to-Let Property(s)',"imageUrl":'https://mylastwill.s3.amazonaws.com/static/img/button-asset-btl.png',"initial":_vm.assets.blt_property_joint,"property":{
              blt_property_joint: _vm.assets.blt_property_joint
            }},on:{"change":_vm.save}}):_vm._e(),(_vm.assets.savings_bank)?_c('JointTableRow',{attrs:{"text":'Savings/Bank',"imageUrl":'https://mylastwill.s3.amazonaws.com/static/img/button-asset-savings.png',"initial":_vm.assets.savings_bank_joint,"property":{
              savings_bank_joint: _vm.assets.savings_bank_joint
            }},on:{"change":_vm.save}}):_vm._e(),(_vm.assets.life_insurance)?_c('JointTableRow',{attrs:{"text":'Life Insurance Policies',"imageUrl":'https://mylastwill.s3.amazonaws.com/static/img/button-asset-insurance.png',"initial":_vm.assets.life_insurance_joint,"property":{
              life_insurance_joint: _vm.assets.life_insurance_joint
            }},on:{"change":_vm.save}}):_vm._e(),(_vm.assets.shares_investments)?_c('JointTableRow',{attrs:{"text":'Shares and Investment',"imageUrl":'https://mylastwill.s3.amazonaws.com/static/img/button-asset-investments.png',"initial":_vm.assets.shares_investments_joint,"property":{
              shares_investments_joint: _vm.assets.shares_investments_joint
            }},on:{"change":_vm.save}}):_vm._e(),(_vm.assets.endowment)?_c('JointTableRow',{attrs:{"text":'Endowment(s)',"imageUrl":'https://mylastwill.s3.amazonaws.com/static/img/button-asset-endowment.png',"initial":_vm.assets.endowment_joint,"property":{
              endowment_joint: _vm.assets.endowment_joint
            }},on:{"change":_vm.save}}):_vm._e(),(_vm.assets.residential_mortgage)?_c('JointTableRow',{attrs:{"text":_vm.moving ? 'Current Mortgage' : 'Residential Mortgage',"imageUrl":'https://mylastwill.s3.amazonaws.com/static/img/button-liability-mortgage.png',"initial":_vm.assets.residential_mortgage_joint,"property":{
              residential_mortgage_joint: _vm.assets.residential_mortgage_joint
            },"liability":true},on:{"change":_vm.save}}):_vm._e(),(_vm.assets.new_residential_mortgage)?_c('JointTableRow',{attrs:{"text":'New Residential Mortgage',"imageUrl":'https://mylastwill.s3.amazonaws.com/static/img/button-liability-mortgage.png',"initial":_vm.assets.new_residential_mortgage_joint,"property":{
              new_residential_mortgage_joint:
                _vm.assets.new_residential_mortgage_joint
            },"liability":true},on:{"change":_vm.save}}):_vm._e(),(_vm.assets.blt_mortgage)?_c('JointTableRow',{attrs:{"text":'Buy-to-Let Mortgage(s)',"imageUrl":'https://mylastwill.s3.amazonaws.com/static/img/button-liability-btlmortgage.png',"initial":_vm.assets.blt_mortgage_joint,"property":{blt_mortgage_joint: _vm.assets.blt_mortgage_joint},"liability":true},on:{"change":_vm.save}}):_vm._e()],1)])]),(_vm.show.jointWarning && _vm.show.jointWarningCount === 1)?_c('JointWarning',{on:{"close":function($event){_vm.show.jointWarning = false}}}):_vm._e()]},proxy:true},{key:"navButtons",fn:function(){return [_c('NavQuestionButtons',{attrs:{"validated":_vm.isValidated,"forwardText":'Next',"forwardLink":_vm.forwardTo,"backLink":_vm.backTo,"center":false}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }