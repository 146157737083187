<template>
  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 option-padding">
    <div
      v-show="category"
      v-on:click="$emit('edit', person)"
      class="person-edit-dots"
    >
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
    </div>
    <div
      v-on:click="$emit('selected', person)"
      class="input-option"
      v-bind:class="{ selected: category, child: child }"
    >
      <div v-if="!child" class="tick-wrapper"></div>
      <span v-if="!child" class="tick ticked"></span>
      <div
        v-if="child"
        v-on:click="$emit('delete', person)"
        class="delete-person f-right"
      >
        <i class="fa fa-trash-alt"></i>
      </div>
      <div class="image-wrapper">
        <img v-bind:src="genderImage(person)" alt="mylastwill" />
      </div>
      <div class="person-details">
        <p>{{ person.full_name }}</p>
        <p v-if="person.relationship" class="relationship">
          ({{ person.relationship }})
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Person',
  props: ['person', 'type'],
  computed: {
    category() {
      if (this.type === 'executor_primary') return this.person.executor_primary
      if (this.type === 'executor_secondary')
        return this.person.executor_secondary
      if (this.type === 'executor_backup') return this.person.executor_backup
      if (this.type === 'trustee_primary') return this.person.trustee_primary
      if (this.type === 'trustee_secondary')
        return this.person.trustee_secondary
      if (this.type === 'trustee_backup') return this.person.trustee_backup
      if (this.type === 'guardian_primary') return this.person.guardian_primary
      if (this.type === 'guardian_secondary')
        return this.person.guardian_secondary
      if (this.type === 'pets_primary') return this.person.pets_primary
      if (this.type === 'child') return this.person.child
      if (this.type === 'residential_property_occupant')
        return this.person.residential_property_occupant
      return null
    },
    child() {
      if (this.person.child && this.type === 'child') return true
      return false
    }
  },
  methods: {
    genderImage(person) {
      if (person.gender === 'Male')
        return 'https://mylastwill.s3.amazonaws.com/static/img/button-male.png'
      return 'https://mylastwill.s3.amazonaws.com/static/img/button-female.png'
    }
  }
}
</script>

<style></style>
