import { http } from '@/services'

export const sectionDescriptions = {
  computed: {
    id() {
      if (this.data && this.data.id) {
        // for management re-render
        return this.data.id
      } else if (
        this.$store.getters.willPreview &&
        this.$store.getters.willPreview.will_ob.id
      ) {
        return this.$store.getters.willPreview.will_ob.id
      } else {
        return null
      }
    },
    personalDetails() {
      if (
        this.data &&
        this.data.user &&
        this.data.user.personaldetails &&
        this.data.user.personaldetails &&
        this.data.user.personaldetails[0]
      ) {
        // for management re-render
        return this.data.user.personaldetails[0]
      } else if (this.$store.getters.personalDetails) {
        return this.$store.getters.personalDetails
      } else {
        return null
      }
    },
    details() {
      if (
        this.data &&
        this.data.user &&
        this.data.user.details &&
        this.data.user.details[0]
      ) {
        // for management re-render
        return this.data.user.details[0]
      } else if (this.$store.getters.details) {
        return this.$store.getters.details
      } else {
        return null
      }
    },
    people() {
      if (this.peopleInSelectedWill) {
        // for management re-render
        return this.peopleInSelectedWill
      } else if (this.$store.getters.people) {
        return this.$store.getters.people
      } else {
        return null
      }
    },
    funeral() {
      if (
        this.data &&
        this.data.user &&
        this.data.user.details &&
        this.data.user.funeral[0]
      ) {
        // for management re-render
        return this.data.user.funeral[0]
      } else if (this.$store.getters.funeral) {
        return this.$store.getters.funeral
      } else {
        return null
      }
    },
    assets() {
      if (
        this.data &&
        this.data.user &&
        this.data.user.assetsliabilities &&
        this.data.user.assetsliabilities[0]
      ) {
        // for management re-render
        return this.data.user.assetsliabilities[0]
      } else if (this.$store.getters.assets) {
        return this.$store.getters.assets
      } else {
        return null
      }
    },
    charities() {
      if (this.data && this.data.user && this.data.user.charity) {
        // for management re-render
        return this.data.user.charity
      } else if (this.$store.getters.charities) {
        return this.$store.getters.charities
      } else {
        return null
      }
    },
    groups() {
      if (this.data && this.data.user && this.data.user.group) {
        // for management re-render
        return this.data.user.group
      } else if (this.$store.getters.groups) {
        return this.$store.getters.groups
      } else {
        return null
      }
    },
    giftCash() {
      if (this.data && this.data.user && this.data.user.giftcash) {
        // for management re-render
        return this.data.user.giftcash
      } else if (this.$store.getters.giftCash) {
        return this.$store.getters.giftCash
      } else {
        return null
      }
    },
    giftOther() {
      if (this.data && this.data.user && this.data.user.giftother) {
        // for management re-render
        return this.data.user.giftother
      } else if (this.$store.getters.giftOther) {
        return this.$store.getters.giftOther
      } else {
        return null
      }
    },
    giftCharity() {
      if (this.data && this.data.user && this.data.user.giftcharity) {
        // for management re-render
        return this.data.user.giftcharity
      } else if (this.$store.getters.giftCharity) {
        return this.$store.getters.giftCharity
      } else {
        return null
      }
    },
    giftRealEstate() {
      if (this.data && this.data.user && this.data.user.giftrealestate) {
        // for management re-render
        return this.data.user.giftrealestate
      } else if (this.$store.getters.giftRealEstate) {
        return this.$store.getters.giftRealEstate
      } else {
        return null
      }
    },
    giftVehicle() {
      if (this.data && this.data.user && this.data.user.giftvehicle) {
        // for management re-render
        return this.data.user.giftvehicle
      } else if (this.$store.getters.giftVehicle) {
        return this.$store.getters.giftVehicle
      } else {
        return null
      }
    },
    profileDescription() {
      var string =
        'Hello ' +
        this.personalDetails.full_name +
        ' you are creating a Will for ' +
        this.singleJoint() +
        '.  ' +
        'You are ' +
        this.gender() +
        ', born on ' +
        this.personalDetails.dob_day +
        '/' +
        this.personalDetails.dob_month +
        '/' +
        this.personalDetails.dob_year +
        ' and live at ' +
        this.personalDetails.address_line_1 +
        ', ' +
        this.personalDetails.post_code +
        '.  ' +
        'You are '
      if (this.personalDetails.marital_status_single_divorced)
        string += 'divorced'
      else if (this.personalDetails.marital_status_single_widowed)
        string += 'widowed'
      else string += String(this.personalDetails.marital_status).toLowerCase()

      if (this.personalDetails.marital_status !== 'Single') {
        string +=
          ' and your ' +
          this.partnerTitle(this.personalDetails.marital_status) +
          ' name is ' +
          this.personalDetails.partner_full_name +
          ', who is ' +
          String(this.personalDetails.partner_gender).toLowerCase() +
          ', you '
      }

      if (this.personalDetails.children === 'Yes') {
        string += ' have children, and you'
      } else {
        string += ' do not not have children, and you'
      }
      if (this.personalDetails.pets === 'Yes') {
        string += ' also have pets.'
      } else {
        string += ' do not have any pets.'
      }
      return string
    },
    childrenDescription() {
      var string = ''
      var children = this.people.filter(
        (person) => person.child && !person.child_step
      )
      var childrenStep = this.people.filter(
        (person) => person.child && person.child_step
      )
      if (children.length) {
        string +=
          'You have ' +
          this.childCount(children) +
          ', ' +
          this.listPeople(children) +
          '.  This includes all minor and adult children, both biological or adopted.  '
      }

      if (children.length && this.personalDetails.children === 'expecting') {
        string += '  You are also expecting another child/children.'
      }
      if (!children.length && this.personalDetails.children === 'expecting') {
        string += 'You are expecting a child/children.'
      }

      if (childrenStep.length)
        string +=
          'You also have ' +
          this.childCount(childrenStep, true) +
          ', ' +
          this.listPeople(childrenStep) +
          '.'

      return string
    },
    executorsDescription() {
      var solePrimary = this.people.filter(
        (person) => person.executor_sole_primary
      )
      var primary = this.people.filter((person) => person.executor_primary)
      var secondary = this.people.filter((person) => person.executor_secondary)
      var backup = this.people.filter((person) => person.executor_backup)
      var string = ''
      if (this.details.executor_professional)
        string +=
          'Our professional executors have all of the resources and experience necessary to deal with the administration of your estate; providing you with total peace of mind.'
      else if (this.details.executor_professional_and_family) {
        string +=
          'You have appointed our professional executors along with ' +
          this.listPeople(primary) +
          '.'
      } else if (solePrimary.length > 0) {
        string +=
          'You have appointed ' +
          this.span(solePrimary[0].full_name) +
          ' to be your main executor.  If ' +
          this.firstWord(solePrimary[0].full_name) +
          ' is unable or unwilling to act, '
        if (secondary.length < 1)
          string += 'then you have not appointed an alternative.'
        if (secondary.length > 0)
          string +=
            'instead you have appointed ' + this.listPeople(secondary) + '.  '
        if (backup.length > 0)
          string +=
            'You have appointed ' +
            this.listPeople(backup) +
            ' as backup if all else fails.'
      } else if (primary.length > 0) {
        string +=
          'You have appointed ' +
          this.listPeople(primary) +
          ' to be your main executor' +
          (primary.length > 1 ? 's' : '') +
          '.  ' +
          'If ' +
          this.listPeople(primary, ' or ', true) +
          (primary.length > 1 ? ' are ' : ' is ') +
          ' unable or unwilling to act, '
        if (secondary.length > 0)
          string +=
            'instead you have appointed ' + this.listPeople(secondary) + '.'
        else string += 'then you have not appointed an alternative.'
      }
      return string
    },
    trusteesDescription() {
      var solePrimary = this.people.filter(
        (person) => person.trustee_sole_primary
      )
      var primary = this.people.filter((person) => person.trustee_primary)
      var secondary = this.people.filter((person) => person.trustee_secondary)
      var backup = this.people.filter((person) => person.trustee_backup)
      var string = ''

      if (
        this.details &&
        this.details.same_as_executors &&
        !this.details.executor_professional
      )
        string += 'You have appointed your executors as your trustees.'
      else if (
        this.details &&
        this.details.executor_professional &&
        this.details.same_as_executors
      )
        string +=
          'Those have that been appointed to act as your executors will also act as your trustees.'
      else if (solePrimary.length > 0) {
        string +=
          solePrimary[0].full_name +
          ' will be your to be your main trustee.  If ' +
          this.firstWord(solePrimary[0].full_name) +
          ' is unable or unwilling to act, '
        if (secondary.length < 1)
          string += 'then you have not appointed an alternative.'
        if (secondary.length > 0)
          string +=
            'instead you have appointed ' + this.listPeople(secondary) + '. '
        if (backup.length > 0)
          string +=
            'You have appointed ' +
            this.listPeople(backup) +
            ' as backup if all else fails.'
      } else if (primary.length > 0) {
        string +=
          this.listPeople(primary) +
          ' will be your main trustee' +
          (primary.length > 1 ? 's.' : '.') +
          '  If ' +
          this.listPeople(primary, ' or ', true) +
          (primary.length > 1 ? ' are ' : ' is ') +
          ' unable or unwilling to act, '
        if (secondary.length > 0)
          string +=
            'instead you have appointed ' + this.listPeople(secondary) + '.'
        else string += 'then you have not appointed an alternative.'
      }
      return string
    },
    guardiansDescription() {
      var solePrimary = this.people.filter(
        (person) => person.guardian_sole_primary
      )
      var primary = this.people.filter((person) => person.guardian_primary)
      var secondary = this.people.filter((person) => person.guardian_secondary)
      var backup = this.people.filter((person) => person.guardian_backup)
      var string = ''
      if (solePrimary.length > 0) {
        string +=
          'You have appointed ' +
          solePrimary[0].full_name +
          ' to be your main guardians.  If ' +
          this.firstWord(solePrimary[0].full_name) +
          ' is unable or unwilling to act, '
        if (secondary.length < 1)
          string += 'then you have not appointed an alternative.'
        if (secondary.length > 0)
          string +=
            'instead you have appointed ' + this.listPeople(secondary) + '.  '
        if (backup.length > 0)
          string +=
            'You have appointed ' +
            this.listPeople(backup) +
            ' as backup if all else fails.'
      } else {
        string +=
          'You have appointed ' +
          this.listPeople(primary) +
          ' to be your main guardian' +
          (primary.length > 1 ? 's' : '') +
          '.  ' +
          'If ' +
          this.listPeople(primary, ' or ', true) +
          (primary.length > 1 ? ' are ' : ' is ') +
          ' unable or unwilling to act, '
        if (secondary.length > 0)
          string +=
            'instead you have appointed ' + this.listPeople(secondary) + '.'
        else string += 'then you have not appointed an alternative.'
      }
      return string
    },
    distributionDescription() {
      if (this.details.discretionary_trust)
        return 'You have a discretionary trust of residue setup on you Will, to change your distribution please contact us.'
      var solePrimary = this.people.filter(
        (person) => person.distribution_sole_primary
      )
      var primary = this.people.filter((person) => person.distribution_primary)
      var groups = this.groups.filter((group) => group.distribution_primary)
      var charities = this.charities.filter(
        (charity) => charity.distribution_primary
      )
      var backupPeople = this.people.filter(
        (person) => person.distribution_backup
      )
      var backupGroups = this.groups.filter(
        (group) => group.distribution_backup
      )
      var backupCharities = this.charities.filter(
        (charity) => charity.distribution_backup
      )

      var string = ''
      if (solePrimary.length > 0) {
        string +=
          'Your partner ' +
          this.span(solePrimary[0].full_name) +
          ' will inherit all of your estate.  If ' +
          this.heShe(solePrimary[0].gender) +
          ' passes away before you then your estate will be distributed as follows: '
      } else {
        string += 'You estate will be distributed as follows: <br><br>'
      }

      for (var i = 0; i < primary.length; i++) {
        var person = primary[i]
        if (i === 0) string += 'You will leave '
        if (i !== 0 && i !== primary.length - 1) string += ', '
        if (i === primary.length - 1 && i !== 0) string += ' and '
        string +=
          this.span(person.distribution_primary_percentage + '%') +
          ' to ' +
          this.span(person.full_name)
        if (person.distribution_backup_children)
          string +=
            ' with ' +
            this.hisHer(person.gender) +
            ' children as ' +
            this.hisHer(person.gender) +
            ' substitute beneficiaries'
        else if (person.distribution_backup_other_beneficiaries)
          string +=
            ' with the other beneficiaries as ' +
            this.hisHer(person.gender) +
            ' backup'
        else if (person.distribution_backup_person)
          string +=
            ' with ' +
            this.span(person.distribution_backup_person.full_name) +
            ' as the substitute beneficiary'
        else if (person.distribution_backup_group)
          string +=
            ' with ' +
            this.relationYour(person.distribution_backup_group) +
            ' as substitute beneficiaries'
        if (i === primary.length - 1) string += '.  <br><br>'
      }

      for (var g = 0; g < groups.length; g++) {
        var group = groups[g]
        if (g === 0) string += 'You will leave '
        if (g !== 0 && g !== groups.length - 1) string += ', '
        if (g === groups.length - 1 && g !== 0) string += ' and '
        string +=
          this.span(group.distribution_percentage + '%') +
          ' to ' +
          this.span(this.relationYour(group.group_name)) +
          ', if one of ' +
          this.relationYour(group.group_name) +
          ' passed away before you then '
        if (group.distribution_backup_one_children)
          string += this.span(' their children')
        else string += this.span(' the remaining ') + group.group_name

        string += ' would benefit'
        if (g === groups.length - 1) string += '.  <br><br>'
      }

      for (var c = 0; c < charities.length; c++) {
        var charity = charities[c]
        if (c === 0) string += 'You will leave '
        if (c !== 0 && g !== charities.length - 1) string += ', '
        if (c === charities.length - 1 && c !== 0) string += ' and '
        string +=
          this.span(charity.distribution_percentage + '%') +
          ' to ' +
          this.span(charity.charity_name)
        if (c === charities.length - 1) string += '. <br><br> '
      }

      string +=
        'If a minor were to benefit then they will receive their inheritance at the age of '
      if (this.details && this.details.distribution_minor_18)
        string += this.span('18')
      else if (this.details && this.details.distribution_minor_21)
        string += this.span('21')
      else if (this.details && this.details.distribution_minor_25)
        string += this.span('25')
      string +=
        ' years old, and if all else fails you will distribute as follows: '
      if (this.details && this.details.distribution_all_die_backup_table) {
        for (var bp = 0; bp < backupPeople.length; bp++) {
          var backupPerson = backupPeople[bp]
          if (bp === 0) string += 'You will leave '
          if (bp !== 0 && bp !== backupPeople.length - 1) string += ', '
          if (bp === backupPeople.length - 1 && bp !== 0) string += ' and '
          string +=
            this.span(backupPerson.distribution_backup_percentage + '%') +
            ' to ' +
            this.firstWord(backupPerson.full_name)
          if (bp === backupPeople.length - 1) string += '.  '
        }

        for (var bg = 0; bg < backupGroups.length; bg++) {
          var backupGroup = backupGroups[bg]
          if (bg === 0) string += 'You will leave '
          if (bg !== 0 && bg !== backupGroups.length - 1) string += ', '
          if (bg === backupGroups.length - 1 && bg !== 0) string += ' and '
          string +=
            this.span(backupGroup.distribution_backup_percentage + '%') +
            ' to ' +
            this.span(this.relationYour(backupGroup.group_name))
          if (bg === backupGroups.length - 1) string += '.  '
        }

        for (var bc = 0; bc < backupCharities.length; bc++) {
          var backupCharity = backupCharities[bc]
          if (bc === 0) string += 'You will leave '
          if (bc !== 0 && bc !== backupCharities.length - 1) string += ', '
          if (bc === backupCharities.length - 1 && bc !== 0) string += ' and '
          string +=
            this.span(backupCharity.distribution_backup_percentage + '%') +
            ' to ' +
            this.span(backupCharity.charity_name)
          if (bc === backupCharities.length - 1) string += '.  '
        }
      } else {
        string +=
          'your closest blood relatives will inherit. If no blood relatives can be traced, your estate will pass to the Crown.'
      }

      return string
    },
    petsDescription() {
      var string = ''
      var petsSole = this.people.filter((person) => person.pets_sole_primary)
      var petsPrimary = this.people.filter((person) => person.pets_primary)

      if (this.details && this.details.pets_yes_no === false) {
        string +=
          'You have chosen not to provide details for the provisions for your pets.'
      } else {
        if (petsSole.length > 0) {
          string +=
            'You have appointed your ' +
            petsSole[0].relationship +
            ' ' +
            this.span(petsSole[0].full_name) +
            ' to take care of your pets. '
          string +=
            'If ' +
            this.firstWord(petsSole[0].full_name) +
            ' is unable or unwilling to act, '
          if (petsPrimary.length > 0) {
            string += 'instead you have appointed '
            string += this.listPeople(petsPrimary) + '.'
          } else {
            string += 'then you have not appointed an alternative.'
          }
        } else if (petsPrimary.length > 0) {
          string += 'You have appointed '
          string += this.listPeople(petsPrimary)
          string += ' to look after your pets.'
        }
      }

      if (this.details && this.details.amount !== 0)
        string +=
          '  You have left ' +
          this.currency(this.details.amount) +
          ' towards the care of your pets.'
      else string += '  You have not left any money for the care of your pets.'
      return string
    },
    assetsDescription() {
      var string = ''
      if (this.assets.known_by_other_name !== null)
        string +=
          'You have left details of any assets or liabilities that you have. '
      if (this.assets.known_by_other_name === 'yes')
        string +=
          'You have assets in your other known name ' +
          this.assets.other_name +
          '.'
      return string
    },
    funeralDescription() {
      var string = ''
      if (this.funeral.funeral_finish === 'No Preference')
        string +=
          'You wish to let your executors decide on either a burial or cremation, '
      else
        string +=
          'You wish to be ' + this.span(this.funeral.funeral_finish) + ', '
      if (this.funeral.funeral_type_no_preference)
        string +=
          'you also have no preference on the type of funeral you would like.'
      else {
        string += 'and you wish for your funeral to be'
        if (this.funeral.funeral_type_celebration)
          string += this.span(" 'a celebration'") + ', '
        if (this.funeral.funeral_type_simple)
          string += this.span(" 'simple'") + ', '
        if (this.funeral.funeral_type_green)
          string += this.span(" 'green'") + ', '
        if (this.funeral.funeral_type_grand)
          string += this.span(" 'a grand send off'") + ', '
        if (this.funeral.funeral_type_non_religious)
          string += this.span(" 'non religious'") + ', '
        string += 'in style. <br><br>'
      }
      if (this.funeral.funeral_organs === 'No')
        string += 'You do ' + this.span('not') + ' wish to be a organ donor. '
      else {
        if (this.funeral.funeral_organs === 'Yes, for therapy')
          string +=
            'You wish to be an organ donor for therapeutic purposes only. '
        else if (this.funeral.funeral_organs === 'Yes, for research')
          string += 'You wish to be an organ donor for research purposes only. '
        else if (this.funeral.funeral_organs === 'Yes, for research & therapy')
          string +=
            'You wish to be an organ donor for both therapeutic and research purposes. '
      }
      if (this.funeral.funeral_wishes)
        string +=
          'Your additional directions are "' +
          this.funeral.funeral_wishes +
          '". '
      return string
    },
    giftsDescription() {
      var giftsCashBefore = this.giftCash.filter(
        (gift) => gift.gift_before_partner
      )
      var giftsCashAfter = this.giftCash.filter(
        (gift) => gift.gift_after_partner
      )
      var giftsRealEstateBefore = this.giftRealEstate.filter(
        (gift) => gift.gift_before_partner
      )
      var giftsRealEstateAfter = this.giftRealEstate.filter(
        (gift) => gift.gift_after_partner
      )
      var giftOtherBefore = this.giftOther.filter(
        (gift) => gift.gift_before_partner
      )
      var giftOtherAfter = this.giftOther.filter(
        (gift) => gift.gift_after_partner
      )
      var giftVehicleBefore = this.giftVehicle.filter(
        (gift) => gift.gift_before_partner
      )
      var giftVehicleAfter = this.giftVehicle.filter(
        (gift) => gift.gift_after_partner
      )
      var giftCharityBefore = this.giftCharity.filter(
        (gift) => gift.gift_before_partner
      )
      var giftCharityAfter = this.giftCharity.filter(
        (gift) => gift.gift_after_partner
      )

      var partner = this.people.filter((person) => person.partner)

      var string = ''
      if (this.details.gifts) {
        if (
          (this.details.gifts_before || this.details.gifts_after) &&
          (giftsCashBefore.length > 0 ||
            giftsCashAfter.length > 0 ||
            giftsRealEstateBefore.length > 0 ||
            giftsRealEstateAfter.length > 0 ||
            giftOtherBefore.length > 0 ||
            giftOtherAfter.length > 0 ||
            giftVehicleBefore.length > 0 ||
            giftVehicleAfter.length > 0 ||
            giftCharityBefore.length > 0 ||
            giftCharityAfter.length > 0)
        ) {
          if (
            this.details.gifts_before &&
            (giftsCashBefore.length > 0 ||
              giftsRealEstateBefore.length > 0 ||
              giftOtherBefore.length > 0 ||
              giftVehicleBefore.length > 0 ||
              giftCharityBefore.length > 0)
          ) {
            string += 'You have left: '
            if (giftsCashBefore.length > 0)
              string += this.listCashGift(giftsCashBefore) + '. '
            if (giftsRealEstateBefore.length > 0)
              string += this.listRealEstateGift(giftsRealEstateBefore) + '. '
            if (giftOtherBefore.length > 0)
              string += this.listOtherGifts(giftOtherBefore) + '. '
            if (giftVehicleBefore.length > 0)
              string += this.listVehicleGifts(giftVehicleBefore) + '. '
            if (giftCharityBefore.length > 0)
              string += this.listCharityGifts(giftCharityBefore) + '. '
          }
          if (this.details.gifts_before && partner.length > 0)
            string +=
              'These will pass even if your partner ' +
              partner[0].full_name +
              ' is still alive. <br><br>'
          if (
            partner.length > 0 &&
            this.details.gifts_after &&
            (giftsCashAfter.length > 0 ||
              giftsRealEstateAfter.length > 0 ||
              giftOtherAfter.length > 0 ||
              giftVehicleAfter.length > 0 ||
              giftCharityAfter.length > 0)
          ) {
            string +=
              ' If ' +
              partner[0].full_name +
              ' has passed away with or before you, then you have left: '
            if (giftsCashAfter.length > 0)
              string += this.listCashGift(giftsCashAfter) + '. '
            if (giftsRealEstateAfter.length > 0)
              string += this.listRealEstateGift(giftsRealEstateAfter) + '. '
            if (giftOtherAfter.length > 0)
              string += this.listOtherGifts(giftOtherAfter) + '. '
            if (giftVehicleAfter.length > 0)
              string += this.listVehicleGifts(giftVehicleAfter) + '. '
            if (giftCharityAfter.length > 0)
              string += this.listCharityGifts(giftCharityAfter) + '. '
          }
        } else {
          string +=
            'You have indicated that you would like to leave gifts, but have not yet done so.'
        }
      } else {
        string += 'You have chosen not to leave any specific gifts.'
      }
      return string
    },
    profileInitialDescription() {
      if (this.personalDetails.full_name && !this.personalDetails.dob_day)
        return 'Date of Birth not set'
      return 'A little basic information about yourself'
    }
  },
  data() {
    return {
      childrenInitialDescription:
        'Children are often named in their parents Will we ask you to start by adding their names',
      executorsInitialDescription:
        'An executors role is to locate, manage and disburse the assets of the estate according to your Will.  It is a role of significant responsibility as Executors are personally liable for any mistakes or incorrect distributions.',
      trusteesInitialDescription:
        'Where assets are left to minor (a child or grandchild for example) it is the Trustees role to look after and invest the assets until the beneficiary comes of age.',
      guardiansInitialDescription:
        ' Guardians become responsible for the day-to-day care of any minor children. They must make decisions on behalf of your child with regard to their upbringing, schooling and anything else a parent would otherwise deal with.',
      distributionInitialDescription:
        'Here you will clearly identify your residuary beneficiaries. A Residuary Beneficiary is someone who is given the remainder of your estate once all the funeral expenses, debts, taxes and gifts have been paid out.',
      petsInitialDescription:
        'To help ensure your pets always have a home to go to, details of who should look after any animals can be included in your Will.',
      assetsInitialDescription:
        'Whilst a Will simply deals with everything that you own, identifying the assets (and liabilities) that make up your estate helps us to create your Will.',
      funeralInitialDescription:
        'By setting out your wishes now, when the time comes your loved ones will know what you wanted and be spared from having to make difficult decisions.',
      giftsInitialDescription:
        'Before an estate is divided up, people often leave gifts to people or charity. Almost anything can be gifted, from personal items to properties.'
    }
  },
  methods: {
    childCount(children, step = false) {
      var string = ''
      string += children.length
      if (step) string += ' step'
      if (children.length === 1) string += ' child'
      else string += ' children'
      return string
    },
    listCashGift(gifts) {
      var string = ''
      for (var i = 0; i < gifts.length; i++) {
        var gift = gifts[i]
        if (i !== 0 && i !== gifts.length - 1) string += ', '
        if (i === gifts.length - 1 && i !== 0) string += ' and '
        string += this.span('£' + gift.amount.toLocaleString()) + ' to '
        if (gift.beneficiary) string += this.span(gift.beneficiary.full_name)
        else {
          if (gift.beneficiary_group_share === 'each')
            string += 'each of ' + this.relationYour(gift.beneficiary_group)
          else
            string +=
              ' to be shared between ' +
              this.relationYour(gift.beneficiary_group)
        }
      }
      return string
    },
    listRealEstateGift(gifts) {
      var string = ''
      for (var i = 0; i < gifts.length; i++) {
        var gift = gifts[i]
        if (i !== 0 && i !== gifts.length - 1) string += ', '
        if (i === gifts.length - 1 && i !== 0) string += ' and '
        string +=
          this.span(
            'Property gift ' + gift.address_line_1 + ', ' + gift.post_code
          ) + ' to '
        if (gift.beneficiary) string += this.span(gift.beneficiary.full_name)
        else string += this.relationYour(gift.beneficiary_group)
      }
      return string
    },
    listOtherGifts(gifts) {
      var string = ''
      for (var i = 0; i < gifts.length; i++) {
        var gift = gifts[i]
        if (i !== 0 && i !== gifts.length - 1) string += ', '
        if (i === gifts.length - 1 && i !== 0) string += ' and '
        if (i === 0) string += 'Your '
        else string += 'your '
        string += this.span(gift.item) + ' to '
        if (gift.beneficiary) string += this.span(gift.beneficiary.full_name)
        else string += this.relationYour(gift.beneficiary_group)
      }
      return string
    },
    listVehicleGifts(gifts) {
      var string = ''
      for (var i = 0; i < gifts.length; i++) {
        var gift = gifts[i]
        if (i !== 0 && i !== gifts.length - 1) string += ', '
        if (i === gifts.length - 1 && i !== 0) string += ' and '
        if (i === 0) string += 'Your '
        else string += 'your '
        string +=
          this.span(gift.model) + ' to ' + this.span(gift.beneficiary.full_name)
      }
      return string
    },
    listCharityGifts(gifts) {
      var string = ''
      for (var i = 0; i < gifts.length; i++) {
        var gift = gifts[i]
        if (i !== 0 && i !== gifts.length - 1) string += ', '
        if (i === gifts.length - 1 && i !== 0) string += ' and '
        string += this.span('£' + gift.amount.toLocaleString()) + ' to '
        string += this.span(gift.charity_name)
      }
      return string
    },
    async saveSectionDescriptions() {
      var data = {
        id: this.id,
        profile_description: this.profileDescription,
        children_description: this.childrenDescription,
        executors_description: this.executorsDescription,
        trustees_description: this.trusteesDescription,
        guardians_description: this.guardiansDescription,
        distribution_description: this.distributionDescription,
        pets_description: this.petsDescription,
        funeral_description: this.funeralDescription,
        assets_description: this.assetsDescription,
        gifts_description: this.giftsDescription
      }
      http
        .post('/wills/api/section_descriptions', data)
        .then(() => {
          return true
        })
        .catch((error) => {
          console.log(error)
          return false
        })
    }
  }
}
