var moment = require('moment')

const getters = {
  // authentication
  endpoints: (state) => {
    return state.endpoints
  },
  tokens: (state) => {
    return state.tokens
  },
  loginError: (state) => {
    return state.loginError
  },

  // other
  user: (state) => state.user,
  sidebar: (state) => state.sidebar,
  sidebarHover: (state) => state.sidebarHover,
  sidebarMobile: (state) => state.sidebarMobile,
  progress: (state) => state.userDetails.progress,
  communication: (state) => state.communication,
  personalDetails: (state) => state.personalDetails,
  willPreview: (state) => state.willPreview,
  checkoutBasket: (state) => state.checkoutBasket,
  prices: (state) => state.prices,
  checkoutProgress: (state) => state.checkoutProgress,
  sharePeople: (state, getters) => {
    return getters.people.filter(
      (person) =>
        person.executor_sole_primary ||
        person.executor_primary ||
        person.executor_secondary ||
        person.executor_backup ||
        person.trustee_sole_primary ||
        person.trustee_primary ||
        person.trustee_secondary ||
        person.trustee_backup ||
        person.guardian_sole_primary ||
        person.guardian_primary ||
        person.guardian_secondary ||
        person.guardian_backup
    )
  },
  shareBrokerPeopleOptions: (state, getters) => {
    return getters.people.filter(
      (person) =>
        !person.partner &&
        (person.executor_primary ||
          person.executor_secondary ||
          person.executor_backup ||
          person.trustee_primary ||
          person.trustee_secondary ||
          person.trustee_backup)
    )
  },
  advice: (state) => state.advice,
  adviceNew: (state) => state.adviceNew,
  documents: (state) => state.documents,
  willUpdate: (state, getters) => {
    if (getters.documents.length) return true
    return false
  },
  people: (state) => state.people,
  peopleDistributionPrimary: (state, getters) => {
    return getters.people.filter((person) => person.distribution_primary)
  },
  partner: (state, getters) => {
    var partner = getters.people.filter(
      (person) => person.partner && !person.partner_ex
    )
    if (partner.length) return partner[0]
    return false
  },
  partner_ex: (state, getters) => {
    var ex = getters.people.filter(
      (person) => !person.partner && person.partner_ex
    )
    if (ex.length) return ex[0]
    return false
  },
  children: (state, getters) => {
    var children = getters.people.filter((person) => person.child)
    if (children.length) return children
    return false
  },
  groups: (state) => state.groups,
  groupsDistributionPrimary: (state, getters) => {
    return getters.groups.filter((group) => group.distribution_primary)
  },
  charities: (state) => state.charities,
  charitiesDistributionPrimary: (state, getters) => {
    return getters.charities.filter((charity) => charity.distribution_primary)
  },
  charitiesDistributionBackup: (state, getters) => {
    return getters.charities.filter((charity) => charity.distribution_backup)
  },
  funeral: (state) => state.funeral,
  assets: (state) => state.assets,
  details: (state) => state.details,
  giftOther: (state) => state.giftOther,
  giftVehicle: (state) => state.giftVehicle,
  giftCash: (state) => state.giftCash,
  giftRealEstate: (state) => state.giftRealEstate,
  giftCharity: (state) => state.giftCharity,
  showIntroModals: (state) => state.showIntroModals,
  progressChildren: (state, getters) => {
    var data = {
      childrenRequired: true,
      childrenCompleted: false
    }
    if (getters.personalDetails && getters.personalDetails.children === 'No') {
      data.childrenRequired = false
    }
    if (
      getters.people.filter(
        (person) => person.child === true || person.child_step === true
      ).length > 0 ||
      getters.personalDetails.children === 'expecting'
    )
      data.childrenCompleted = true
    return data
  },
  progressGuardians: (state, getters) => {
    var data = {
      guardiansRequired: false,
      guardiansCompleted: false
    }
    if (getters.personalDetails && getters.personalDetails.children !== 'No') {
      if (getters.personalDetails.country !== 'Scotland') {
        if (
          getters.people.filter(
            (person) =>
              person.child === true &&
              person.child_step === false &&
              person.under18 === true
          ).length > 0 ||
          getters.personalDetails.children === 'expecting'
        ) {
          data.guardiansRequired = true
        }
      } else {
        if (
          getters.people.filter(
            (person) =>
              person.child &&
              !person.child_step &&
              moment().diff(
                new Date(person.dob_year, person.dob_month, person.dob_day),
                'years'
              ) < 16
          ).length > 0 ||
          getters.personalDetails.children === 'expecting'
        ) {
          data.guardiansRequired = true
        }
      }

      if (
        getters.people.filter(
          (person) =>
            person.guardian_primary === true ||
            person.guardian_sole_primary === true
        ).length > 0
      ) {
        data.guardiansCompleted = true
      }
    }
    return data
  },
  progressTrustees: (state, getters) => {
    var data = {
      trusteesRequired: false,
      trusteesCompleted: false
    }
    if (getters.personalDetails && getters.personalDetails.children !== 'No') {
      if (
        getters.people.filter(
          (person) =>
            person.child === true &&
            person.child_step === false &&
            person.under18 === true
        ).length > 0 ||
        getters.personalDetails.children === 'expecting'
      ) {
        data.trusteesRequired = true
      }
    }
    if (
      (getters.people.filter((person) => person.trustee_sole_primary).length >
        0 &&
        getters.people.filter((person) => person.trustee_secondary).length >
          0) ||
      getters.people.filter((person) => person.trustee_primary).length > 0 ||
      (getters.details && getters.details.same_as_executors === true)
    ) {
      data.trusteesCompleted = true
    }
    return data
  },
  progressExecutors: (state, getters) => {
    var data = {
      executorsCompleted: false
    }
    if (
      (getters.people &&
        getters.people.filter((person) => person.executor_sole_primary).length >
          0 &&
        getters.people &&
        getters.people.filter((person) => person.executor_secondary).length >
          0) ||
      (getters.people &&
        getters.people.filter((person) => person.executor_primary).length >
          0) ||
      (getters.details && getters.details.executor_professional === true)
    )
      data.executorsCompleted = true
    return data
  },
  progressFuneral: (state, getters) => {
    var data = {
      funeralCompleted: false
    }
    if (getters.funeral && getters.funeral.funeral_finish)
      data.funeralCompleted = true
    return data
  },
  progressProfile: (state, getters) => {
    var data = {
      profileCompleted: false
    }
    if (
      getters.personalDetails &&
      getters.personalDetails.full_name &&
      getters.personalDetails.dob_day &&
      getters.personalDetails.dob_month
    )
      data.profileCompleted = true
    return data
  },
  progressGifts: (state, getters) => {
    var data = {
      giftsCompleted: false
    }
    if (
      (getters.giftCash && getters.giftCash.length > 0) ||
      (getters.giftRealEstate && getters.giftRealEstate.length > 0) ||
      (getters.giftCharity && getters.giftCharity.length > 0) ||
      (getters.giftOther && getters.giftOther.length > 0) ||
      (getters.giftVehicle && getters.giftVehicle.length > 0) ||
      (getters.details && getters.details.gifts === false)
    ) {
      data.giftsCompleted = true
    }
    return data
  },
  progressPets: (state, getters) => {
    var data = {
      petsCompleted: false,
      petsRequired: false,
      percentage: 0
    }
    if (getters.personalDetails.pets === 'Yes') {
      data.petsRequired = true
      if (
        (getters.people &&
          getters.people.filter(
            (person) =>
              person.pets_sole_primary === true || person.pets_primary === true
          ).length > 0) ||
        (getters.details && getters.details.pets_yes_no === false)
      ) {
        data.petsCompleted = true
      }
    }
    return data
  },
  progressAssets: (state, getters) => {
    var data = {
      assetsCompleted: false
    }
    if (getters.assets && getters.assets.known_by_other_name !== null)
      data.assetsCompleted = true
    return data
  },
  progressDistribution: (state, getters) => {
    var data = {
      distributionCompleted: false
    }
    var distributionPrimaryPeople = getters.people.filter(
      (person) => person.distribution_primary === true
    )
    var distributionPrimaryGroups = getters.groups.filter(
      (group) => group.distribution_primary === true
    )
    var distributionPrimaryCharities = getters.charitiesDistributionPrimary

    var distributionBackupPeople = getters.people.filter(
      (person) => person.distribution_backup === true
    )
    var distributionBackupGroups = getters.groups.filter(
      (group) => group.distribution_backup === true
    )
    var distributionBackupCharities = getters.charitiesDistributionBackup

    var percentage = 0
    distributionPrimaryPeople.forEach((person) => {
      percentage += Number(person.distribution_primary_percentage)
    })
    distributionPrimaryGroups.forEach((group) => {
      percentage += Number(group.distribution_percentage)
    })
    distributionPrimaryCharities.forEach((charity) => {
      percentage += Number(charity.distribution_percentage)
    })

    var percentageBackup = 0

    if (getters.details.distribution_all_die_backup_table) {
      distributionBackupPeople.forEach((person) => {
        percentageBackup += Number(person.distribution_backup_percentage)
      })
      distributionBackupGroups.forEach((group) => {
        percentageBackup += Number(group.distribution_backup_percentage)
      })
      distributionBackupCharities.forEach((charity) => {
        percentageBackup += Number(charity.distribution_backup_percentage)
      })
      console.log('here 0')
    }

    if (
      Number(percentage.toFixed(2)) === 100 &&
      getters.details &&
      (getters.details.distribution_minor_18 ||
        getters.details.distribution_minor_21 ||
        getters.details.distribution_minor_25)
    ) {

      if (
        getters.details.distribution_all_die_backup_table &&
        Number(percentageBackup.toFixed(2)) !== 100
      ) {
        data.distributionCompleted = false
      } else {
        data.distributionCompleted = true
      }
    } else {
      data.distributionCompleted = false
    }
    data.percentage = Number(percentage.toFixed(2))
    return data
  },
  progressWillReady: (state, getters) => {
    if (
      ((getters.progressChildren.childrenRequired &&
        getters.progressChildren.childrenCompleted) ||
        getters.progressChildren.childrenRequired === false) &&
      ((getters.progressGuardians.guardiansRequired &&
        getters.progressGuardians.guardiansCompleted) ||
        getters.progressGuardians.guardiansRequired === false) &&
      getters.progressExecutors.executorsCompleted &&
      ((getters.progressTrustees.trusteesRequired &&
        getters.progressTrustees.trusteesCompleted) ||
        getters.progressTrustees.trusteesRequired === false) &&
      getters.progressFuneral.funeralCompleted &&
      getters.progressProfile.profileCompleted &&
      getters.progressDistribution.distributionCompleted &&
      getters.progressGifts.giftsCompleted &&
      ((getters.progressPets.petsRequired &&
        getters.progressPets.petsCompleted) ||
        getters.progressPets.petsRequired === false) &&
      getters.progressAssets.assetsCompleted
    ) {
      return true
    }
    return false
  },
  progressSummary: (state, getters) => {
    var data = {
      sectionsComplete: 0,
      sectionsIncomplete: 0,
      totalRequiredSections: 6,
      percentageComplete: 0
    }

    if (getters.progressPets.petsRequired) data.totalRequiredSections += 1
    if (getters.progressChildren.childrenRequired)
      data.totalRequiredSections += 1
    if (getters.progressGuardians.guardiansRequired)
      data.totalRequiredSections += 1
    if (getters.progressTrustees.trusteesRequired)
      data.totalRequiredSections += 1

    if (getters.progressProfile.profileCompleted) {
      data.sectionsComplete += 1
    } else {
      data.sectionsIncomplete += 1
    }

    if (
      getters.progressChildren.childrenRequired &&
      getters.progressChildren.childrenCompleted
    ) {
      data.sectionsComplete += 1
    } else if (
      getters.progressChildren.childrenRequired &&
      !getters.progressChildren.childrenCompleted
    )
      data.sectionsIncomplete += 1

    if (
      getters.progressGuardians.guardiansRequired &&
      getters.progressGuardians.guardiansCompleted
    ) {
      data.sectionsComplete += 1
    } else if (
      getters.progressGuardians.guardiansRequired &&
      !getters.progressGuardians.guardiansCompleted
    )
      data.sectionsIncomplete += 1

    if (getters.progressExecutors.executorsCompleted) {
      data.sectionsComplete += 1
    } else {
      data.sectionsIncomplete += 1
    }

    if (
      getters.progressPets.petsRequired &&
      getters.progressPets.petsCompleted
    ) {
      data.sectionsComplete += 1
    } else if (
      getters.progressPets.petsRequired &&
      !getters.progressPets.petsCompleted
    )
      data.sectionsIncomplete += 1

    if (
      getters.progressTrustees.trusteesRequired &&
      getters.progressTrustees.trusteesCompleted
    ) {
      data.sectionsComplete += 1
    } else if (
      getters.progressTrustees.trusteesRequired &&
      !getters.progressTrustees.trusteesCompleted
    )
      data.sectionsIncomplete += 1

    if (getters.progressGifts.giftsCompleted) {
      data.sectionsComplete += 1
    } else {
      data.sectionsIncomplete += 1
    }

    if (getters.progressDistribution.distributionCompleted) {
      data.sectionsComplete += 1
    } else {
      data.sectionsIncomplete += 1
    }

    if (getters.progressFuneral.funeralCompleted) {
      data.sectionsComplete += 1
    } else {
      data.sectionsIncomplete += 1
    }

    if (getters.progressAssets.assetsCompleted) {
      data.sectionsComplete += 1
    } else {
      data.sectionsIncomplete += 1
    }
    data.percentageComplete = Math.round(
      (data.sectionsComplete / data.totalRequiredSections) * 100,
      0
    )
    return data
  },
  bankAccountSetup: (state) => state.bankAccountSetup,
  directDebitForm: (state) => state.directDebitForm,
  directDebitMandate: (state) => state.directDebitMandate,
  // handover
  handoverUser: (state) => state.handoverUser,
  handoverPartner: (state) => state.handoverPartner,
  handoverBrokerContactEmail: (state) => state.handoverBrokerContactEmail,
  // mirror will details - if partner clicks same link
  partnerCheckoutBasket: (state) => state.partnerCheckoutBasket,
  partnerPersonalDetails: (state) => state.partnerPersonalDetails
}

export default getters
