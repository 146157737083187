import Vue from 'vue'
import { http } from '@/services'

const mutations = {
  // authentication

  loginError(state, message) {
    state.loginError = message
  },
  accessToken(state, newToken) {
    localStorage.setItem('accessToken', newToken)
    state.tokens.access = newToken
  },
  refreshToken(state, newToken) {
    localStorage.setItem('refreshToken', newToken)
    state.tokens.refresh = newToken
  },
  removeTokens(state) {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    http.defaults.headers.Authorization = null
    state.tokens.access = null
    state.tokens.refresh = null
  },

  // other
  clearInitialise(state) {
    state.user = null
    state.willPreview = null
    state.checkoutBasket = {
      will_id: null,
      advice_id: null,
      printing: true,
      package: true,
      single_price: '',
      joint_price: '',
      coupon: '',
      will_for: '',
      total_price: '',
      charge_now_price: '',
      go_card_less_redirect_id: '',
      active_subscription: '',
      account_holder_name: '',
      account_number_ending: ''
    }
    state.checkoutProgress = {
      createWill: false,
      package: false,
      printing: false,
      checking: false,
      pay: false,
      share: false,
      advice: false,
      draft: false
    }
    state.prices = []
    state.communication = null
    state.documents = []
    state.sharePeople = []
    state.advice = []
    state.adviceNew = []
    state.people = []
    state.groups = []
    state.charities = []
    state.personalDetails = {
      id: null,
      terms_and_conditions: null,
      will_for: null,
      full_name: null,
      sex: null,
      dob_day: null,
      dob_month: null,
      dob_year: null,
      charity_donate: null,
      charity_amount: null,
      charity_percentage: null,
      address_line_1: null,
      address_line_2: null,
      post_town: null,
      post_code: null,
      country: null,
      marital_status: null,
      marital_status_single_divorced: null,
      marital_status_single_widowed: null,
      partner_details: null,
      partner_full_name: null,
      partner_gender: null,
      partner_email: null,
      partner_phone: null,
      partner: null,
      children: null,
      pets: null,
      phone: null,
      contact_email: null
    }
    state.details = {
      id: null,
      distribution_minor_18: null,
      distribution_minor_21: null,
      distribution_minor_25: null,
      distribution_all_die_dont_care: null,
      distribution_all_die_backup_table: null,
      distribution_disabled: null,
      distribution_disabled_details: null,
      gifts: null,
      gifts_before: null,
      gifts_after: null,
      pets_yes_no: null,
      amount: null,
      same_as_executors: null,
      trustees_secondary_required: null,
      trustees_backup_required: null,
      executor_professional: null,
      executor_professional_and_family: null,
      executors_secondary_required: null,
      consent_to_alternative_professional_executors: null,
      guardians_backup_required: null,
      guardians_secondary_required: null,
      executors_backup_required: null,
      discretionary_trust: null,
      last_saved: null
    }
    state.funeral = {
      id: null,
      funeral_type_celebration: null,
      funeral_type_simple: null,
      funeral_type_green: null,
      funeral_type_grand: null,
      funeral_type_non_religious: null,
      funeral_type_no_preference: null,
      funeral_finish: null,
      funeral_wishes_required: null,
      funeral_wishes: null,
      funeral_organs: null,
      funeral_plan: null,
      funeral_plan_provider: null
    }
    state.giftCash = []
    state.giftOther = []
    state.giftRealEstate = []
    state.giftVehicle = []
    state.giftCharity = []
    state.assets = {
      residential_property: null,
      residential_property_amount: null,
      residential_property_joint: null,
      blt_property: null,
      blt_property_amount: null,
      blt_property_joint: null,
      savings_bank: null,
      savings_bank_amount: null,
      savings_bank_joint: null,
      life_insurance: null,
      life_insurance_amount: null,
      life_insurance_joint: null,
      vehicle: null,
      vehicle_amount: null,
      pension: null,
      pension_amount: null,
      shares_investments: null,
      shares_investments_amount: null,
      shares_investments_joint: null,
      endowment: null,
      endowment_amount: null,
      endowment_joint: null,
      business: null,
      business_amount: null,
      household: null,
      household_amount: null,
      gold_jewellery: null,
      gold_jewellery_amount: null,
      trust: null,
      residential_mortgage: null,
      residential_mortgage_amount: null,
      residential_mortgage_joint: null,
      blt_mortgage: null,
      blt_mortgage_amount: null,
      blt_mortgage_joint: null,
      credit_cards: null,
      credit_cards_amount: null,
      loans: null,
      loans_amount: null,
      assets_outside_uk: null,
      known_by_other_name: null,
      other_name: null,
      business_type_required: null,
      business_type: null,
      shareholders: null
    }
    state.showIntroModals = {
      executors: true,
      trustees: true,
      guardians: true,
      distribution: true,
      gifts: true,
      children: true,
      funeral: true,
      assets: true,
      handover: true
    }
    state.bankAccountSetup = null
    state.directDebitForm = null
    state.directDebitMandate = null
    state.partnerCheckoutBasket = null
    state.partnerPersonalDetails = null
  },
  user(state, { authUser, isAuthenticated }) {
    Vue.set(state, 'user', authUser)
    Vue.set(state, 'isAuthenticated', isAuthenticated)
  },
  /*
  user (state, user) {
    state.user = user
  },
  */
  sidebar(state, value) {
    state.sidebar = value
  },
  sidebarToggle(state) {
    state.sidebar === 'full'
      ? (state.sidebar = 'mini-sidebar')
      : (state.sidebar = 'full')
  },
  sidebarHover(state) {
    state.sidebarHover === true
      ? (state.sidebarHover = false)
      : (state.sidebarHover = true)
  },
  sidebarMobileToggle(state) {
    state.sidebarMobile === true
      ? (state.sidebarMobile = false)
      : (state.sidebarMobile = true)
  },
  sidebarMobileCollapse(state) {
    if (state.sidebarMobile === true) state.sidebarMobile = false
  },
  progress(state, data) {
    state.userDetails.progress = data
  },
  willPreview(state, data) {
    state.willPreview = data
  },
  adviceWillId(state, data) {
    state.checkoutBasket.will_id = data.will_id
    state.checkoutBasket.advice_id = data.advice_id
  },
  checkoutBasket(state, payload) {
    if (typeof payload.printing !== 'undefined')
      state.checkoutBasket.printing = payload.printing
    if (typeof payload.package !== 'undefined')
      state.checkoutBasket.package = payload.package
    if (typeof payload.will_for !== 'undefined')
      state.checkoutBasket.will_for = payload.will_for
    if (typeof payload.single_price !== 'undefined')
      state.checkoutBasket.single_price = payload.single_price
    if (typeof payload.single_price !== 'undefined')
      state.checkoutBasket.single_price = payload.single_price
    if (typeof payload.joint_price !== 'undefined')
      state.checkoutBasket.joint_price = payload.joint_price
    if (typeof payload.total_price !== 'undefined')
      state.checkoutBasket.total_price = payload.total_price
    if (typeof payload.charge_now_price !== 'undefined')
      state.checkoutBasket.charge_now_price = payload.charge_now_price
    if (typeof payload.coupon !== 'undefined')
      state.checkoutBasket.coupon = payload.coupon
    if (typeof payload.go_card_less_redirect_id !== 'undefined')
      state.checkoutBasket.go_card_less_redirect_id =
        payload.go_card_less_redirect_id
    if (typeof payload.active_subscription !== 'undefined')
      state.checkoutBasket.active_subscription = payload.active_subscription
    if (typeof payload.redirect_flow_url !== 'undefined')
      state.checkoutBasket.redirect_flow_url = payload.redirect_flow_url
  },
  prices(state, data) {
    state.prices = data
  },
  communication(state, data) {
    state.communication = data
  },
  existingMandate(state, payload) {
    if (payload && payload.account_holder_name)
      state.checkoutBasket.account_holder_name = payload.account_holder_name
    if (payload && payload.account_number_ending)
      state.checkoutBasket.account_number_ending = payload.account_number_ending
  },
  checkoutProgress(state, data) {
    if (typeof data.createWill !== 'undefined')
      state.checkoutProgress.createWill = data.createWill
    if (typeof data.package !== 'undefined')
      state.checkoutProgress.package = data.package
    if (typeof data.printing !== 'undefined')
      state.checkoutProgress.printing = data.printing
    if (typeof data.checking !== 'undefined')
      state.checkoutProgress.checking = data.checking
    if (typeof data.pay !== 'undefined') state.checkoutProgress.pay = data.pay
    if (typeof data.share !== 'undefined')
      state.checkoutProgress.share = data.share
    if (typeof data.advice !== 'undefined')
      state.checkoutProgress.advice = data.advice
    if (typeof data.draft !== 'undefined')
      state.checkoutProgress.draft = data.draft
  },
  personAdd(state, data) {
    var index = state.people.findIndex((person) => person.id === data.id)
    if (typeof index !== 'undefined' && index >= 0) {
      state.people.splice(index, 1, data)
    } else if (index < 0) {
      state.people.push(data)
    }
  },
  groupAdd(state, data) {
    var index = state.groups.findIndex((group) => group.id === data.id)
    if (typeof index !== 'undefined' && index >= 0) {
      state.groups.splice(index, 1, data)
    } else if (index < 0) {
      state.groups.push(data)
    }
  },
  charityAdd(state, data) {
    var index = state.charities.findIndex((charity) => charity.id === data.id)
    if (typeof index !== 'undefined' && index >= 0) {
      state.charities.splice(index, 1, data)
    } else if (index < 0) {
      state.charities.push(data)
    }
  },
  personDelete(state, data) {
    var index = state.people.findIndex((person) => person.id === data)
    if (typeof index !== 'undefined') state.people.splice(index, 1)
  },
  groupDelete(state, data) {
    var index = state.groups.findIndex((group) => group.id === data)
    if (typeof index !== 'undefined') state.groups.splice(index, 1)
  },
  charityDelete(state, data) {
    var index = state.charities.findIndex((charity) => charity.id === data)
    if (typeof index !== 'undefined') state.charities.splice(index, 1)
  },
  sharePeople(state, data) {
    state.sharePeople = data
  },
  advice(state, data) {
    state.advice = data
  },
  adviceNew(state, data) {
    state.adviceNew = data
  },
  documents(state, data) {
    state.documents = data
  },
  people(state, data) {
    state.people = data
  },
  groups(state, data) {
    state.groups = data
  },
  charities(state, data) {
    state.charities = data
  },
  personalDetails(state, data) {
    if (typeof data.id !== 'undefined') state.personalDetails.id = data.id
    if (typeof data.terms_and_conditions !== 'undefined')
      state.personalDetails.terms_and_conditions = data.terms_and_conditions
    if (typeof data.will_for !== 'undefined')
      state.personalDetails.will_for = data.will_for
    if (typeof data.full_name !== 'undefined')
      state.personalDetails.full_name = data.full_name
    if (typeof data.sex !== 'undefined') state.personalDetails.sex = data.sex
    if (typeof data.dob_day !== 'undefined')
      state.personalDetails.dob_day = data.dob_day
    if (typeof data.dob_month !== 'undefined')
      state.personalDetails.dob_month = data.dob_month
    if (typeof data.dob_year !== 'undefined')
      state.personalDetails.dob_year = data.dob_year
    if (typeof data.charity_donate !== 'undefined')
      state.personalDetails.charity_donate = data.charity_donate
    if (typeof data.charity_amount !== 'undefined')
      state.personalDetails.charity_amount = data.charity_amount
    if (typeof data.charity_percentage !== 'undefined')
      state.personalDetails.charity_percentage = data.charity_percentage
    if (typeof data.address_line_1 !== 'undefined')
      state.personalDetails.address_line_1 = data.address_line_1
    if (typeof data.address_line_2 !== 'undefined')
      state.personalDetails.address_line_2 = data.address_line_2
    if (typeof data.post_town !== 'undefined')
      state.personalDetails.post_town = data.post_town
    if (typeof data.post_code !== 'undefined')
      state.personalDetails.post_code = data.post_code
    if (typeof data.country !== 'undefined')
      state.personalDetails.country = data.country
    if (typeof data.moving !== 'undefined')
      state.personalDetails.moving = data.moving

    if (typeof data.domiciled_in_country !== 'undefined') {
      Vue.set(
        state.personalDetails,
        'domiciled_in_country',
        data.domiciled_in_country
      )
    }

    if (typeof data.domiciled_advice_accepted !== 'undefined') {
      Vue.set(
        state.personalDetails,
        'domiciled_advice_accepted',
        data.domiciled_advice_accepted
      )
    }

    if (typeof data.partner_details !== 'undefined')
      state.personalDetails.partner_details = data.partner_details
    if (typeof data.partner_full_name !== 'undefined')
      state.personalDetails.partner_full_name = data.partner_full_name
    if (typeof data.partner_gender !== 'undefined')
      state.personalDetails.partner_gender = data.partner_gender
    if (typeof data.partner_email !== 'undefined')
      state.personalDetails.partner_email = data.partner_email
    if (typeof data.partner_phone !== 'undefined')
      state.personalDetails.partner_phone = data.partner_phone
    if (typeof data.partner !== 'undefined')
      state.personalDetails.partner = data.partner
    if (typeof data.children !== 'undefined')
      state.personalDetails.children = data.children
    if (typeof data.pets !== 'undefined') state.personalDetails.pets = data.pets
    if (typeof data.phone !== 'undefined')
      state.personalDetails.phone = data.phone
    if (typeof data.contact_email !== 'undefined')
      state.personalDetails.contact_email = data.contact_email
    if (typeof data.marital_status !== 'undefined')
      state.personalDetails.marital_status = data.marital_status
    if (typeof data.marital_status_single_widowed !== 'undefined')
      state.personalDetails.marital_status_single_widowed =
        data.marital_status_single_widowed
    if (typeof data.marital_status_single_divorced !== 'undefined')
      state.personalDetails.marital_status_single_divorced =
        data.marital_status_single_divorced
    if (typeof data.username !== 'undefined')
      state.personalDetails.username = data.username
    if (typeof data.usernameError !== 'undefined') {
      state.personalDetails.usernameError = data.usernameError
    }
  },
  details(state, data) {
    if (typeof data.id !== 'undefined') state.details.id = data.id
    if (typeof data.distribution_minor_18 !== 'undefined')
      state.details.distribution_minor_18 = data.distribution_minor_18
    if (typeof data.distribution_minor_21 !== 'undefined')
      state.details.distribution_minor_21 = data.distribution_minor_21
    if (typeof data.distribution_minor_25 !== 'undefined')
      state.details.distribution_minor_25 = data.distribution_minor_25
    if (typeof data.distribution_all_die_dont_care !== 'undefined')
      state.details.distribution_all_die_dont_care =
        data.distribution_all_die_dont_care
    if (typeof data.distribution_all_die_backup_table !== 'undefined')
      state.details.distribution_all_die_backup_table =
        data.distribution_all_die_backup_table
    if (typeof data.distribution_disabled !== 'undefined')
      state.details.distribution_disabled = data.distribution_disabled
    if (typeof data.distribution_disabled_details !== 'undefined')
      state.details.distribution_disabled_details =
        data.distribution_disabled_details
    if (typeof data.gifts !== 'undefined') state.details.gifts = data.gifts
    if (typeof data.gifts_before !== 'undefined')
      state.details.gifts_before = data.gifts_before
    if (typeof data.gifts_after !== 'undefined')
      state.details.gifts_after = data.gifts_after
    if (typeof data.pets_yes_no !== 'undefined')
      state.details.pets_yes_no = data.pets_yes_no
    if (typeof data.amount !== 'undefined') state.details.amount = data.amount
    if (typeof data.same_as_executors !== 'undefined')
      state.details.same_as_executors = data.same_as_executors
    if (typeof data.trustees_secondary_required !== 'undefined')
      state.details.trustees_secondary_required =
        data.trustees_secondary_required
    if (typeof data.trustees_backup_required !== 'undefined')
      state.details.trustees_backup_required = data.trustees_backup_required
    if (typeof data.executor_professional !== 'undefined')
      state.details.executor_professional = data.executor_professional
    if (typeof data.executor_professional_and_family !== 'undefined')
      state.details.executor_professional_and_family =
        data.executor_professional_and_family
    if (
      typeof data.consent_to_alternative_professional_executors !== 'undefined'
    )
      state.details.consent_to_alternative_professional_executors =
        data.consent_to_alternative_professional_executors
    if (typeof data.executors_secondary_required !== 'undefined')
      state.details.executors_secondary_required =
        data.executors_secondary_required
    if (typeof data.executors_backup_required !== 'undefined')
      state.details.executors_backup_required = data.executors_backup_required
    if (typeof data.guardians_backup_required !== 'undefined')
      state.details.guardians_backup_required = data.guardians_backup_required
    if (typeof data.guardians_secondary_required !== 'undefined')
      state.details.guardians_secondary_required =
        data.guardians_secondary_required
    if (typeof data.discretionary_trust !== 'undefined')
      state.details.discretionary_trust = data.discretionary_trust
    if (typeof data.last_saved !== 'undefined')
      state.details.last_saved = data.last_saved
  },
  assets(state, data) {
    if (typeof data.id !== 'undefined') state.assets.id = data.id
    if (typeof data.residential_property !== 'undefined')
      state.assets.residential_property = data.residential_property

    if (typeof data.residential_property_amount !== 'undefined')
      state.assets.residential_property_amount =
        data.residential_property_amount

    if (typeof data.residential_property_joint !== 'undefined')
      state.assets.residential_property_joint = data.residential_property_joint

    if (typeof data.residential_property_owner !== 'undefined')
      Vue.set(
        state.assets,
        'residential_property_owner',
        data.residential_property_owner
      )

    if (typeof data.new_residential_property !== 'undefined') {
      Vue.set(
        state.assets,
        'new_residential_property',
        data.new_residential_property
      )
    }

    if (typeof data.new_residential_property_amount !== 'undefined') {
      Vue.set(
        state.assets,
        'new_residential_property_amount',
        data.new_residential_property_amount
      )
      // state.assets.new_residential_property_amount =
      // data.new_residential_property_amount
    }


    if (typeof data.new_residential_property_joint !== 'undefined') {
      Vue.set(
        state.assets,
        'new_residential_property_joint',
        data.new_residential_property_joint
      )
      // state.assets.new_residential_property_joint =
      // data.new_residential_property_joint
    }


    if (typeof data.new_residential_property_owner !== 'undefined') {
      Vue.set(
        state.assets,
        'new_residential_property_owner',
        data.new_residential_property_owner
      )
    }


    if (typeof data.blt_property !== 'undefined')
      state.assets.blt_property = data.blt_property
    if (typeof data.blt_property_amount !== 'undefined')
      state.assets.blt_property_amount = data.blt_property_amount

    if (typeof data.blt_property_joint !== 'undefined')
      state.assets.blt_property_joint = data.blt_property_joint
    if (typeof data.savings_bank !== 'undefined')
      state.assets.savings_bank = data.savings_bank
    if (typeof data.savings_bank_amount !== 'undefined')
      state.assets.savings_bank_amount = data.savings_bank_amount
    if (typeof data.savings_bank_joint !== 'undefined')
      state.assets.savings_bank_joint = data.savings_bank_joint
    if (typeof data.life_insurance !== 'undefined')
      state.assets.life_insurance = data.life_insurance
    if (typeof data.life_insurance_amount !== 'undefined')
      state.assets.life_insurance_amount = data.life_insurance_amount

    if (typeof data.life_insurance_joint !== 'undefined')
      state.assets.life_insurance_joint = data.life_insurance_joint
    if (typeof data.vehicle !== 'undefined') state.assets.vehicle = data.vehicle
    if (typeof data.vehicle_amount !== 'undefined')
      state.assets.vehicle_amount = data.vehicle_amount
    if (typeof data.pension !== 'undefined') state.assets.pension = data.pension
    if (typeof data.pension_amount !== 'undefined')
      state.assets.pension_amount = data.pension_amount
    if (typeof data.shares_investments !== 'undefined')
      state.assets.shares_investments = data.shares_investments
    if (typeof data.shares_investments_amount !== 'undefined')
      state.assets.shares_investments_amount = data.shares_investments_amount
    if (typeof data.shares_investments_joint !== 'undefined')
      state.assets.shares_investments_joint = data.shares_investments_joint
    if (typeof data.endowment !== 'undefined')
      state.assets.endowment = data.endowment
    if (typeof data.endowment_amount !== 'undefined')
      state.assets.endowment_amount = data.endowment_amount
    if (typeof data.endowment_joint !== 'undefined')
      state.assets.endowment_joint = data.endowment_joint
    if (typeof data.business !== 'undefined')
      state.assets.business = data.business
    if (typeof data.business_amount !== 'undefined')
      state.assets.business_amount = data.business_amount
    if (typeof data.household !== 'undefined')
      state.assets.household = data.household
    if (typeof data.household_amount !== 'undefined')
      state.assets.household_amount = data.household_amount
    if (typeof data.gold_jewellery !== 'undefined')
      state.assets.gold_jewellery = data.gold_jewellery
    if (typeof data.gold_jewellery_amount !== 'undefined')
      state.assets.gold_jewellery_amount = data.gold_jewellery_amount
    if (typeof data.trust !== 'undefined') state.assets.trust = data.trust

    if (typeof data.residential_mortgage !== 'undefined')
      state.assets.residential_mortgage = data.residential_mortgage
    if (typeof data.residential_mortgage_amount !== 'undefined')
      state.assets.residential_mortgage_amount =
        data.residential_mortgage_amount
    if (typeof data.residential_mortgage_joint !== 'undefined')
      state.assets.residential_mortgage_joint = data.residential_mortgage_joint

    if (typeof data.new_residential_mortgage !== 'undefined') {
      Vue.set(
        state.assets,
        'new_residential_mortgage',
        data.new_residential_mortgage
      )
      // state.assets.new_residential_mortgage = data.new_residential_mortgage

    }

    if (typeof data.new_residential_mortgage_amount !== 'undefined') {
      Vue.set(
        state.assets,
        'new_residential_mortgage_amount',
        data.new_residential_mortgage_amount
      )
      // state.assets.new_residential_mortgage_amount =
      // data.new_residential_mortgage_amount
    }



    if (typeof data.new_residential_mortgage_joint !== 'undefined') {
      Vue.set(
        state.assets,
        'new_residential_mortgage_joint',
        data.new_residential_mortgage_joint
      )
      // state.assets.new_residential_mortgage_joint =
      // data.new_residential_mortgage_joint
    }


    if (typeof data.blt_mortgage !== 'undefined')
      state.assets.blt_mortgage = data.blt_mortgage
    if (typeof data.blt_mortgage_amount !== 'undefined')
      state.assets.blt_mortgage_amount = data.blt_mortgage_amount
    if (typeof data.blt_mortgage_joint !== 'undefined')
      state.assets.blt_mortgage_joint = data.blt_mortgage_joint
    if (typeof data.credit_cards !== 'undefined')
      state.assets.credit_cards = data.credit_cards
    if (typeof data.credit_cards_amount !== 'undefined')
      state.assets.credit_cards_amount = data.credit_cards_amount
    if (typeof data.loans !== 'undefined') state.assets.loans = data.loans
    if (typeof data.loans_amount !== 'undefined')
      state.assets.loans_amount = data.loans_amount
    if (typeof data.assets_outside_uk !== 'undefined')
      state.assets.assets_outside_uk = data.assets_outside_uk
    if (typeof data.assets_outside_uk_country !== 'undefined') {
      state.assets.assets_outside_uk_country = data.assets_outside_uk_country
    }
    if (typeof data.known_by_other_name !== 'undefined')
      state.assets.known_by_other_name = data.known_by_other_name
    if (typeof data.other_name !== 'undefined')
      state.assets.other_name = data.other_name
    if (typeof data.business_type_required !== 'undefined')
      state.assets.business_type_required = data.business_type_required
    if (typeof data.business_type !== 'undefined')
      state.assets.business_type = data.business_type
    if (typeof data.shareholders_required !== 'undefined')
      state.assets.shareholders_required = data.shareholders_required
    if (
      typeof data.shareholders !== 'undefined' &&
      data.shareholders !== 'False'
    )
      state.assets.shareholders = data.shareholders
  },
  funeral(state, data) {
    if (typeof data.id !== 'undefined') state.funeral.id = data.id
    if (typeof data.funeral_type_celebration !== 'undefined')
      state.funeral.funeral_type_celebration = data.funeral_type_celebration
    if (typeof data.funeral_type_simple !== 'undefined')
      state.funeral.funeral_type_simple = data.funeral_type_simple
    if (typeof data.funeral_type_green !== 'undefined')
      state.funeral.funeral_type_green = data.funeral_type_green
    if (typeof data.funeral_type_grand !== 'undefined')
      state.funeral.funeral_type_grand = data.funeral_type_grand
    if (typeof data.funeral_type_non_religious !== 'undefined')
      state.funeral.funeral_type_non_religious = data.funeral_type_non_religious
    if (typeof data.funeral_type_no_preference !== 'undefined')
      state.funeral.funeral_type_no_preference = data.funeral_type_no_preference
    if (typeof data.funeral_finish !== 'undefined')
      state.funeral.funeral_finish = data.funeral_finish
    if (typeof data.funeral_wishes_required !== 'undefined')
      state.funeral.funeral_wishes_required = data.funeral_wishes_required
    if (typeof data.funeral_wishes !== 'undefined')
      state.funeral.funeral_wishes = data.funeral_wishes
    if (typeof data.funeral_organs !== 'undefined')
      state.funeral.funeral_organs = data.funeral_organs
    if (typeof data.funeral_plan !== 'undefined')
      state.funeral.funeral_plan = data.funeral_plan
    if (typeof data.funeral_plan_provider !== 'undefined')
      state.funeral.funeral_plan_provider = data.funeral_plan_provider
  },
  giftOther(state, data) {
    state.giftOther = data
  },
  giftCharity(state, data) {
    state.giftCharity = data
  },
  giftVehicle(state, data) {
    state.giftVehicle = data
  },
  giftCash(state, data) {
    state.giftCash = data
  },
  giftRealEstate(state, data) {
    state.giftRealEstate = data
  },
  giftCashAdd(state, data) {
    var index = state.giftCash.findIndex((gift) => gift.id === data.id)
    if (typeof index !== 'undefined' && index >= 0) {
      state.giftCash.splice(index, 1, data)
    } else if (index < 0) {
      state.giftCash.push(data)
    }
  },
  giftOtherAdd(state, data) {
    var index = state.giftOther.findIndex((gift) => gift.id === data.id)
    if (typeof index !== 'undefined' && index >= 0) {
      state.giftOther.splice(index, 1, data)
    } else if (index < 0) {
      state.giftOther.push(data)
    }
  },
  giftPropertyAdd(state, data) {
    var index = state.giftRealEstate.findIndex((gift) => gift.id === data.id)
    if (typeof index !== 'undefined' && index >= 0) {
      state.giftRealEstate.splice(index, 1, data)
    } else if (index < 0) {
      state.giftRealEstate.push(data)
    }
  },
  giftCharityAdd(state, data) {
    var index = state.giftCharity.findIndex((gift) => gift.id === data.id)
    if (typeof index !== 'undefined' && index >= 0) {
      state.giftCharity.splice(index, 1, data)
    } else if (index < 0) {
      state.giftCharity.push(data)
    }
  },
  giftVehicleAdd(state, data) {
    var index = state.giftVehicle.findIndex((gift) => gift.id === data.id)
    if (typeof index !== 'undefined' && index >= 0) {
      state.giftVehicle.splice(index, 1, data)
    } else if (index < 0) {
      state.giftVehicle.push(data)
    }
  },
  giftCashDelete(state, data) {
    var index = state.giftCash.findIndex((gift) => gift.id === data)
    if (typeof index !== 'undefined') state.giftCash.splice(index, 1)
  },
  giftRealEstateDelete(state, data) {
    var index = state.giftRealEstate.findIndex((gift) => gift.id === data)
    if (typeof index !== 'undefined') state.giftRealEstate.splice(index, 1)
  },
  giftOtherDelete(state, data) {
    var index = state.giftOther.findIndex((gift) => gift.id === data)
    if (typeof index !== 'undefined') state.giftOther.splice(index, 1)
  },
  giftCharityDelete(state, data) {
    var index = state.giftCharity.findIndex((gift) => gift.id === data)
    if (typeof index !== 'undefined') state.giftCharity.splice(index, 1)
  },
  giftVehicleDelete(state, data) {
    var index = state.giftVehicle.findIndex((gift) => gift.id === data)
    if (typeof index !== 'undefined') state.giftVehicle.splice(index, 1)
  },
  showIntroModals(state, data) {
    if (typeof data.executors !== 'undefined') {
      state.showIntroModals.executors = data.executors
    } else if (typeof data.trustees !== 'undefined') {
      state.showIntroModals.trustees = data.trustees
    } else if (typeof data.guardians !== 'undefined') {
      state.showIntroModals.guardians = data.guardians
    } else if (typeof data.children !== 'undefined') {
      state.showIntroModals.children = data.children
    } else if (typeof data.funeral !== 'undefined') {
      state.showIntroModals.funeral = data.funeral
    } else if (typeof data.assets !== 'undefined') {
      state.showIntroModals.assets = data.assets
    } else if (typeof data.gifts !== 'undefined') {
      state.showIntroModals.gifts = data.gifts
    } else if (typeof data.distribution !== 'undefined') {
      state.showIntroModals.distribution = data.distribution
    } else if (typeof data.handover !== 'undefined') {
      state.showIntroModals.handover = data.handover
    }
  },
  bankAccountSetup(state, data) {
    state.bankAccountSetup = data
  },
  directDebitForm(state, data) {
    state.directDebitForm = data
  },
  directDebitMandate(state, data) {
    state.directDebitMandate = data
  },
  handoverUser(state, data) {
    state.handoverUser = data
  },
  handoverBrokerContactEmail(state, data) {
    state.handoverBrokerContactEmail = data
  },
  handoverPartner(state, data) {
    state.handoverPartner = data
  },
  removeHandover(state) {
    state.handoverUser = null
    state.handoverPartner = null
    state.handoverBrokerContactEmail = null
  },
  partnerCheckoutBasket(state, data) {
    state.partnerCheckoutBasket = data
  },
  partnerPersonalDetails(state, data) {
    state.partnerPersonalDetails = data
  }
}

export default mutations
