<template>
  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6 option-padding">
    <div class="quad-button">
      <div class="letter-container">
        <div
          v-for="(option, id) in options"
          :key="id"
          v-on:click="$emit('addPerson', option)"
          class="quad-option"
        >
          <span>{{ option[0] }}</span>
        </div>

        <div class="plus-sign">
          <div class="plus-sign__vertical"></div>
          <div class="plus-sign__horizontal"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PersonAddQuad',
  props: ['brotherSister', 'children'],
  data() {
    return {
      windowWidth: null
    }
  },
  mounted() {
    this.windowWidth = window.innerWidth
  },
  computed: {
    options() {
      if (window.innerWidth > 570) {
        if (this.brotherSister)
          return [
            ['brother', 'Male'],
            ['sister', 'Female'],
            ['friend', ''],
            ['other', '']
          ]
        if (this.children)
          return [
            ['son', 'Male'],
            ['daughter', 'Female'],
            ['stepson', 'Male'],
            ['stepdaughter', 'Female']
          ]
      } else {
        if (this.brotherSister)
          return [
            ['', ''],
            ['', ''],
            ['', ''],
            ['', '']
          ]
        if (this.children)
          return [
            ['child', ''],
            ['child', ''],
            ['child', ''],
            ['child', '']
          ]
      }
      return null
    }
  }
}
</script>

<style scoped>
span:first-letter {
  text-transform: capitalize;
}
</style>
