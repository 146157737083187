var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"question-nav",class:{ validated: _vm.validated, center: _vm.center },attrs:{"id":"js-question-nav"}},[_c('div',{staticClass:"button-wrapper"},[(_vm.externalBackUrl)?_c('button',{staticClass:"btn btn-medium btn-backwards btn--dark",on:{"click":function($event){$event.stopPropagation();return _vm.gotoBackUrl.apply(null, arguments)}}},[_c('i',{staticClass:"fa fa-chevron-left"}),(_vm.backText)?_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.backText))]):_vm._e()]):(_vm.backLink)?_c('router-link',{staticClass:"btn btn-medium btn-backwards btn--dark",attrs:{"to":_vm.backLink,"tag":"button","type":"button"}},[_c('i',{staticClass:"fa fa-chevron-left"}),(_vm.backText)?_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.backText))]):_vm._e()]):_vm._e(),(_vm.externalForwardUrl)?_c('button',{staticClass:"btn btn-arrow btn-medium btn--dark",class:{
        'btn-forwards': _vm.externalBackUrl || _vm.backLink,
        disabled: _vm.forwardDisabled
      },attrs:{"disabled":_vm.forwardDisabled},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.console.log('enter key pressed')},"click":function($event){$event.stopPropagation();return _vm.gotoForwardUrl.apply(null, arguments)}}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.forwardText))]),(_vm.checkConfirm)?_c('i',{staticClass:"fa fa-check-double"}):_c('i',{staticClass:"fa fa-chevron-right"})]):(_vm.forwardLink)?_c('router-link',{ref:"jsNext",staticClass:"btn btn-arrow btn-medium btn--dark",class:{
        'btn-forwards': _vm.backLink || _vm.externalBackUrl,
        disabled: _vm.forwardDisabled
      },attrs:{"to":_vm.forwardLink,"tag":"button","id":"js-next","disabled":_vm.forwardDisabled}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.forwardText))]),(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):(_vm.checkConfirm)?_c('i',{staticClass:"fa fa-check-double"}):_c('i',{staticClass:"fa fa-chevron-right"})]):_c('button',{ref:"jsNext",staticClass:"btn btn-arrow btn-medium btn--dark",class:{
        'btn-forwards': _vm.externalBackUrl || _vm.backLink,
        disabled: _vm.forwardDisabled
      },attrs:{"id":"js-next","disabled":_vm.forwardDisabled},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('forwardClick')}}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.forwardText))]),(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):(_vm.checkConfirm)?_c('i',{staticClass:"fa fa-check-double"}):_c('i',{staticClass:"fa fa-chevron-right"})])],1),_vm._m(0),(_vm.forwardLinkAlternative)?_c('router-link',{ref:"jsNext",staticClass:"alternative-link",class:{ disabled: _vm.forwardDisabled },attrs:{"to":_vm.forwardLinkAlternative,"tag":"a","id":"js-next","disabled":_vm.forwardDisabled}},[_vm._v(" "+_vm._s(_vm.forwardTextAlternative)+" ")]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-enter"},[_vm._v("press "),_c('strong',[_vm._v("ENTER")])])
}]

export { render, staticRenderFns }