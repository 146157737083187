<template>
  <div class="container-fluid no-clip" v-bind:class="background || 'bg-basket'">
    <div class="row">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h2 v-if="heading" class="question-section-question">
              {{ heading }}
            </h2>
            <div
              v-if="subHeading"
              class="question-section-intro"
              v-bind:class="introClasses"
            >
              {{ subHeading }}
            </div>
            <div
              class="row"
              v-bind:class="{
                'question-section-options': questionOptions
              }"
            >
              <slot name="contents"></slot>
            </div>
            <slot name="navButtons"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Page',
  props: [
    'heading',
    'background',
    'subHeading',
    'introClasses',
    'questionOptions'
  ],
  data() {
    return {}
  }
}
</script>

<style></style>
