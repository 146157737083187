<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
    :background="'bg-personal-details'"
  >
    <template v-slot:contents>
      <InputOptionLarge
        v-bind:letter="'a'"
        v-bind:selected="assets.known_by_other_name === 'no'"
        v-bind:option="'No'"
        v-bind:image="'https://mylastwill.s3.amazonaws.com/static/img/button-asset-name.png'"
        @selected="save('no')"
      >
        <template v-slot:text>
          <div class="pricing-sub-text">
            Select this option if all of your assets are held in the name '{{
              full_name
            }}'.
          </div>
        </template>
      </InputOptionLarge>
      <InputOptionLarge
        v-bind:letter="'b'"
        v-bind:selected="assets.known_by_other_name === 'yes'"
        v-bind:option="'Yes'"
        v-bind:image="'https://mylastwill.s3.amazonaws.com/static/img/button-asset-names.png'"
        @selected="save('yes')"
      >
        <template v-slot:text>
          <div class="pricing-sub-text">
            If some of your assets are in the name '{{ full_name }}' and some
            assets are held under a different name please select this option and
            add your alias.
          </div>
        </template>
        <template v-slot:input>
          <div class="partner-invite">
            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <input
                  v-model="name"
                  required
                  type="text"
                  placeholder="Other name"
                  class="input-standard-grey validation"
                />
              </div>
            </div>
          </div>
        </template>
      </InputOptionLarge>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="backTo"
        v-bind:center="false"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputOptionLarge from '@/common/ui/InputOptionLarge'

export default {
  name: 'AssetsAka',
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputOptionLarge
  },
  computed: {
    number() {
      var questionNumber = 4
      if (this.assets.residential_property) questionNumber += 1
      if (this.assets.business) questionNumber += 1
      if (
        this.assets.business &&
        this.assets.business_type === 'limited company'
      )
        questionNumber += 1
      if (this.requireJoint) questionNumber += 1
      if (this.requireAmounts) questionNumber += 1
      return questionNumber
    },
    requireJoint() {
      if (
        this.assets.blt_property ||
        this.assets.residential_property ||
        this.assets.residential_property ||
        this.assets.blt_property ||
        this.assets.savings_bank ||
        this.assets.life_insurance ||
        this.assets.shares_investments ||
        this.assets.endowment
      )
        return true
      return false
    },
    requireAmounts() {
      if (
        this.assets.blt_property ||
        this.assets.residential_property ||
        this.assets.residential_property ||
        this.assets.blt_property ||
        this.assets.savings_bank ||
        this.assets.life_insurance ||
        this.assets.shares_investments ||
        this.assets.endowment ||
        this.assets.credit_cards ||
        this.assets.loans ||
        this.assets.business ||
        this.assets.household ||
        this.assets.vehicle ||
        this.assets.pension ||
        this.assets.gold_jewellery
      )
        return true
      return false
    },
    forwardTo() {
      if (this.assets.life_insurance) return '/assets/trust'
      return '/assets/check_and_confirm'
    },
    backTo() {
      if (
        this.assets.business &&
        this.assets.business_type === 'limited company'
      )
        return '/assets/business_shares'
      else if (
        this.assets.business &&
        this.assets.business_type !== 'limited company'
      )
        return '/assets/business_type'
      else if (this.assets.assets_outside_uk_country && this.assets.assets_outside_uk_country.length) {
        return '/assets/foreign_assets_country'
      }

      return '/assets/outside_england_wales'
    },
    heading() {
      return 'Do you hold assets in any other names?'
    },
    subHeading() {
      return (
        'Sometimes assets are held in different names, for example a bank account might be held ' +
        "in the name 'Jo Bloggs' and a pension belonging to the same person in the name 'Joseph Bloggs'. " +
        'This is common following a marriage or divorce where assets remain in ones maiden name. To avoid future ' +
        'problems we can allow for this in your Will.'
      )
    },
    full_name() {
      return this.$store.getters.personalDetails.full_name
    },
    checkoutBasket() {
      return this.$store.getters.checkoutBasket
    },
    isValidated() {
      if (
        this.assets.known_by_other_name === 'no' ||
        (this.assets.known_by_other_name === 'yes' && this.name)
      )
        return true
      return false
    },
    assets() {
      return this.$store.getters.assets
    }
  },
  beforeRouteLeave(to, from, next) {
    this.save()
    next()
  },
  watch: {
    assets: {
      immediate: true,
      deep: true,
      handler(value) {
        this.name = value.other_name
      }
    }
  },
  data() {
    return {
      name: null
    }
  },
  methods: {
    save(value) {
      if (this.assets) {
        this.$store.commit('assets', {
          known_by_other_name: value
        })
      }
      this.saveOtherName(value)
    },
    saveOtherName(value) {
      var name = this.name
      if (value === 'no') name = null
      if (this.assets) {
        this.$store.commit('assets', {
          other_name: name
        })
      }
    }
  }
}
</script>

<style></style>
