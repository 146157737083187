<template>
  <BaseModalSmall
    @close="closeModal"
    v-bind:isValidated="validateForm"
    :show="show"
    @confirm="savePerson"
  >
    <template v-slot:header>{{
      person ? 'Edit Person' : 'Create Person'
    }}</template>
    <template v-slot:body>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="input-container">
            <input
              v-model="data.full_name"
              type="text"
              class="input-standard-grey validation"
              required
              placeholder="Full Name"
              id="full_name"
            />
            <label for="full_name">Full Name</label>
          </div>
        </div>
        <div
          v-if="
            (person &&
              !person.child &&
              !person.child_step &&
              !person.partner) ||
            (initialPerson &&
              initialPerson[2] !== 'child' &&
              initialPerson[2] !== 'child_step' &&
              initialPerson[2] !== 'partner')
          "
          class="col-lg-7 col-md-7 col-sm-12 col-xs-12"
        >
          <RelationshipSelect
            v-bind:rel="data.relationship"
            @selected="setRelationship"
          ></RelationshipSelect>
        </div>
        <div
          v-else-if="(person && !person.partner) || !person"
          class="col-lg-7 col-md-7 col-sm-12 col-xs-12"
        >
          <div class="input-container">
            <span v-if="data.dob" class="fakelabel">Date of Birth</span>
            <Datepicker
              v-model="data.dob"
              input-class="input-standard-grey validation"
              placeholder="Date of birth"
              id="dob"
              v-bind:required="data.child || data.child_step"
              :disabled-dates="disabledDates"
              initial-view="year"
              name="dob"
              @selected="setDob"
            ></Datepicker>
          </div>
        </div>

        <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
          <div class="input-container">
            <div
              v-on:click="data.gender = 'Male'"
              v-bind:class="{
                'btn--green': data.gender === 'Male',
                'btn-unselected': data.gender !== 'Male'
              }"
              class="btn-selector btn-male btn field reduce-width"
            >
              Male
            </div>
            <div
              v-on:click="data.gender = 'Female'"
              class="btn-selector btn-female btn field reduce-width"
              v-bind:class="{
                'btn--green': data.gender === 'Female',
                'btn-unselected': data.gender !== 'Female'
              }"
            >
              Female
            </div>
          </div>
        </div>
        <div
          v-if="data.child || data.child_step"
          class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
        >
          <ChildBornToSelect
            v-bind:value="data.child_born_to"
            @selected="setChildBornTo"
          ></ChildBornToSelect>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div v-show="data.relationship === 'friend'" class="friend-text">
            Everybody must be clearly identified in your Will. Because this
            person is not a relation, you must add their mobile number, home
            address <span>OR</span> their email address:
          </div>
          <transition name="slide">
            <div v-show="showManualRelationship">
              <div class="input-container">
                <input
                  v-model="manualRelationship"
                  type="text"
                  class="input-standard-grey validation"
                  placeholder="Relationship"
                  required
                  id="relationshipManual"
                />
                <label for="relationshipManual">Relationship</label>
              </div>
            </div>
          </transition>
          <transition name="slide">
            <div v-show="showAdditional">
              <div class="input-container">
                <input
                  v-model="data.phone"
                  type="text"
                  class="input-standard-grey validation"
                  placeholder="Mobile"
                  id="phone"
                />
                <label for="phone">Mobile</label>
              </div>

              <div class="input-container">
                <input
                  v-model="data.address"
                  type="text"
                  class="input-standard-grey validation"
                  placeholder="Address"
                  id="address"
                />
                <label for="address">Address</label>
              </div>
              <div class="input-container">
                <input
                  v-model="data.email"
                  type="email"
                  class="input-standard-grey validation"
                  placeholder="Email"
                  id="email"
                />
                <label for="email">Email</label>
              </div>
            </div>
          </transition>

          <div v-show="!showAdditional" class="add-dropdown-header">
            <h5>
              <div class="add-dropdown-button">
                <span v-on:click="toggleAdditional">Click Here</span>
                to add contact details (optional)
              </div>
            </h5>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:button>Save</template>
  </BaseModalSmall>
</template>

<script>
import BaseModalSmall from '@/common/ui/BaseModalSmall'
import RelationshipSelect from '@/common/components/RelationshipSelect'
import ChildBornToSelect from '@/common/components/ChildBornToSelect'
import Datepicker from 'vuejs-datepicker'
import { http } from '@/services'

export default {
  name: 'PersonModal',
  components: {
    BaseModalSmall,
    RelationshipSelect,
    ChildBornToSelect,
    Datepicker
  },
  props: ['person', 'initialPerson', 'show'],
  data() {
    return {
      data: {
        id: null,
        full_name: null,
        address: '',
        phone: '',
        email: '',
        gender: null,
        child: false,
        child_step: false,
        child_born_to: '',
        relationship: '',
        dob_day: null,
        dob_month: null,
        dob_year: null,
        under18: false,
        under25: false,
        dob: null,

        // other non editable fields

        executor_sole_primary: false,
        executor_primary: false,
        executor_secondary: false,
        executor_backup: false,
        trustee_sole_primary: false,
        trustee_primary: false,
        trustee_secondary: false,
        trustee_backup: false,
        guardian_sole_primary: false,
        guardian_primary: false,
        guardian_secondary: false,
        guardian_backup: false,
        pets_sole_primary: false,
        pets_primary: false,
        residential_property_occupant: false
      },
      showAdditional: false,
      showManualRelationship: false,
      manualRelationship: '',
      disabledDates: {
        from: new Date()
      }
    }
  },
  watch: {
    person(value) {
      if (this.tempAccount) this.showAdditional = true
      if (value) {
        this.data.id = value.id
        this.data.full_name = value.full_name
        this.data.address = value.address
        this.data.phone = value.phone
        this.data.email = value.email
        this.data.gender = value.gender
        this.data.child = value.child
        this.data.child_step = value.child_step
        this.data.relationship = value.relationship
        this.data.child_born_to = value.child_born_to

        if (value.dob_day && value.dob_month && value.dob_year) {
          var month = Number(value.dob_month) - 1
          this.data.dob = new Date(value.dob_year, month, value.dob_day)
        }
        this.data.dob_day = value.dob_day
        this.data.dob_month = value.dob_month
        this.data.dob_year = value.dob_year

        this.data.under18 = value.under18
        this.data.under25 = value.under25

        this.data.executor_sole_primary = value.executor_sole_primary
        this.data.executor_primary = value.executor_primary
        this.data.executor_secondary = value.executor_secondary
        this.data.executor_backup = value.executor_backup
        this.data.trustee_sole_primary = value.trustee_sole_primary
        this.data.trustee_primary = value.trustee_primary
        this.data.trustee_secondary = value.trustee_secondary
        this.data.trustee_backup = value.trustee_backup
        this.data.guardian_sole_primary = value.guardian_sole_primary
        this.data.guardian_primary = value.guardian_primary
        this.data.guardian_secondary = value.guardian_secondary
        this.data.guardian_backup = value.guardian_backup
        this.data.pets_sole_primary = value.pets_sole_primary
        this.data.pets_primary = value.pets_primary
        this.data.residential_property_occupant =
          value.residential_property_occupant
      } else {
        this.clearForm()
      }
    },
    initialPerson(value) {
      if (value && !this.person) {
        this.data.relationship = value[0]
        this.data.gender = value[1]
        if (value[2]) {
          if (value[2] === 'executor_primary') this.data.executor_primary = true
          else if (value[2] === 'executor_secondary')
            this.data.executor_secondary = true
          else if (value[2] === 'executor_backup')
            this.data.executor_backup = true
          else if (value[2] === 'trustee_primary')
            this.data.trustee_primary = true
          else if (value[2] === 'trustee_secondary')
            this.data.trustee_secondary = true
          else if (value[2] === 'trustee_backup')
            this.data.trustee_backup = true
          else if (value[2] === 'guardian_primary')
            this.data.guardian_primary = true
          else if (value[2] === 'guardian_secondary')
            this.data.guardian_secondary = true
          else if (value[2] === 'guardian_backup')
            this.data.guardian_backup = true
          else if (value[2] === 'pets_primary') this.data.pets_primary = true
          else if (value[2] === 'child') this.data.child = true
          else if (value[2] === 'child_step') this.data.child_step = true
          else if (value[2] === 'residential_property_occupant')
            this.data.residential_property_occupant = true
        }
      }
    },
    relationship(value) {
      if (value === 'friend') this.showAdditional = true
      if (value === 'other') this.showManualRelationship = true
      else this.showManualRelationship = false
    }
  },
  computed: {
    relationship() {
      return this.data.relationship
    },
    validateForm() {
      if (this.data.full_name && this.data.relationship && this.data.gender) {
        if (
          this.relationship === 'friend' &&
          (this.data.phone || this.data.email || this.data.address)
        )
          return true
        else if (this.relationship === 'other' && this.manualRelationship)
          return true
        else if (
          this.data.child &&
          (!this.data.dob_year || !this.data.child_born_to)
        )
          return false
        else if (
          this.relationship !== 'friend' &&
          this.relationship !== 'other'
        )
          return true
      }
      return false
    },
    partner() {
      if (
        this.$store.getters.people.filter(
          (person) => person.partner || person.partner_ex
        ).length > 0
      )
        return this.$store.getters.people.filter(
          (person) => person.partner || person.partner_ex
        )[0]
      else return null
    },
    user() {
      return this.$store.getters.user
    },
    tempAccount() {
      return this.user && this.user.temp_account && this.user.change_password
    }
  },
  methods: {
    toggleAdditional() {
      if (this.showAdditional) this.showAdditional = false
      else this.showAdditional = true
    },
    closeModal() {
      this.$emit('close')
      if (this.tempAccount) this.showAdditional = true
      else this.showAdditional = false
      this.clearForm()
    },
    clearForm() {
      this.data.id = null
      this.data.full_name = null
      this.data.address = ''
      this.data.phone = ''
      this.data.email = ''
      this.data.gender = null
      this.data.child = false
      this.data.child_step = false
      this.data.relationship = null
      this.data.child_born_to = ''
      this.data.dob_day = null
      this.data.dob_month = null
      this.data.dob_year = null
      this.data.under18 = false
      this.data.under25 = false
      this.data.dob = null
      this.data.executor_sole_primary = false
      this.data.executor_primary = false
      this.data.executor_secondary = false
      this.data.executor_backup = false
      this.data.trustee_sole_primary = false
      this.data.trustee_primary = false
      this.data.trustee_secondary = false
      this.data.trustee_backup = false
      this.data.guardian_sole_primary = false
      this.data.guardian_primary = false
      this.data.guardian_secondary = false
      this.data.guardian_backup = false
      this.data.pets_sole_primary = false
      this.data.pets_primary = false
      this.data.residential_property_occupant = false
    },
    setRelationship(value) {
      this.data.relationship = value
    },
    setChildBornTo(value) {
      this.data.child_born_to = value[0]
      this.data.child = value[1]
      this.data.child_step = value[2]
      this.setChildRelationship()
    },
    savePerson() {
      var formData = new FormData()
      formData.append('id', this.data.id)
      formData.append('full_name', this.data.full_name)
      if (this.data.address !== null)
        formData.append('address', this.data.address)
      if (this.data.phone !== null) formData.append('phone', this.data.phone)
      if (this.data.email !== null) formData.append('email', this.data.email)
      formData.append('child', this.data.child)
      formData.append('child_step', this.data.child_step)
      if (this.data.child || this.data.child_step) {
        formData.append('relationship', this.setChildRelationship())
      } else if (
        this.data.relationship === 'other' &&
        this.manualRelationship
      ) {
        formData.append('relationship', this.manualRelationship.toLowerCase())
      } else {
        formData.append('relationship', this.data.relationship)
      }
      if (this.data.child_born_to)
        formData.append('child_born_to', this.data.child_born_to)
      formData.append('gender', this.data.gender)
      if (this.data.dob_day) formData.append('dob_day', this.data.dob_day)
      if (this.data.dob_month) formData.append('dob_month', this.data.dob_month)
      if (this.data.dob_year) formData.append('dob_year', this.data.dob_year)
      formData.append('under18', this.data.under18)
      formData.append('under25', this.data.under25)
      formData.append('executor_sole_primary', this.data.executor_sole_primary)
      formData.append('executor_primary', this.data.executor_primary)
      formData.append('executor_secondary', this.data.executor_secondary)
      formData.append('executor_backup', this.data.executor_backup)
      formData.append('trustee_sole_primary', this.data.trustee_sole_primary)
      formData.append('trustee_primary', this.data.trustee_primary)
      formData.append('trustee_secondary', this.data.trustee_secondary)
      formData.append('trustee_backup', this.data.trustee_backup)
      formData.append('guardian_sole_primary', this.data.guardian_sole_primary)
      formData.append('guardian_primary', this.data.guardian_primary)
      formData.append('guardian_secondary', this.data.guardian_secondary)
      formData.append('guardian_backup', this.data.guardian_backup)
      formData.append('pets_sole_primary', this.data.pets_sole_primary)
      formData.append('pets_primary', this.data.pets_primary)
      formData.append(
        'residential_property_occupant',
        this.data.residential_property_occupant
      )
      if (this.person) {
        http
          .patch('/wills/api/person', formData)
          .then((response) => {
            this.$notification.success('Person updated')
            this.$store.commit('personAdd', response.data)
            this.closeModal()
            this.clearForm()
          })
          .catch((error) => {
            console.log(error)
            this.closeModal()
            this.clearForm()
          })
      } else {
        http
          .post('/wills/api/person', formData)
          .then((response) => {
            this.$notification.success('Person created')
            this.$store.commit('personAdd', response.data)
            this.closeModal()
            this.clearForm()
          })
          .catch((error) => {
            console.log(error)
            this.closeModal()
            this.clearForm()
          })
      }
    },
    setChildRelationship() {
      var relationship = ''
      if (
        this.partner &&
        !this.data.child_born_to.includes(
          this.$store.getters.personalDetails.full_name
        )
      )
        relationship = this.partner.relationship + "'s "
      if (this.data.gender === 'Male') relationship += 'son'
      else relationship += 'daughter'
      return relationship
    },
    setDob(value) {
      var now = new Date()
      var max18 = new Date(
        now.getFullYear() - 18,
        now.getMonth(),
        now.getDate()
      )
      var max25 = new Date(
        now.getFullYear() - 25,
        now.getMonth(),
        now.getDate()
      )
      var month = value.getMonth() + 1
      this.data.dob_day = value.getDate()
      this.data.dob_month = month > 9 ? month : '0' + month
      this.data.dob_year = value.getFullYear()
      this.data.under18 = false
      this.data.under25 = false
      if (value.getTime() > max18.getTime()) {
        this.data.under18 = true
        this.data.under25 = false
      } else if (value.getTime() > max25.getTime()) {
        this.data.under18 = false
        this.data.under25 = true
      }
    }
  }
}
</script>

<style scoped>
.reduce-width {
  width: 48%;
}
</style>
