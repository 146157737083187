<template>
  <tr v-bind:class="{ 'liability-row': liability }" class="app-table-row">
    <td class="beneficiary-name">
      <img :src="imageUrl" alt="residential" />
      <h5 class="inline-block align-middle" v-html="text"></h5>
    </td>
    <td class="beneficiary-percentage">
      <div class="quantity">
        <span v-on:click="minus" class="quantity-minus minus-large">-</span>
        <input
          v-model.number="value"
          @input="save"
          @change="save"
          @blur="save"
          type="number"
          inputmode="numeric"
          pattern="[0-9]*"
          class="input-pc"
        />
        <span v-on:click="plus" class="quantity-plus plus-large">+</span>
      </div>
      <div class="error-container">
        <ErrorPop
          v-show="error"
          :message="error"
          :displayClose="false"
          @clearError="error = null"
        ></ErrorPop>
      </div>
    </td>
  </tr>
</template>
<script>
import ErrorPop from '@/common/ui/ErrorPop'

export default {
  name: 'AmountTableRow',
  props: ['text', 'imageUrl', 'initial', 'property', 'increment', 'liability'],
  components: {
    ErrorPop
  },
  mounted() {
    setTimeout(() => this.save(), 10000)
  },
  watch: {
    initial: {
      deep: true,
      immediate: true,
      handler(amount) {
        this.value = amount
      }
    },
    value: {
      deep: true,
      immediate: false,
      handler(value) {
        if (this.value < 0 || this.value === null || this.value === '') {
          this.error = 'Must be 0 or greater'
        } else {
          this.error = false
        }
      }
    },
    error: {
      deep: true,
      immediate: true,
      handler(value) {
        var e = false
        if (value || (!this.initial && this.initial !== 0)) e = true
        var objectCopy = Object.assign({}, this.property)
        objectCopy[Object.keys(this.property)[0]] = e
        this.$emit('error', objectCopy)
      }
    }
  },
  data() {
    return {
      value: null,
      error: null
    }
  },

  methods: {
    save() {
      if (this.value < 0 || this.value === '' || this.value === null)
        this.error = 'Must be 0 or greater'
      var objectCopy = Object.assign({}, this.property)
      objectCopy[Object.keys(this.property)[0]] = this.value
      this.$emit('change', objectCopy)
    },
    plus() {
      if (typeof this.value === 'string') {
        this.value = 0
      }
      this.value += Number(this.increment)
      this.save()
    },
    minus() {
      if (this.value >= this.increment) this.value -= this.increment
      else this.value = 0
      this.save()
    }
  }
}
</script>

<style scoped>
.error-container {
  position: relative;
  left: 80px;
  top: 20px;
}
</style>
