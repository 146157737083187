<template>
  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
    <div class="control">
      <label class="switch" :class="{ small: small }">
        <input
          v-on:click="$emit('toggle')"
          class="switch"
          type="checkbox"
          v-bind:checked="checked"
        />
        <span class="slider round"></span>
        <span v-if="text" class="text">{{ text }}</span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputSwitch',
  props: ['checked', 'text', 'small']
}
</script>

<style scoped>
.text {
  position: absolute;
  content: 'Increase amount with inflation?';
  top: 6px;
  left: -160px;
  width: 150px;
  text-align: center;
}

.small {
  width: 77px !important;
  height: 41px !important;
}
</style>
