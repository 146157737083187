<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <InputOptionSmall
        :letter="'a'"
        :option="'I own 100%'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-shares-myself.png'"
        :selected="assets.shareholders === '100%'"
        @selected="save('100%')"
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'b'"
        :option="'Multiple shareholders'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-shares-myselfothers.png'"
        :selected="assets.shareholders === '51%-99%'"
        @selected="save('51%-99%')"
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'c'"
        :option="'Myself and my partner'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-shares-myselfpartner.png'"
        :selected="assets.shareholders === '1%-50%'"
        @selected="save('1%-50%')"
      ></InputOptionSmall>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="backTo"
        v-bind:center="false"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputOptionSmall from '@/common/ui/InputOptionSmall'

export default {
  name: 'AssetsBusinessShares',
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputOptionSmall
  },
  computed: {
    assets() {
      return this.$store.getters.assets
    },
    number() {
      if (this.requireJoint) return '8'
      return '7'
    },
    isValidated() {
      if (this.assets.shareholders) return true
      return false
    },
    requireJoint() {
      if (
        this.assets.blt_property ||
        this.assets.residential_property ||
        this.assets.residential_property ||
        this.assets.blt_property ||
        this.assets.savings_bank ||
        this.assets.life_insurance ||
        this.assets.shares_investments ||
        this.assets.endowment
      )
        return true
      return false
    },
    requireAmounts() {
      if (
        this.assets.credit_cards ||
        this.assets.loans ||
        this.assets.business ||
        this.assets.household ||
        this.assets.vehicle ||
        this.assets.pension ||
        this.assets.gold_jewellery
      )
        return true
      return false
    },
    heading() {
      return 'Who owns the shares in your business?'
    },
    subHeading() {
      return (
        ' From shares in a Limited Company to assets owned by a Sole Trader, most ' +
        'business assets can be passed under a Will. The type of business you own can however ' +
        'influence the way your Will is structured so please clarify the nature of your business below.'
      )
    },
    forwardTo() {
      return '/assets/aka'
    },
    backTo() {
      return '/assets/business_type'
    }
  },
  methods: {
    save(value) {
      this.$store.commit('assets', {
        shareholders: value
      })
    }
  }
}
</script>
