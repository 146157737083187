<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <PersonBlank
        :name="personalDetails.full_name"
        :gender="personalDetails.sex"
        relationship="Myself"
        :selected="true"
      />
      <Person
        v-for="(person, id) in people"
        v-bind:key="id"
        v-bind:person="person"
        @selected="save"
        @edit="edit"
        :type="'residential_property_occupant'"
      />
      <PersonAddQuad @addPerson="setNewPerson" :brotherSister="true">
      </PersonAddQuad>
      <PersonModal
        v-show="show.personModal"
        :show="show.personModal"
        :person="selectedPerson"
        :initialPerson="initialPerson"
        @close="closePersonModal"
      ></PersonModal>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="backTo"
        v-bind:center="false"
        v-bind:loading="loading"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import { willStringHelpers } from '@/common/mixins/willStringHelpers'
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import Person from '@/common/ui/Person'
import PersonModal from '@/views/pages/PeoplePage/PersonModal'
import PersonAddQuad from '@/common/ui/PersonAddQuad'
import PersonBlank from '../../../../common/ui/PersonBlank'

export default {
  name: 'AssetsPropertyOccupants',
  mixins: [willStringHelpers],
  components: {
    NavQuestion,
    NavQuestionButtons,
    Person,
    PersonModal,
    PersonAddQuad,
    PersonBlank
  },
  computed: {
    heading() {
      if (this.moving)
        return 'Who will be living with you at your new property?'
      return `Who lives at ${this.address}?`
    },
    number() {
      return '5'
    },
    subHeading() {
      if (this.moving) {
        return `Please ensure that all the people living your new address are selected. This information will not effect the wording of your Will, instead it helps us to make sure that you get the right advice where necessary.`
      }
      return `Please ensure that all the people living at ${this.address} are selected. This information will not effect the wording of your Will, instead it helps us to make sure that you get the right advice where necessary.`
    },
    occupants() {
      return this.people.filter(
        (person) => person.residential_property_occupant
      )
    },
    personalDetails() {
      return this.$store.getters.personalDetails
    },
    address() {
      return (
        this.personalDetails.address_line_1 +
        ', ' +
        this.personalDetails.address_line_2
      )
    },
    people() {
      return this.$store.getters.people
    },
    details() {
      return this.$store.getters.details
    },
    partner() {
      return this.$store.getters.partner
    },
    forwardTo() {
      return '/assets/outside_england_wales'
    },
    backTo() {
      return '/assets/joint'
    },
    isValidated() {
      return true
    },
    moving() {
      return this.$store.getters.personalDetails.moving
    }
  },
  data() {
    return {
      loading: false,
      selectedPerson: null,
      initialPerson: null,
      show: {
        personModal: false
      }
    }
  },
  methods: {
    save(person) {
      var copyPerson = Object.assign({}, person)
      copyPerson.residential_property_occupant = !person.residential_property_occupant
      this.$store.commit('personAdd', copyPerson)
    },
    edit(person) {
      this.selectedPerson = person
      this.show.personModal = true
    },
    closePersonModal() {
      this.selectedPerson = null
      this.show.personModal = false
      this.initialPerson = null
    },
    setNewPerson(value) {
      value.push('residential_property_occupant')
      this.initialPerson = value
      this.show.personModal = true
      this.selectedPerson = null
    }
  }
}
</script>
