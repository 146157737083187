<template>
  <NavCheckAndConfirm :text="assetsDescription" :backTo="backTo">
    <template></template>
  </NavCheckAndConfirm>
</template>

<script>
import NavCheckAndConfirm from '@/common/ui/NavCheckAndConfirm'
import { willStringHelpers } from '@/common/mixins/willStringHelpers'
import { sectionDescriptions } from '@/common/mixins/sectionDescriptions'

export default {
  name: 'AssetsCheckAndConfirm',
  mixins: [willStringHelpers, sectionDescriptions],
  components: {
    NavCheckAndConfirm
  },
  computed: {
    assets() {
      return this.$store.getters.assets
    },
    backTo() {
      if (this.assets && this.assets.life_insurance) return '/assets/trust'
      return '/assets/aka'
    }
  },
  methods: {},
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('saveAssets')
    this.$store.dispatch('savePeople')
    this.$store.dispatch('saveDetails')
    this.$notification.success('Assets and Liabilities details saved')
    next()
  }
}
</script>

<style></style>
