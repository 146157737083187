<template>
  <NavQuestion
      :background="'bg-personal-details'"
      :heading="heading"
      :number="number"
      :questionOptions="true"
      :subHeading="subHeading"
  >
    <template v-slot:contents>
      <div class="input-container">
        <v-select
            v-model="selected"
            :options="countryOptions"
            :reduce="(label) => label.code"
            multiple
            :searchable="true"
            class="grey"
            placeholder="Select Country"
            @input="save"
        />
      </div>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
          v-bind:backLink="backTo"
          v-bind:center="false"
          v-bind:forwardLink="forwardTo"
          v-bind:forwardText="'Next'"
          v-bind:validated="isValidated"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import {countryOptions} from "./options/countryOptions";

export default {
  name: 'AssetsOutsideCountry',
  components: {
    NavQuestion,
    NavQuestionButtons,
    vSelect
  },
  mounted() {
    this.selected = this.assets.assets_outside_uk_country
  },
  mixins: [countryOptions],
  watch: {
    selected: {
      immediate: false,
      deep: true,
      handler() {
        this.save()
      }
    }
  },
  computed: {
    assets() {
      return this.$store.getters.assets
    },
    requireJoint() {
      if (
          this.assets.blt_property ||
          this.assets.residential_property ||
          this.assets.residential_property ||
          this.assets.blt_property ||
          this.assets.savings_bank ||
          this.assets.life_insurance ||
          this.assets.shares_investments ||
          this.assets.endowment
      )
        return true
      return false
    },
    requireAmountsUrl() {
      if (
          this.assets.credit_cards ||
          this.assets.loans ||
          this.assets.business ||
          this.assets.household ||
          this.assets.vehicle ||
          this.assets.pension ||
          this.assets.gold_jewellery
      )
        return true
      return false
    },
    requireAmounts() {
      if (
          this.assets.blt_property ||
          this.assets.residential_property ||
          this.assets.residential_property ||
          this.assets.blt_property ||
          this.assets.savings_bank ||
          this.assets.life_insurance ||
          this.assets.shares_investments ||
          this.assets.endowment ||
          this.assets.credit_cards ||
          this.assets.loans ||
          this.assets.business ||
          this.assets.household ||
          this.assets.vehicle ||
          this.assets.pension ||
          this.assets.gold_jewellery
      )
        return true
      return false
    },
    number() {
      var questionNumber = 3
      if (this.assets.residential_property) questionNumber += 1
      if (this.assets.assets_outside_uk) questionNumber += 1
      if (this.requireJoint) questionNumber += 1
      if (this.requireAmounts) questionNumber += 1
      return questionNumber
    },
    forwardTo() {
      if (this.assets.business) return '/assets/business_type'
      return '/assets/aka'
    },
    backTo() {
      if (this.assets.assets_outside_uk) return '/assets/outside_england_wales'
      if (this.assets.residential_property) return '/assets/property_occupants'
      else if (this.requireJoint) return '/assets/joint'
      else if (this.requireAmountsUrl) return '/assets/amounts'
      return '/assets/liabilities'
    },
    heading() {
      return 'Which country do you own assets in?'
    },
    subHeading() {
      return (
          ''
      )
    },
    isValidated() {
      return true
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('saveAssets')
    next()
  },
  data() {
    return {
      selected: [],
      show: {
        intro: true
      }
    }
  },
  methods: {
    save(value) {
      if (this.assets) {
        this.$store.commit('assets', {
          assets_outside_uk_country: this.selected
        })
      }
    }
  }
}
</script>

<style>
.vs--multiple .vs__selected {
  background-color: #4f9b7e;
}

.grey .vs__dropdown-toggle {
  background: #f9f9f9;
  border-bottom-color: #e56781;
  border-bottom-width: 3px;
}
</style>
