<template>
  <BaseModalSmall
      @close="close"
      v-bind:isValidated="true"
      @confirm="close"
      :noSlide="true"
  >
    <template v-slot:header>Note</template>
    <template v-slot:body>
      <div>
        <p>
          Although this Will includes all assets owned in your name, jointly owned assets could pass
          to the surviving joint owner(s) irrespective of the wishes in your Will (if the joint owner(s) pass before you
          then the assets will be held in your sole name and will then pass as part of your estate).
        </p>
      </div>
    </template>
    <template v-slot:button>Continue</template>
  </BaseModalSmall>
</template>

<script>
import BaseModalSmall from '@/common/ui/BaseModalSmall'

export default {
  name: 'JointWarning',
  components: {
    BaseModalSmall
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>
