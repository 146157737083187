<template>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 option-padding">
    <div
      v-on:click="select"
      v-bind:class="{
        selected: selected,
        'pricing-option': pricing,
        'popular-option': popular
      }"
      class="input-option input-option-big display-flex"
      role="button"
    >
      <div class="tick-wrapper"></div>
      <div v-if="!pricing" class="tick"></div>

      <div
        v-if="pricing"
        class="pricing-text"
        v-bind:class="{ package: subPricing }"
      >
        {{ pricing }}
      </div>
      <div v-if="subPricing" class="pricing-text-frequency">
        {{ subPricing }}
      </div>

      <div class="image-wrapper left">
        <img v-bind:src="image" />
      </div>
      <div class="input-option-inner-wrapper">
        <div>
          <div class="label pb30">
            <div class="letter">
              {{ letter }}
            </div>
            <div class="caption">
              {{ option }}
            </div>
          </div>
        </div>
        <div class="text-long">
          <slot name="text"></slot>
        </div>
        <transition name="slide">
          <div v-show="selected" class="text-long">
            <slot name="input"></slot>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputOptionLarge',
  props: [
    'letter',
    'option',
    'pricing',
    'image',
    'selected',
    'subPricing',
    'popular'
  ],
  methods: {
    select() {
      this.$emit('selected')
    }
  }
}
</script>
