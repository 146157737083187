<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  mounted() {
    window.addEventListener('keyup', function (event) {
      var nextButton = document.getElementById('js-next')
      var questionNav = document.getElementById('js-question-nav')
      var introModal = document.getElementById('js-intro-modal')
      var adviceModal = document.getElementById('js-adviceModal')
      if (event.key === 'Enter') {
        if (
          document.activeElement &&
          document.activeElement.tagName !== 'INPUT' &&
          document.activeElement.tagName !== 'TEXTAREA' &&
          document.activeElement.tagName !== 'SELECT'
        ) {
          document.activeElement.blur()
          if (introModal && introModal.classList.contains('show')) {
            introModal.click()
          } else if (adviceModal && adviceModal.classList.contains('show')) {
            // adviceModal.click()
          } else if (
            questionNav &&
            questionNav.classList.contains('validated')
          ) {
            nextButton.click()
          }
        }
      }
    })
  }
}
</script>
<style>
.left-sidebar #sidebarnav #js-side-menu-checkout.selected .wrapper {
  max-height: 900px;
}

.reset-numbers {
  counter-reset: list;
}

.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.notificationCenter {
  z-index: 102 !important;
}

.font-success {
  color: #4f9b7e;
}

.font-primary {
  color: #345a7a;
}

.font-secondary {
  color: #e56781;
}

.font-bold {
  font-weight: bolder;
}

.mb60 {
  margin-bottom: 60px;
}

.mb30 {
  margin-bottom: 30px;
}

.link {
  cursor: pointer;
  text-decoration: underline;
}
@media screen and (max-width: 570px) {
  .vdp-datepicker__calendar {
    position: static !important;
  }
}
/* icons fa */
i.fa-trash-alt {
  font-size: 18px;
}
i.fa-pen {
  font-size: 18px;
}
i.fa-eraser {
  font-size: 20px;
}
</style>
