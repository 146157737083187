import Vue from 'vue'
import Router from 'vue-router'

import AssetsPage from '@/views/pages/AssetsLiabilitiesPage/index'
import AssetsAssets from '@/views/pages/AssetsLiabilitiesPage/Questions/Assets'
import AssetsLiabilities from '@/views/pages/AssetsLiabilitiesPage/Questions/Liabilities'
import AssetsAmounts from '@/views/pages/AssetsLiabilitiesPage/Questions/Amounts'
import AssetsJoint from '@/views/pages/AssetsLiabilitiesPage/Questions/Joint'
import AssetsOutsideEnglandWales from '@/views/pages/AssetsLiabilitiesPage/Questions/OutsideEnglandWales'
import AssetsOutsideCountry from '@/views/pages/AssetsLiabilitiesPage/Questions/OutsideAssetsCountry'
import AssetsBusinessType from '@/views/pages/AssetsLiabilitiesPage/Questions/BusinessType'
import AssetsBusinessShares from '@/views/pages/AssetsLiabilitiesPage/Questions/BusinessShares'
import AssetsAka from '@/views/pages/AssetsLiabilitiesPage/Questions/Aka'
import AssetsTrust from '@/views/pages/AssetsLiabilitiesPage/Questions/Trust'
import AssetsPropertyOccupants from '@/views/pages/AssetsLiabilitiesPage/Questions/PropertyOccupants'
import AssetsCheckAndConfirm from '@/views/pages/AssetsLiabilitiesPage/CheckAndConfirm'

const FuneralPage = () => import('@/views/pages/FuneralPage/index')
const FuneralType = () => import('@/views/pages/FuneralPage/Questions/Type')
const FuneralStyle = () => import('@/views/pages/FuneralPage/Questions/Style')
const FuneralPlan = () => import('@/views/pages/FuneralPage/Questions/Plan')
const FuneralWishes = () => import('@/views/pages/FuneralPage/Questions/Wishes')
const FuneralWishesOption = () =>
  import('@/views/pages/FuneralPage/Questions/WishesOption')
const FuneralOrganDonor = () =>
  import('@/views/pages/FuneralPage/Questions/OrganDonor')
const FuneralCheckAndConfirm = () =>
  import('@/views/pages/FuneralPage/CheckAndConfirm')

const GiftsPage = () => import('@/views/pages/GiftsPage/index')
const GiftsLeaveOption = () =>
  import('@/views/pages/GiftsPage/Questions/LeaveOption')
const GiftsDirectOption = () =>
  import('@/views/pages/GiftsPage/Questions/DirectOption')
const GiftsDirect = () => import('@/views/pages/GiftsPage/Questions/Direct')
const GiftsIndirectOption = () =>
  import('@/views/pages/GiftsPage/Questions/IndirectOption')
const GiftsIndirect = () => import('@/views/pages/GiftsPage/Questions/Indirect')
const GiftsCheckAndConfirm = () =>
  import('@/views/pages/GiftsPage/CheckAndConfirm')

const DistributionPartner = () =>
  import('@/views/pages/DistributionPage/Questions/Partner')
const DistributionSplit = () =>
  import('@/views/pages/DistributionPage/Questions/Split')
const DistributionPerson = () =>
  import('@/views/pages/DistributionPage/Questions/Person')
const DistributionGroup = () =>
  import('@/views/pages/DistributionPage/Questions/Group')
const DistributionAllFail = () =>
  import('@/views/pages/DistributionPage/Questions/AllFail')
const DistributionSplitBackup = () =>
  import('@/views/pages/DistributionPage/Questions/SplitBackup')
const DistributionMinors = () =>
  import('@/views/pages/DistributionPage/Questions/Minors')
const DistributionDisabledOption = () =>
  import('@/views/pages/DistributionPage/Questions/DisabledOption')
const DistributionDisabled = () =>
  import('@/views/pages/DistributionPage/Questions/Disabled')

const DistributionCheckAndConfirm = () =>
  import('@/views/pages/DistributionPage/CheckAndConfirm')

const DistributionPage = () => import('@/views/pages/DistributionPage/index')

const PetsPage = () => import('@/views/pages/PetsPage/index')
const PetsPrimaryOption = () =>
  import('@/views/pages/PetsPage/Questions/PrimaryOption')
const PetsPartner = () => import('@/views/pages/PetsPage/Questions/Partner')
const PetsPrimary = () => import('@/views/pages/PetsPage/Questions/Primary')
const PetsCash = () => import('@/views/pages/PetsPage/Questions/Cash')
const PetsCheckAndConfirm = () =>
  import('@/views/pages/PetsPage/CheckAndConfirm')

const Overview = () => import('@/views/pages/MainMenuView/Overview')

const ProfilePage = () => import('@/views/pages/ProfilePage/index')
const ProfileName = () => import('@/views/pages/ProfilePage/Questions/Name')
const ProfileGender = () => import('@/views/pages/ProfilePage/Questions/Gender')
const ProfileChildren = () =>
  import('@/views/pages/ProfilePage/Questions/Children')
const ProfilePets = () => import('@/views/pages/ProfilePage/Questions/Pets')
const ProfileDob = () => import('@/views/pages/ProfilePage/Questions/Dob')
const ProfileAddress = () =>
  import('@/views/pages/ProfilePage/Questions/Address')
const ProfileResidentialStatus = () => import('@/views/pages/ProfilePage/Questions/ResidentialStatus')
const ProfileMaritalStatus = () =>
  import('@/views/pages/ProfilePage/Questions/MaritalStatus')
const ProfilePartnerName = () =>
  import('@/views/pages/ProfilePage/Questions/PartnerName')
const ProfilePartnerGender = () =>
  import('@/views/pages/ProfilePage/Questions/PartnerGender')
const ProfileWillFor = () =>
  import('@/views/pages/ProfilePage/Questions/WillFor')
const ProfileCharityOption = () =>
  import('@/views/pages/ProfilePage/Questions/CharityOption')
const ProfileCharityAmount = () =>
  import('@/views/pages/ProfilePage/Questions/CharityAmount')
const ProfileCharityPercentage = () =>
  import('@/views/pages/ProfilePage/Questions/CharityPercentage')
const ProfileUsername = () =>
  import('@/views/pages/ProfilePage/Questions/Username')
const ProfilePassword = () =>
  import('@/views/pages/ProfilePage/Questions/Password')
const ProfileCheckAndConfirm = () =>
  import('@/views/pages/ProfilePage/CheckAndConfirm')

const Executors = () => import('@/views/pages/ExecutorsPage/index')
const ExecutorsTypeOf = () =>
  import('@/views/pages/ExecutorsPage/Questions/TypeOf')
const ExecutorsPartner = () =>
  import('@/views/pages/ExecutorsPage/Questions/Partner')
const ExecutorsPrimary = () =>
  import('@/views/pages/ExecutorsPage/Questions/Primary')
const ExecutorsSecondary = () =>
  import('@/views/pages/ExecutorsPage/Questions/Secondary')
const ExecutorsBackup = () =>
  import('@/views/pages/ExecutorsPage/Questions/Backup')
const ExecutorsSecondaryOption = () =>
  import('@/views/pages/ExecutorsPage/Questions/SecondaryOption')
const ExecutorsBackupOption = () =>
  import('@/views/pages/ExecutorsPage/Questions/BackupOption')
const ExecutorsCheckAndConfirm = () =>
  import('@/views/pages/ExecutorsPage/CheckAndConfirm')

const TrusteesPage = () => import('@/views/pages/TrusteesPage/index')
const ExecutorsAsTrustees = () =>
  import('@/views/pages/TrusteesPage/Questions/ExecutorsAsTrustees')
const TrusteesPartner = () =>
  import('@/views/pages/TrusteesPage/Questions/Partner')
const TrusteesCheckAndConfirm = () =>
  import('@/views/pages/TrusteesPage/CheckAndConfirm')
const TrusteesPrimary = () =>
  import('@/views/pages/TrusteesPage/Questions/Primary')
const TrusteesSecondary = () =>
  import('@/views/pages/TrusteesPage/Questions/Secondary')
const TrusteesSecondaryOption = () =>
  import('@/views/pages/TrusteesPage/Questions/SecondaryOption')
const TrusteesBackup = () =>
  import('@/views/pages/TrusteesPage/Questions/Backup')
const TrusteesBackupOption = () =>
  import('@/views/pages/TrusteesPage/Questions/BackupOption')

const GuardiansPage = () => import('@/views/pages/GuardiansPage/index')
const GuardiansPrimary = () =>
  import('@/views/pages/GuardiansPage/Questions/Primary')
const GuardiansSecondaryOption = () =>
  import('@/views/pages/GuardiansPage/Questions/SecondaryOption')
const GuardiansSecondary = () =>
  import('@/views/pages/GuardiansPage/Questions/Secondary')
const GuardiansCheckAndConfirm = () =>
  import('@/views/pages/GuardiansPage/CheckAndConfirm')

const ChildrenPage = () => import('@/views/pages/ChildrenPage/index')
const ChildrenYourChildren = () =>
  import('@/views/pages/ChildrenPage/Questions/YourChildren')
const ChildrenCheckAndConfirm = () =>
  import('@/views/pages/ChildrenPage/CheckAndConfirm')

const CreateWillPage = () =>
  import('@/views/pages/CheckoutView/CreateWillPage/index')
const WillNotReadyPage = () =>
  import('@/views/pages/CheckoutView/WillNotReadyPage/index')
const StoragePage = () => import('@/views/pages/CheckoutView/StoragePage/index')
const PrintingPage = () =>
  import('@/views/pages/CheckoutView/PrintingPage/index')
const CheckingPage = () =>
  import('@/views/pages/CheckoutView/CheckingPage/index')
const PayPage = () => import('@/views/pages/CheckoutView/PayPage/index')
const DirectDebitSetupPage = () =>
  import('@/views/pages/CheckoutView/DirectDebit/Setup')
const DirectDebitConfirmationPage = () =>
  import('@/views/pages/CheckoutView/DirectDebit/Confirmation')
const AdvicePage = () => import('@/views/pages/CheckoutView/AdvicePage/index')
const SharePage = () => import('@/views/pages/CheckoutView/SharePage/index')

const PeoplePage = () => import('@/views/pages/PeoplePage/index')
const AccountPage = () => import('@/views/pages/AccountPage/index')
const DocumentsPage = () => import('@/views/pages/DocumentsPage/index')
const DocumentsFinalWill = () => import('@/views/pages/DocumentsPage/FinalWill')
const DraftWillPage = () =>
  import('@/views/pages/CheckoutView/DraftWillPage/index')

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '',
      redirect: '/home'
    },
    {
      path: '',
      component: () => import('@/common/layout/MainPage.vue'),
      children: [
        {
          path: '/home',
          name: 'Overview',
          component: Overview
        },
        {
          path: '/share',
          name: 'ShareView',
          component: SharePage
        },
        {
          path: '/create_will',
          name: 'CreateWillPage',
          component: CreateWillPage
        },
        {
          path: '/storage',
          name: 'StoragePage',
          component: StoragePage
        },
        {
          path: '/printing',
          name: 'PrintingPage',
          component: PrintingPage
        },
        {
          path: '/checking',
          name: 'CheckingPage',
          component: CheckingPage
        },
        {
          path: '/pay',
          name: 'PayPage',
          component: PayPage
        },
        {
          path: '/pay/direct_debit',
          name: 'DirectDebitSetupPage',
          component: DirectDebitSetupPage
        },
        {
          path: '/pay/direct_debit_confirmation',
          name: 'DirectDebitConfirmationPage',
          component: DirectDebitConfirmationPage
        },
        {
          path: '/advice',
          name: 'AdvicePage',
          component: AdvicePage
        },
        {
          path: '/new_customer',
          name: 'NewCustomer',
          component: SharePage
        },
        {
          path: '/will_not_ready',
          name: 'WillNotReadyPage',
          component: WillNotReadyPage
        },
        {
          path: '/draft_will',
          name: 'DraftWillPage',
          component: DraftWillPage
        },
        {
          path: '/profile',
          name: 'ProfilePage',
          component: ProfilePage
        },
        {
          path: '/profile/name',
          name: 'ProfileName',
          component: ProfileName,
          alias: '/start/name'
        },
        {
          path: '/profile/gender',
          name: 'ProfileGender',
          component: ProfileGender
        },
        {
          path: '/profile/children',
          name: 'ProfileChildren',
          component: ProfileChildren
        },
        {
          path: '/profile/pets',
          name: 'ProfilePets',
          component: ProfilePets
        },
        {
          path: '/profile/dob',
          name: 'ProfileDob',
          component: ProfileDob
        },
        {
          path: '/profile/address',
          name: 'ProfileAddress',
          component: ProfileAddress
        },
        {
          path: '/profile/residential_status',
          name: 'ProfileResidentialStatus',
          component: ProfileResidentialStatus
        },
        {
          path: '/profile/marital_status',
          name: 'ProfileMaritalStatus',
          component: ProfileMaritalStatus
        },
        {
          path: '/profile/partner_name',
          name: 'ProfilePartnerName',
          component: ProfilePartnerName
        },
        {
          path: '/profile/partner_gender',
          name: 'ProfilePartnerGender',
          component: ProfilePartnerGender
        },
        {
          path: '/profile/will_for',
          name: 'ProfileWillFor',
          component: ProfileWillFor
        },
        {
          path: '/profile/charity_option',
          name: 'ProfileCharityOption',
          component: ProfileCharityOption
        },
        {
          path: '/profile/charity_amount',
          name: 'ProfileCharityAmount',
          component: ProfileCharityAmount
        },
        {
          path: '/profile/charity_percentage',
          name: 'ProfileCharityPercentage',
          component: ProfileCharityPercentage
        },
        {
          path: '/profile/check_and_confirm',
          name: 'ProfileCheckAndConfirm',
          component: ProfileCheckAndConfirm
        },
        {
          path: '/trustees',
          name: 'TrusteesPage',
          component: TrusteesPage
        },
        {
          path: '/trustees/partner',
          name: 'TrusteesPartner',
          component: TrusteesPartner
        },
        {
          path: '/trustees/check_and_confirm',
          name: 'TrusteesCheckAndConfirm',
          component: TrusteesCheckAndConfirm
        },
        {
          path: '/trustees/executors_as_trustees',
          name: 'ExecutorsAsTrustees',
          component: ExecutorsAsTrustees
        },
        {
          path: '/trustees/primary',
          name: 'TrusteesPrimary',
          component: TrusteesPrimary
        },
        {
          path: '/trustees/secondary',
          name: 'TrusteesSecondary',
          component: TrusteesSecondary
        },
        {
          path: '/trustees/secondary_option',
          name: 'TrusteesSecondaryOption',
          component: TrusteesSecondaryOption
        },
        {
          path: '/trustees/backup',
          name: 'TrusteesBackup',
          component: TrusteesBackup
        },
        {
          path: '/trustees/backup_option',
          name: 'TrusteesBackupOption',
          component: TrusteesBackupOption
        },
        {
          path: '/distribution',
          name: 'DistributionPage',
          component: DistributionPage
        },
        {
          path: '/distribution/partner',
          name: 'DistributionPartner',
          component: DistributionPartner
        },
        {
          path: '/distribution/beneficiaries',
          name: 'DistributionSplit',
          component: DistributionSplit
        },
        {
          path: '/distribution/primary_person/:name/:id',
          name: 'DistributionPerson',
          component: DistributionPerson
        },
        {
          path: '/distribution/primary_group/:name/:id',
          name: 'DistributionGroup',
          component: DistributionGroup
        },
        {
          path: '/distribution/all_fail',
          name: 'DistributionAllFail',
          component: DistributionAllFail
        },
        {
          path: '/distribution/substitute_beneficiaries',
          name: 'DistributionSplitBackup',
          component: DistributionSplitBackup
        },
        {
          path: '/distribution/minors',
          name: 'DistributionMinors',
          component: DistributionMinors
        },
        {
          path: '/distribution/disability_option',
          name: 'DistributionDisabledOption',
          component: DistributionDisabledOption
        },
        {
          path: '/distribution/disability_details',
          name: 'DistributionDisabled',
          component: DistributionDisabled
        },
        {
          path: '/distribution/check_and_confirm',
          name: 'DistributionCheckAndConfirm',
          component: DistributionCheckAndConfirm
        },
        {
          path: '/gifts',
          name: 'GiftsPage',
          component: GiftsPage
        },
        {
          path: '/gifts/leave_option',
          name: 'GiftsLeaveOption',
          component: GiftsLeaveOption
        },
        {
          path: '/gifts/direct_option',
          name: 'GiftsDirectOption',
          component: GiftsDirectOption
        },
        {
          path: '/gifts/direct',
          name: 'GiftsDirect',
          component: GiftsDirect
        },
        {
          path: '/gifts/indirect_option',
          name: 'GiftsIndirectOption',
          component: GiftsIndirectOption
        },
        {
          path: '/gifts/indirect',
          name: 'GiftsIndirect',
          component: GiftsIndirect
        },
        {
          path: '/gifts/check_and_confirm',
          name: 'GiftsCheckAndConfirm',
          component: GiftsCheckAndConfirm
        },
        {
          path: '/pets',
          name: 'PetsPage',
          component: PetsPage
        },
        {
          path: '/pets/primary_option',
          name: 'PetsPrimaryOption',
          component: PetsPrimaryOption
        },
        {
          path: '/pets/partner',
          name: 'PetsPartner',
          component: PetsPartner
        },
        {
          path: '/pets/primary',
          name: 'PetsPrimary',
          component: PetsPrimary
        },
        {
          path: '/pets/cash',
          name: 'PetsCash',
          component: PetsCash
        },
        {
          path: '/pets/check_and_confirm',
          name: 'PetsCheckAndConfirm',
          component: PetsCheckAndConfirm
        },
        {
          path: '/funeral',
          name: 'FuneralPage',
          component: FuneralPage
        },
        {
          path: '/funeral/type',
          name: 'FuneralType',
          component: FuneralType
        },
        {
          path: '/funeral/style',
          name: 'FuneralStyle',
          component: FuneralStyle
        },
        {
          path: '/funeral/plan',
          name: 'FuneralPlan',
          component: FuneralPlan
        },
        {
          path: '/funeral/organ_donor',
          name: 'FuneralOrganDonor',
          component: FuneralOrganDonor
        },
        {
          path: '/funeral/wishes_option',
          name: 'FuneralWishesOption',
          component: FuneralWishesOption
        },
        {
          path: '/funeral/wishes',
          name: 'FuneralWishes',
          component: FuneralWishes
        },
        {
          path: '/funeral/check_and_confirm',
          name: 'FuneralCheckAndConfirm',
          component: FuneralCheckAndConfirm
        },
        {
          path: '/guardians',
          name: 'GuardiansPage',
          component: GuardiansPage
        },
        {
          path: '/guardians/primary',
          name: 'GuardiansPrimary',
          component: GuardiansPrimary
        },
        {
          path: '/guardians/secondary_option',
          name: 'GuardiansSecondaryOption',
          component: GuardiansSecondaryOption
        },
        {
          path: '/guardians/secondary',
          name: 'GuardiansSecondary',
          component: GuardiansSecondary
        },
        {
          path: '/guardians/check_and_confirm',
          name: 'GuardiansCheckAndConfirm',
          component: GuardiansCheckAndConfirm
        },
        {
          path: '/executors',
          name: 'Executors',
          component: Executors
        },
        {
          path: '/executors/type_of',
          name: 'ExecutorsTypeOf',
          component: ExecutorsTypeOf
        },
        {
          path: '/executors/partner',
          name: 'ExecutorsPartner',
          component: ExecutorsPartner
        },
        {
          path: '/executors/primary',
          name: 'ExecutorsPrimary',
          component: ExecutorsPrimary
        },
        {
          path: '/executors/secondary',
          name: 'ExecutorsSecondary',
          component: ExecutorsSecondary
        },
        {
          path: '/executors/backup',
          name: 'ExecutorsBackup',
          component: ExecutorsBackup
        },
        {
          path: '/executors/backup_option',
          name: 'ExecutorsBackupOption',
          component: ExecutorsBackupOption
        },
        {
          path: '/executors/secondary_option',
          name: 'ExecutorsSecondaryOption',
          component: ExecutorsSecondaryOption
        },
        {
          path: '/executors/check_and_confirm',
          name: 'ExecutorsCheckAndConfirm',
          component: ExecutorsCheckAndConfirm
        },
        {
          path: '/assets',
          name: 'AssetsPage',
          component: AssetsPage
        },
        {
          path: '/assets/assets',
          name: 'AssetsAssets',
          component: AssetsAssets
        },
        {
          path: '/assets/liabilities',
          name: 'AssetsLiabilities',
          component: AssetsLiabilities
        },
        {
          path: '/assets/amounts',
          name: 'AssetsAmounts',
          component: AssetsAmounts
        },
        {
          path: '/assets/joint',
          name: 'AssetsJoint',
          component: AssetsJoint
        },
        {
          path: '/assets/outside_england_wales',
          name: 'AssetsOutsideEnglandWales',
          component: AssetsOutsideEnglandWales
        },
        {
          path: '/assets/foreign_assets_country',
          name: 'AssetsOutsideCountry',
          component: AssetsOutsideCountry
        },
        {
          path: '/assets/business_type',
          name: 'AssetsBusinessType',
          component: AssetsBusinessType
        },
        {
          path: '/assets/business_shares',
          name: 'AssetsBusinessShares',
          component: AssetsBusinessShares
        },
        {
          path: '/assets/aka',
          name: 'AssetsAka',
          component: AssetsAka
        },
        {
          path: '/assets/trust',
          name: 'AssetsTrust',
          component: AssetsTrust
        },
        {
          path: '/assets/property_occupants',
          name: 'PropertyOccupants',
          component: AssetsPropertyOccupants
        },
        {
          path: '/assets/check_and_confirm',
          name: 'AssetsCheckAndConfirm',
          component: AssetsCheckAndConfirm
        },
        {
          path: '/children',
          name: 'ChildrenPage',
          component: ChildrenPage
        },
        {
          path: '/children/your_children',
          name: 'ChildrenYourChildren',
          component: ChildrenYourChildren
        },
        {
          path: '/children/check_and_confirm',
          name: 'ChildrenCheckAndConfirm',
          component: ChildrenCheckAndConfirm
        },
        {
          path: '/people',
          name: 'PeoplePage',
          component: PeoplePage
        },
        {
          path: '/account',
          name: 'AccountPage',
          component: AccountPage
        },
        {
          path: '/handover',
          name: 'AccountHandoverPage',
          component: () =>
            import('@/views/pages/CheckoutView/Handover/index.vue')
        },
        {
          path: '/handover/share',
          name: 'ContactHandoverPage',
          component: () =>
            import('@/views/pages/CheckoutView/Handover/Contact.vue')
        },
        {
          path: '/handover/account',
          name: 'AccountHandoverPage',
          component: () =>
            import('@/views/pages/CheckoutView/Handover/Handover.vue')
        },
        {
          path: '/documents',
          name: 'DocumentsPage',
          component: DocumentsPage
        },
        {
          path: '/documents/final_will/:id',
          name: 'DocumentsFinalWill',
          component: DocumentsFinalWill
        }
      ]
    },
    {
      path: '',
      component: () => import('@/common/layout/FullPageBanner.vue'),
      children: [
        {
          path: '/write_my_will',
          name: 'WriteMyWill',
          component: () => import('@/views/pages/WriteMyWill/index.vue')
        },
        {
          path: '/coupon/:coupon',
          name: 'WriteMyWillCoupon',
          component: () => import('@/views/pages/WriteMyWill/index.vue')
        },
        {
          path: '/coupon/mirror/:coupon',
          name: 'WriteMyWillCoupon',
          component: () => import('@/views/pages/WriteMyWill/index.vue')
        },
        {
          path: '/write_my_will/name',
          name: 'WriteMyWillName',
          component: ProfileName
        },
        {
          path: '/write_my_will/gender',
          name: 'WriteMyWillGender',
          component: ProfileGender
        },
        {
          path: '/write_my_will/children',
          name: 'WriteMyWillChildren',
          component: ProfileChildren
        },
        {
          path: '/write_my_will/pets',
          name: 'WriteMyWillPets',
          component: ProfilePets
        },
        {
          path: '/write_my_will/dob',
          name: 'WriteMyWillDob',
          component: ProfileDob
        },
        {
          path: '/write_my_will/address',
          name: 'WriteMyWillAddress',
          component: ProfileAddress
        },
        {
          path: '/write_my_will/residential_status',
          name: 'WriteMyWillResidentialStatus',
          component: ProfileResidentialStatus
        },
        {
          path: '/write_my_will/marital_status',
          name: 'WriteMyWillMaritalStatus',
          component: ProfileMaritalStatus
        },
        {
          path: '/write_my_will/partner_name',
          name: 'WriteMyWillPartnerName',
          component: ProfilePartnerName
        },
        {
          path: '/write_my_will/partner_gender',
          name: 'WriteMyWillPartnerGender',
          component: ProfilePartnerGender
        },
        {
          path: '/write_my_will/will_for',
          name: 'WriteMyWillWillFor',
          component: ProfileWillFor
        },
        {
          path: '/write_my_will/charity_option',
          name: 'WriteMyWillCharityOption',
          component: ProfileCharityOption
        },
        {
          path: '/write_my_will/charity_amount',
          name: 'WriteMyWillCharityAmount',
          component: ProfileCharityAmount
        },
        {
          path: '/write_my_will/charity_percentage',
          name: 'WriteMyWillCharityPercentage',
          component: ProfileCharityPercentage
        },
        {
          path: '/write_my_will/username',
          name: 'WriteMyWillUsername',
          component: ProfileUsername
        },
        {
          path: '/write_my_will/password',
          name: 'WriteMyWillPassword',
          component: ProfilePassword
        },
        {
          path: '/write_my_will/check_and_confirm',
          name: 'WriteMyWillCheckAndConfirm',
          component: ProfileCheckAndConfirm
        }
      ]
    },
    {
      path: '',
      component: () => import('@/common/layout/FullPage.vue'),
      children: [
        {
          path: '/role/:id',
          name: 'Role',
          component: () => import('@/views/pages/ShareReferral/index.vue')
        },
        {
          // facebook social login redirect fix
          path: '/_=_',
          redirect: '/login'
        },
        {
          path: '/login/facebook',
          name: 'LoginRegister',
          component: () => import('@/views/pages/Login/index.vue')
        },
        {
          path: '/login/google',
          name: 'LoginRegister',
          component: () => import('@/views/pages/Login/index.vue')
        },
        {
          path: '/login/:access/:refresh',
          name: 'LoginRegister',
          component: () => import('@/views/pages/Login/index.vue')
        },
        {
          path: '/login',
          name: 'LoginRegister',
          component: () => import('@/views/pages/Login/index.vue')
        },
        {
          path: '/broker_create_user',
          name: 'BrokerCreateUser',
          component: () => import('@/views/pages/Login/BrokerCreateUser.vue')
        },
        {
          path: '/account_handover/:code',
          name: 'AccountHandoverPasswordSet',
          component: () =>
            import('@/views/pages/Login/AccountHandoverPasswordSet.vue')
        },
        {
          path: '/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue')
        },
        {
          path: '*',
          redirect: '/error-404'
        }
      ]
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
