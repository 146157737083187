const state = {
  // authentication

  isAuthenticated: false,
  loginError: null,
  tokens: {
    access: localStorage.getItem('accessToken'),
    refresh: localStorage.getItem('refreshToken')
  },
  endpoints: {
    baseUrl:
      process.env.NODE_ENV === 'production'
        ? 'https://www.mylastwill.co.uk/'
        : 'http://127.0.0.1:8000/',
    obtainJWT:
      process.env.NODE_ENV === 'production'
        ? 'https://www.mylastwill.co.uk/wills/api/token/'
        : 'http://127.0.0.1:8000/wills/api/token/',
    refreshJWT:
      process.env.NODE_ENV === 'production'
        ? 'https://www.mylastwill.co.uk/wills/api/token/refresh/'
        : 'http://127.0.0.1:8000/wills/api/token/refresh/'
  },

  // other
  user: '',
  sidebar: 'full',
  sidebarHover: false,
  sidebarMobile: false,
  userDetails: {
    progress: []
  },
  willPreview: null,
  checkoutBasket: {
    will_id: null,
    advice_id: null,
    printing: true,
    package: true,
    single_price: '',
    joint_price: '',
    coupon: '',
    will_for: '',
    total_price: '',
    charge_now_price: '',
    go_card_less_redirect_id: '',
    active_subscription: '',
    account_holder_name: '',
    account_number_ending: ''
  },
  checkoutProgress: {
    createWill: false,
    package: false,
    printing: false,
    checking: false,
    pay: false,
    share: false,
    advice: false,
    draft: false
  },
  prices: [],
  communication: null,
  documents: [],
  sharePeople: [],
  advice: [],
  adviceNew: [],
  people: [],
  groups: [],
  charities: [],
  personalDetails: {
    id: null,
    terms_and_conditions: null,
    will_for: null,
    full_name: null,
    sex: null,
    dob_day: null,
    dob_month: null,
    dob_year: null,
    charity_donate: null,
    charity_amount: null,
    charity_percentage: null,
    address_line_1: null,
    address_line_2: null,
    post_town: null,
    post_code: null,
    country: null,
    moving: false,
    domiciled_in_country: null,
    domiciled_advice_accepted: null,
    marital_status: null,
    marital_status_single_divorced: null,
    marital_status_single_widowed: null,
    partner_details: null,
    partner_full_name: null,
    partner_gender: null,
    partner_email: null,
    partner_phone: null,
    partner: null,
    children: null,
    pets: null,
    phone: null,
    contact_email: null,
    username: null,
    usernameError: null
  },
  details: {
    id: null,
    distribution_minor_18: null,
    distribution_minor_21: null,
    distribution_minor_25: null,
    distribution_all_die_dont_care: null,
    distribution_all_die_backup_table: null,
    distribution_disabled: null,
    distribution_disabled_details: null,
    gifts: null,
    gifts_before: null,
    gifts_after: null,
    pets_yes_no: null,
    amount: null,
    same_as_executors: null,
    trustees_secondary_required: null,
    trustees_backup_required: null,
    executor_professional: null,
    executor_professional_and_family: null,
    consent_to_alternative_professional_executors: null,
    executors_secondary_required: null,
    guardians_backup_required: null,
    guardians_secondary_required: null,
    executors_backup_required: null,
    discretionary_trust: null,
    last_saved: null
  },
  funeral: {
    id: null,
    funeral_type_celebration: null,
    funeral_type_simple: null,
    funeral_type_green: null,
    funeral_type_grand: null,
    funeral_type_non_religious: null,
    funeral_type_no_preference: null,
    funeral_finish: null,
    funeral_wishes_required: null,
    funeral_wishes: null,
    funeral_organs: null,
    funeral_plan: null,
    funeral_plan_provider: null
  },
  giftCash: [],
  giftOther: [],
  giftRealEstate: [],
  giftVehicle: [],
  giftCharity: [],
  assets: {
    residential_property: null,
    residential_property_amount: null,
    residential_property_joint: null,
    residential_property_owner: null,
    new_residential_property: null,
    new_residential_property_amount: null,
    new_residential_property_joint: null,
    new_residential_property_owner: null,
    blt_property: null,
    blt_property_amount: null,
    blt_property_joint: null,
    savings_bank: null,
    savings_bank_amount: null,
    savings_bank_joint: null,
    life_insurance: null,
    life_insurance_amount: null,
    life_insurance_joint: null,
    vehicle: null,
    vehicle_amount: null,
    pension: null,
    pension_amount: null,
    shares_investments: null,
    shares_investments_amount: null,
    shares_investments_joint: null,
    endowment: null,
    endowment_amount: null,
    endowment_joint: null,
    business: null,
    business_amount: null,
    household: null,
    household_amount: null,
    gold_jewellery: null,
    gold_jewellery_amount: null,
    trust: null,
    residential_mortgage: null,
    residential_mortgage_amount: null,
    residential_mortgage_joint: null,
    new_residential_mortgage: null,
    new_residential_mortgage_amount: null,
    new_residential_mortgage_joint: null,
    blt_mortgage: null,
    blt_mortgage_amount: null,
    blt_mortgage_joint: null,
    credit_cards: null,
    credit_cards_amount: null,
    loans: null,
    loans_amount: null,
    assets_outside_uk: null,
    known_by_other_name: null,
    other_name: null,
    business_type_required: null,
    business_type: null,
    shareholders: null
  },
  showIntroModals: {
    executors: true,
    trustees: true,
    guardians: true,
    distribution: true,
    gifts: true,
    children: true,
    funeral: true,
    assets: true,
    handover: true
  },
  bankAccountSetup: null,
  directDebitForm: null,
  directDebitMandate: null,
  // handover
  handoverUser: null,
  handoverPartner: null,
  handoverBrokerContactEmail: null,
  // partner mirror will details - if partner uses same code
  partnerCheckoutBasket: null,
  partnerPersonalDetails: null
}
export default state
