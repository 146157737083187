<template>
  <Page>
    <template v-slot:contents>
      <div class="check-and-finish mt60">
        <div class="row">
          <div
            class="col-lg-5 col-lg-offset-1 col-md-5 col-md-offset-1 col-sm-12 col-xs-12"
          >
            <div class="check-and-finish-content">
              <div class="heading">
                <h4 class="h1 heading-title">Check & Confirm</h4>
                <div class="heading-line">
                  <span class="short-line"></span>
                  <span class="long-line"></span>
                </div>
                <div class="pb60" v-html="text"></div>
                <NavQuestionButtons
                  v-bind:validated="true"
                  v-bind:forwardText="'Confirm'"
                  v-bind:forwardLink="'/home'"
                  v-bind:backLink="backTo"
                  v-bind:checkConfirm="true"
                ></NavQuestionButtons>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="check-and-finish-thumb">
              <img
                src="https://mylastwill.s3.amazonaws.com/static/img/magnify_executors.png"
                alt="magnify"
              />
            </div>
          </div>
        </div>
        <div class="check-and-finish-border"></div>
      </div>
    </template>
  </Page>
</template>

<script>
import Page from '@/common/ui/Page'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'

export default {
  name: 'NavCheckAndConfirm',
  components: {
    Page,
    NavQuestionButtons
  },
  props: ['text', 'backTo']
}
</script>

<style>
.mt80 {
  margin-top: 80px;
}
.pb60 span {
  color: #2f2c2c;
  font-weight: 600;
}
</style>
