<template>
  <BaseModalIntro
    v-if="showValue"
    :introTitle="'Assets & Liabilities'"
    :showValue="showValue"
    :show="show"
    @close="$emit('close')"
    @doNotShowAgain="doNotShowAgain"
  >
    <template v-slot:contents>
      <h5>Overview</h5>
      <p>
        Whilst a Will simply deals with everything that you own, identifying the
        assets (and liabilities) that make up your estate helps us to create
        your Will. If for example your estate contains a business or assets held
        in other names, your Will may need to be worded slightly differently. In
        addition, having an overview of your assets can help executors deal with
        everything when your time comes.
      </p>

      <h5>Valuations</h5>
      <p>
        The valuations in this section do not need to be exact, please just
        provide an estimate or 'best guess'. Things change over the years, the
        value of assets will likely increase and the type of assets owned may
        change, this section is simply a 'snap-shot' of your estate as it is
        today.
      </p>

      <h5>Inheritance Tax</h5>
      <p>
        You should understand that the system is not designed to provide any
        analysis or advice around inheritance tax (IHT). If you think that IHT
        may be applicable to your estate you should take advice from a suitably
        qualified professional. In general a single persons estate will not be
        taxed where the value of total assets, less total liabilities is under
        £325,000
      </p>
    </template>
  </BaseModalIntro>
</template>

<script>
import BaseModalIntro from '@/common/ui/BaseModalIntro'

export default {
  name: 'AssetsIntroModal',
  props: ['show'],
  components: {
    BaseModalIntro
  },
  computed: {
    showValue() {
      return this.$store.getters.showIntroModals.assets
    }
  },
  methods: {
    doNotShowAgain() {
      this.$store.commit('showIntroModals', { assets: false })
    }
  }
}
</script>

<style></style>
