export const willStringHelpers = {
  methods: {
    span(value) {
      return '<span>' + value + '</span>'
    },
    singleJoint() {
      if (this.personalDetails.will_for === 'Myself') return 'yourself'
      else return 'yourself and your partner'
    },
    gender() {
      return String(this.personalDetails.sex).toLowerCase()
    },
    partnerTitle(maritalStatus) {
      if (maritalStatus === 'Separated' && maritalStatus !== 'Single')
        return 'ex-partners'
      else if (maritalStatus !== 'Separated' && maritalStatus !== 'Single')
        return 'partners'
      return ''
    },
    childCount(children) {
      if (children.length === 1) return children.length + ' child'
      else return children.length + ' children'
    },
    listPeople(people, andOr = ' and ', firstWord = false, removeSpan = false) {
      var string = ''
      for (var i = 0; i < people.length; i++) {
        if (i !== 0 && i !== people.length - 1) string += ', '
        if (i === people.length - 1 && i !== 0) string += andOr
        if (firstWord) string += this.span(this.firstWord(people[i].full_name))
        else string += this.span(people[i].full_name)
      }
      if (removeSpan) {
        string = string.replace(/<span>/g, '')
        string = string.replace(/<\/span>/g, '')
      }
      return string
    },
    listGroups(groups, andOr = ' and ', firstWord = false) {
      var string = ''
      for (var i = 0; i < groups.length; i++) {
        if (i !== 0 && i !== groups.length - 1) string += ', '
        if (i === groups.length - 1 && i !== 0) string += andOr
        if (firstWord) string += this.span(this.firstWord(groups[i].group_name))
        else string += this.span(groups[i].group_name)
      }
      return string
    },
    listPeopleGroupsCharities(
      people = null,
      groups = null,
      charities = null,
      andOr = ' and ',
      firstWord = false,
      combinedInitial = null,
      removeSpan = false
    ) {
      var combined
      if (combinedInitial) combined = combinedInitial
      else combined = [...people, ...groups, ...charities]

      var string = ''
      for (var i = 0; i < combined.length; i++) {
        if (i !== 0 && i !== combined.length - 1) string += ', '
        if (i === combined.length - 1 && i !== 0) string += andOr

        if (typeof combined[i].full_name !== 'undefined') {
          if (firstWord)
            string += this.span(this.firstWord(combined[i].full_name))
          else string += this.span(combined[i].full_name)
        }
        if (typeof combined[i].group_name !== 'undefined') {
          /*
          if (firstWord)
            string +=
              'your ' + this.span(this.firstWord(combined[i].group_name))
          else string += 'your ' + this.span(combined[i].group_name)
           */
          string += 'your ' + this.span(combined[i].group_name)
        }
        if (typeof combined[i].charity_name !== 'undefined') {
          if (firstWord)
            string += this.span(this.firstWord(combined[i].charity_name))
          else string += this.span(combined[i].charity_name)
        }
      }
      if (removeSpan) {
        string = string.replace(/<span>/g, '')
        string = string.replace(/<\/span>/g, '')
      }
      return string
    },
    listPartnerPeopleGroupsCharities(
      partner = null,
      people = null,
      groups = null,
      charities = null,
      andOr = ' and ',
      firstWord = false
    ) {
      var partnerList = []
      if (partner) {
        partnerList.push(partner)
      }
      var peopleResults = [...partnerList, ...people, ...groups, ...charities]
      return this.listPeopleGroupsCharities(
        null,
        null,
        null,
        andOr,
        firstWord,
        peopleResults
      )
    },
    firstWord(string, plural = false, span = true) {
      var name = String(string).split(' ')[0]
      if (plural) name += "'s"
      if (span) return this.span(name)
      return name
    },
    heShe(string, capitalise = false) {
      if (capitalise) return string === 'Male' ? 'He' : 'She'
      return string === 'Male' ? 'he' : 'she'
    },
    hisHer(string, capitalise = false) {
      if (capitalise) return string === 'Male' ? 'His' : 'Her'
      return string === 'Male' ? 'his' : 'her'
    },
    himHer(string, capitalise = false) {
      if (capitalise) return string === 'Male' ? 'Him' : 'Her'
      return string === 'Male' ? 'him' : 'her'
    },
    relationYour(string) {
      return this.span(string === 'Other' ? '' : ' your ' + string)
    },
    currency(value) {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'GBP'
      })

      return formatter.format(value)
    },
    listAssetsLiabilities(value) {
      var string = ''
      if (value.residential_mortgage) string += ', mortgage'
      if (value.business) string += ', business'
      if (value.credit_cards) string += ', credit cards'
      if (value.blt_mortgage || value.blt_property) string += ', btl property'
      return string
    },
    homeAddress(value) {
      var string = ''
      if (value.address_line_1) string += value.address_line_1 + ', '
      if (value.address_line_2) string += value.address_line_2 + ', '
      if (value.post_code) string += value.post_code
      return string
    },
    childsOrChildrens(value) {
      if (value.length === 1) return "child's"
      return "children's"
    },
    childOtherParent(value) {
      if (value === 'Male') return ['mother', 'her']
      return ['father', 'him']
    },
    childParent(value) {
      if (value === 'Male') return ['father', 'him']
      return ['mother', 'her']
    },
    stringToNumber(value) {
      return Number(value.replace(/,/g, ''))
    }
  }
}
