import axios from 'axios'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import { http } from '@/services'
import router from '@/router/index'

const actions = {
  // authentication

  setHttpHeaders({ getters }) {
    const baseHTTP = {
      baseUrl: getters.endpoints.baseUrl,
      headers: {
        Authorization: `JWT ${getters.tokens.access}`,
        'Content-Type': 'application/json'
      }
    }
    http.defaults.headers = baseHTTP.headers
  },
  logout({ commit }, noReRoute) {
    commit('removeTokens')
    commit('clearInitialise')
    if (!noReRoute) {
      // window.location.href = 'http://127.0.0.1:8000/logged_out'
      router.push('/login').catch((error) => {
        console.log(error)
      })
    }
  },
  async _setTokens({ commit, dispatch }, data) {
    commit('loginError', null)
    commit('accessToken', data.access)
    commit('refreshToken', data.refresh)
    dispatch('setHttpHeaders')
    if (!data.noRedirect) {
      router.push('/home').catch((error) => {
        console.log(error)
      })
    }
    return Promise.resolve(true)
  },
  async obtainToken({ commit, getters, dispatch }, data) {
    const payload = {
      email: data.email,
      password: data.password
    }
    axios.defaults.withCredentials = true

    return axios
      .post(getters.endpoints.obtainJWT, payload)
      .then((response) => {
        if (data.noRedirect) {
          response.data.noRedirect = true
          dispatch('_setTokens', response.data)
        } else {
          dispatch('_setTokens', response.data)
        }
      })
      .catch((error) => {
        console.log(error.response)
        if (
          error.response.data &&
          error.response.data.dev_code &&
          error.response.data.dev_code === 'change_password_handover' &&
          error.response.data.user_hash
        ) {
          router
            .push(`/account_handover/${error.response.data.user_hash}`)
            .catch((error) => {
              console.log(error)
            })
        } else if (
          error.response.data &&
          error.response.data.dev_code &&
          error.response.data.dev_code === 'user_is_broker'
        ) {
          if (process.env.NODE_ENV === 'production') {
            var base_url = 'https://broker.mylastwill.co.uk/login'
            if (error.response.data.tokens) {
              window.location.href =
                base_url +
                '/' +
                error.response.data.tokens.access +
                '/' +
                error.response.data.tokens.refresh
            } else {
              window.location.href = base_url
            }
          } else {
            var base_url_dev = 'http://127.0.0.1:8080/login'
            if (error.response.data.tokens) {
              window.location.href =
                base_url_dev +
                '/' +
                error.response.data.tokens.access +
                '/' +
                error.response.data.tokens.refresh
            } else {
              window.location.href = base_url_dev
            }
            window.location.href = base_url_dev
          }
        } else {
          commit('loginError', error.response.data)
          throw error
        }
      })
  },
  async refreshToken({ commit, getters, dispatch }) {
    const decoded = jwt_decode(getters.tokens.refresh)
    const exp = decoded.exp
    const now = Date.now() / 1000

    if (!getters.tokens.refresh || exp - now <= 0) {
      dispatch('logout')
      return
    }

    const payload = {
      refresh: getters.tokens.refresh
    }

    return axios
      .post(getters.endpoints.refreshJWT, payload)
      .then((response) => {
        commit('accessToken', response.data.access)
        dispatch('setHttpHeaders')
        try {
          dispatch('userFetch')
          router.push('/home').catch((error) => {
            console.log(error)
          })
        } catch (error) {
          throw error
        }
        return Promise.resolve(true)
      })
      .catch((error) => {
        return Promise.resolve(false)
      })
  },
  async inspectToken({ dispatch, getters, commit }) {
    const token = getters.tokens.access

    if (token) {
      if (
        http.defaults &&
        http.defaults.headers.Authorization !== `JWT ${token}`
      ) {
        dispatch('setHttpHeaders')
      }

      const decoded = jwt_decode(token)
      const exp = decoded.exp
      const now = Date.now() / 1000

      if (exp - now < 300) {
        // less than 5 minutes to go 300
        let response = dispatch('refreshToken')
        return response
      } else if (exp - now < 86400) {
        // 1 day
        // DO NOTHING, DO NOT REFRESH
        if (!getters.user) {
          try {
            dispatch('userFetch')
            // if (router.currentRoute && router.currentRoute.name !== 'Overview') router.push('/home').catch(error => { console.log(error) })
          } catch (error) {
            throw error
          }
        } else {
          if (router.currentRoute && router.currentRoute.name !== 'Overview')
            router.push('/home').catch((error) => {
              console.log(error)
            })
        }
      } else {
        dispatch('logout')
      }
    } else {
      dispatch('logout')
    }
    return Promise.resolve(true)
  },

  // other
  initialise({ dispatch }) {
    dispatch('personalDetailsFetch')
    dispatch('checkoutBasketFetch')
    dispatch('pricesFetch')
    dispatch('existingMandateFetch')
    dispatch('peopleFetch')
    dispatch('groupsFetch')
    dispatch('charitiesFetch')
    dispatch('adviceNewFetch')
    dispatch('detailsFetch')
    dispatch('funeralFetch')
    dispatch('giftOtherFetch')
    dispatch('giftCharityFetch')
    dispatch('giftVehicleFetch')
    dispatch('giftCashFetch')
    dispatch('giftRealEstateFetch')
    dispatch('assetsFetch')
    dispatch('documentsFetch')
  },
  async userFetch({ commit, dispatch }) {
    const userFetch = http
      .get('/wills/api/user')
      .then((response) => {
        // commit('user', response.data)
        /*
        if (response.data.change_password && !response.data.temp_account) {
          console.log('TODO - logout user to change password')
          dispatch('logout')
        }
         */
        commit('user', {
          authUser: response.data,
          isAuthenticated: true
        })
        dispatch('initialise')
      })
      .catch((error) => {
        console.log(error, 'error fetching user')
      })
    return userFetch
  },
  progressFetch({ commit }) {
    http
      .get('/wills/api/progress')
      .then((response) => {
        commit('progress', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  communicationFetch({ commit }) {
    http
      .get('/wills/api/communication')
      .then((response) => {
        commit('communication', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  documentsFetch({ commit, getters }) {
    http
      .get('/wills/api/documents')
      .then((response) => {
        commit('documents', response.data)
        var latestWill = null
        var wills = getters.documents.filter((doc) => doc.paid && doc.checked)
        if (wills.length)
          latestWill = wills.sort(
            (a, b) => new Date(b.uploaded_at) - new Date(a.uploaded_at)
          )
        else latestWill = null
        if (latestWill && router.history.current.path === '/home')
          router
            .push('/documents/final_will/' + latestWill[0].id)
            .catch((error) => {
              console.log(error)
            })
      })
      .catch((error) => {
        console.log(error)
      })
  },
  peopleFetch({ commit }) {
    http
      .get('/wills/api/people')
      .then((response) => {
        commit('people', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  groupsFetch({ commit }) {
    http
      .get('/wills/api/groups')
      .then((response) => {
        commit('groups', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  charitiesFetch({ commit }) {
    http
      .get('/wills/api/charities')
      .then((response) => {
        commit('charities', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  detailsFetch({ commit }) {
    http
      .get('/wills/api/details')
      .then((response) => {
        commit('details', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  personalDetailsFetch({ commit }) {
    http
      .get('/wills/api/personal_details')
      .then((response) => {
        commit('personalDetails', response.data)
        if (!response.data.full_name || !response.data.dob_day)
          router.push('/profile').catch((error) => {
            console.log(error)
          })
      })
      .catch((error) => {
        console.log(error)
      })
  },
  willPreviewFetch() {
    http
      .get('/wills/api/preview_will')
      .then((response) => {
        this.commit('willPreview', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  checkoutBasketFetch() {
    http
      .get('/wills/api/checkout_basket')
      .then((response) => {
        this.commit('checkoutBasket', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  checkoutBasketSave({ commit }, data) {
    commit('checkoutBasket', data)
  },
  pricesFetch({ commit }) {
    http
      .get('/wills/api/prices')
      .then((response) => {
        commit('prices', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  adviceWillIdFetch({ commit }) {
    http
      .get('/wills/api/will_advice_id')
      .then((response) => {
        commit('adviceWillId', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  existingMandateFetch({ commit }) {
    http
      .get('/wills/api/existing_mandate_new')
      .then((response) => {
        commit('existingMandate', response.data.existing_mandate)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  adviceFetch({ commit }) {
    http
      .get('/wills/api/advice')
      .then((response) => {
        commit('advice', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  adviceNewFetch({ commit }) {
    http
      .get('/wills/api/advice_new')
      .then((response) => {
        commit('adviceNew', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  funeralFetch({ commit }) {
    http
      .get('/wills/api/funeral')
      .then((response) => {
        commit('funeral', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  giftOtherFetch({ commit }) {
    http
      .get('/wills/api/gift_other')
      .then((response) => {
        commit('giftOther', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  giftCharityFetch({ commit }) {
    http
      .get('/wills/api/gift_charity')
      .then((response) => {
        commit('giftCharity', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  giftCashFetch({ commit }) {
    http
      .get('/wills/api/gift_cash')
      .then((response) => {
        commit('giftCash', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  giftVehicleFetch({ commit }) {
    http
      .get('/wills/api/gift_vehicle')
      .then((response) => {
        commit('giftVehicle', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  giftRealEstateFetch({ commit }) {
    http
      .get('/wills/api/gift_real_estate')
      .then((response) => {
        commit('giftRealEstate', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  giftBeforeClear({ commit, getters }) {
    http
      .delete('/wills/api/gift_clear_all_before')
      .then((response) => {
        getters.giftCash
          .filter((gift) => gift.gift_before_partner)
          .forEach((gift) => {
            commit('giftCashDelete', gift.id)
          })
        getters.giftOther
          .filter((gift) => gift.gift_before_partner)
          .forEach((gift) => {
            commit('giftOtherDelete', gift.id)
          })
        getters.giftCharity
          .filter((gift) => gift.gift_before_partner)
          .forEach((gift) => {
            commit('giftCharityDelete', gift.id)
          })
        getters.giftRealEstate
          .filter((gift) => gift.gift_before_partner)
          .forEach((gift) => {
            commit('giftRealEstateDelete', gift.id)
          })
        getters.giftVehicle
          .filter((gift) => gift.gift_before_partner)
          .forEach((gift) => {
            commit('giftVehicleDelete', gift.id)
          })
      })
      .catch((error) => {
        console.log(error)
      })
  },
  giftAfterClear({ commit, getters }) {
    http
      .delete('/wills/api/gift_clear_all_after')
      .then((response) => {
        getters.giftCash
          .filter((gift) => gift.gift_after_partner)
          .forEach((gift) => {
            commit('giftCashDelete', gift.id)
          })
        getters.giftOther
          .filter((gift) => gift.gift_after_partner)
          .forEach((gift) => {
            commit('giftOtherDelete', gift.id)
          })
        getters.giftCharity
          .filter((gift) => gift.gift_after_partner)
          .forEach((gift) => {
            commit('giftCharityDelete', gift.id)
          })
        getters.giftRealEstate
          .filter((gift) => gift.gift_after_partner)
          .forEach((gift) => {
            commit('giftRealEstateDelete', gift.id)
          })
        getters.giftVehicle
          .filter((gift) => gift.gift_after_partner)
          .forEach((gift) => {
            commit('giftVehicleDelete', gift.id)
          })
      })
      .catch((error) => {
        console.log(error)
      })
  },
  assetsFetch({ commit }) {
    http
      .get('/wills/api/assets')
      .then((response) => {
        commit('assets', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  saveAssets({ commit, getters }) {
    http
      .patch('/wills/api/assets', getters.assets)
      .then((response) => {
        commit('assets', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  savePersonalDetails({ commit, getters }) {
    http
      .patch('/wills/api/personal_details', getters.personalDetails)
      .then((response) => {
        commit('personalDetails', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  saveDetails({ commit, getters }) {
    http
      .patch('/wills/api/details', getters.details)
      .then((response) => {
        commit('details', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  saveFuneral({ commit, getters }) {
    http
      .patch('/wills/api/funeral', getters.funeral)
      .then((response) => {
        commit('funeral', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  savePeople({ commit, getters }) {
    http
      .patch('/wills/api/people', getters.people)
      .then((response) => {
        commit('people', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  updatePerson({ commit }, person) {
    http
      .patch('/wills/api/person', person)
      .then((response) => {
        commit('personAdd', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  deletePersonFromServer({ commit, getters }, data) {
    http
      .delete('/wills/api/person', { params: { person_pk: data } })
      .then((response) => {
        commit('personDelete', data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  updateGroup({ commit }, group) {
    http
      .patch('/wills/api/group', group)
      .then((response) => {
        commit('groupAdd', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  deleteGroupFromServer({ commit, getters }, data) {
    http
      .delete('/wills/api/group', { params: { group_pk: data } })
      .then((response) => {
        commit('groupDelete', data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  sharePeopleSetYes({ commit, getters }) {
    for (var i = 0; i < getters.sharePeople.length; i++) {
      var person = Object.assign({}, getters.sharePeople[i])
      person.share = true
      commit('personAdd', person)
    }
  },
  saveGroups({ commit, getters }) {
    http
      .patch('/wills/api/groups', getters.groups)
      .then((response) => {
        commit('groups', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  saveCharities({ commit, getters }) {
    http
      .patch('/wills/api/charities', getters.charities)
      .then((response) => {
        commit('charities', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  deleteCharityFromServer({ commit, getters }, data) {
    http
      .delete('/wills/api/charity', { params: { charity_pk: data } })
      .then((response) => {
        commit('charityDelete', data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  deleteCharityGiftFromServer({ commit, getters }, data) {
    http
      .delete('/wills/api/gift_charity', { params: { id: data } })
      .then((response) => {
        commit('giftCharityDelete')
      })
      .catch((error) => console.log(error))
  },
  updateProgress({ getters }) {
    var data = {
      percentage_complete: getters.progressSummary.percentageComplete,
      sections_incomplete: getters.progressSummary.sectionsIncomplete,
      sections_complete: getters.progressSummary.sectionsComplete,
      total_sections_required: getters.progressSummary.totalRequiredSections
    }
    http
      .post('/wills/api/progress', data)
      .then()
      .catch((error) => {
        console.log(error)
      })
  },

  saveAll({ dispatch }) {
    dispatch('saveCharities')
    dispatch('saveGroups')
    dispatch('savePeople')
    dispatch('saveAssets')
    dispatch('saveDetails')
    dispatch('saveFuneral')
    dispatch('savePersonalDetails')
  }
}

export default actions
