<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
    :background="'bg-personal-details'"
  >
    <template v-slot:contents>
      <div class="app-table fadeIn-1">
        <table class="assets-liabilities-table">
          <thead class="gifts-wrap-title-main">
            <tr>
              <th class="beneficiary-name">Asset/Liability</th>
              <th class="beneficiary-percentage">Single/Joint Ownership</th>
            </tr>
          </thead>
          <tbody>
            <OwnerTableRow
              v-if="assets.residential_property"
              :initial="assets.residential_property_owner"
              :title="moving ? 'Current Property' : 'Residential Property'"
              @ownerSet="saveOwner('residential_property_owner', $event)"
            ></OwnerTableRow>
            <OwnerTableRow
              v-if="assets.new_residential_property"
              :initial="assets.new_residential_property_owner"
              :title="'New Residential Property'"
              @ownerSet="saveOwner('new_residential_property_owner', $event)"
            ></OwnerTableRow>

            <JointTableRow
              v-if="assets.blt_property"
              :text="'Buy-to-Let Property(s)'"
              :imageUrl="'https://mylastwill.s3.amazonaws.com/static/img/button-asset-btl.png'"
              :initial="assets.blt_property_joint"
              :property="{
                blt_property_joint: assets.blt_property_joint
              }"
              @change="save"
            ></JointTableRow>
            <JointTableRow
              v-if="assets.savings_bank"
              :text="'Savings/Bank'"
              :imageUrl="'https://mylastwill.s3.amazonaws.com/static/img/button-asset-savings.png'"
              :initial="assets.savings_bank_joint"
              :property="{
                savings_bank_joint: assets.savings_bank_joint
              }"
              @change="save"
            ></JointTableRow>
            <JointTableRow
              v-if="assets.life_insurance"
              :text="'Life Insurance Policies'"
              :imageUrl="'https://mylastwill.s3.amazonaws.com/static/img/button-asset-insurance.png'"
              :initial="assets.life_insurance_joint"
              :property="{
                life_insurance_joint: assets.life_insurance_joint
              }"
              @change="save"
            ></JointTableRow>
            <JointTableRow
              v-if="assets.shares_investments"
              :text="'Shares and Investment'"
              :imageUrl="'https://mylastwill.s3.amazonaws.com/static/img/button-asset-investments.png'"
              :initial="assets.shares_investments_joint"
              :property="{
                shares_investments_joint: assets.shares_investments_joint
              }"
              @change="save"
            ></JointTableRow>
            <JointTableRow
              v-if="assets.endowment"
              :text="'Endowment(s)'"
              :imageUrl="'https://mylastwill.s3.amazonaws.com/static/img/button-asset-endowment.png'"
              :initial="assets.endowment_joint"
              :property="{
                endowment_joint: assets.endowment_joint
              }"
              @change="save"
            ></JointTableRow>
            <JointTableRow
              v-if="assets.residential_mortgage"
              :text="moving ? 'Current Mortgage' : 'Residential Mortgage'"
              :imageUrl="'https://mylastwill.s3.amazonaws.com/static/img/button-liability-mortgage.png'"
              :initial="assets.residential_mortgage_joint"
              :property="{
                residential_mortgage_joint: assets.residential_mortgage_joint
              }"
              :liability="true"
              @change="save"
            ></JointTableRow>
            <JointTableRow
              v-if="assets.new_residential_mortgage"
              :text="'New Residential Mortgage'"
              :imageUrl="'https://mylastwill.s3.amazonaws.com/static/img/button-liability-mortgage.png'"
              :initial="assets.new_residential_mortgage_joint"
              :property="{
                new_residential_mortgage_joint:
                  assets.new_residential_mortgage_joint
              }"
              :liability="true"
              @change="save"
            ></JointTableRow>

            <JointTableRow
              v-if="assets.blt_mortgage"
              :text="'Buy-to-Let Mortgage(s)'"
              :imageUrl="'https://mylastwill.s3.amazonaws.com/static/img/button-liability-btlmortgage.png'"
              :initial="assets.blt_mortgage_joint"
              :property="{blt_mortgage_joint: assets.blt_mortgage_joint}"
              :liability="true"
              @change="save"
            ></JointTableRow>

          </tbody>
        </table>

      </div>
      <JointWarning
          v-if="show.jointWarning && show.jointWarningCount === 1"
          @close="show.jointWarning = false"
      />
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="backTo"
        v-bind:center="false"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import JointTableRow from './JointTableRow'
import OwnerTableRow from './OwnerTableRow'
import JointWarning from '@/views/pages/AssetsLiabilitiesPage/Questions/modals/JointWarning'

export default {
  name: 'AssetsJoint',
  components: {
    JointWarning,
    NavQuestion,
    NavQuestionButtons,
    JointTableRow,
    OwnerTableRow
  },
  data () {
    return {
      show: {
        jointWarning: false,
        jointWarningCount: 0
      }
    }
  },
  watch: {},
  computed: {
    assets() {
      return this.$store.getters.assets
    },
    number() {
      return '4'
    },
    forwardTo() {
      if (this.$store.getters.assets.residential_property)
        return '/assets/property_occupants'
      return '/assets/outside_england_wales'
    },
    backTo: () => '/assets/amounts',
    heading() {
      return 'Are the assets and liabilities joint or single?'
    },
    partner() {
      return this.$store.getters.partner
    },
    subHeading() {
      if (this.partner) {
        return (
          'If you own assets jointly with ' +
          this.partner.full_name +
          ' or ' +
          'anybody else, please update the table below.  This allows us to better estimate the overall value of your estate.'
        )
      }
      return 'If you own assets jointly, please update the table below.  This allows us to better estimate the overall value of your estate.'
    },
    isValidated() {
      return !(
        this.assets.residential_property &&
        this.assets.residential_property_owner === null
      )
    },
    moving() {
      return this.$store.getters.personalDetails.moving
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('saveAssets')
    next()
  },
  methods: {
    save(value) {
      // show warning modal once
      if (value[Object.keys(value)[0]]) {
        this.show.jointWarning = true
        this.show.jointWarningCount += 1
      }
      // save
      if (this.assets) {
        this.$store.commit('assets', value)
      }
    },
    saveOwner(attribute, value) {
      //show warning modal once
      if (value !== 'myself') {
        this.show.jointWarning = true
        this.show.jointWarningCount += 1
      }
      // save
      if (this.assets) {
        let data = new Object()
        data[attribute] = value
        this.$store.commit('assets', data)
      }
    }
  }
}
</script>

<style></style>
