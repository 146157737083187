<template>
  <div
    v-if="showMessage"
    class="input-alert-new"
    :class="{ left: left }"
    v-on:click="hideMessage"
  >
    <span>{{ message }} <i v-if="displayClose" class="fa fa-times"></i></span>
    <div></div>
  </div>
</template>

<script>
export default {
  name: 'ErrorPop',
  props: ['message', 'displayClose', 'left'],
  watch: {
    message(value) {
      if (value) {
        this.showMessage = true
      } else if (value === null) {
        this.showMessage = false
      }
    }
  },
  data() {
    return {
      showMessage: true,
      messageText: ''
    }
  },
  methods: {
    hideMessage() {
      this.showMessage = false
      this.$emit('clearError')
    }
  }
}
</script>
<style scoped>
.input-alert-new {
  position: relative;
}

.input-alert-new::before,
.input-alert-new span {
  display: block;
  position: absolute;
}

.input-alert-new::before {
  top: -18px;
  left: 35px;
  content: '';
  z-index: 41;
  width: 0;
  height: 0;
  line-height: 0;
  border-bottom: 8px solid #e56781;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: transparent;
  overflow: hidden;
}

.input-alert-new.left::before {
  top: 50%;
  left: 2px;
  border-right: 8px solid #e56781;
  border-bottom: 8px solid transparent;
  border-top: 8px solid transparent;
  border-left: transparent;
}

.input-alert-new span {
  top: -10px;
  left: 10px;
  z-index: 40;
  text-decoration: none;
  padding: 12px 18px;
  color: #fff;
  background-color: #e56781;
  border-radius: 0.2em;
  -webkit-box-shadow: 0 2px 30px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.2);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0.3;
}
</style>
