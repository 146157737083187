var render = function render(){var _vm=this,_c=_vm._self._c;return _c('NavQuestion',{attrs:{"heading":_vm.heading,"number":_vm.number,"subHeading":_vm.subHeading,"questionOptions":true,"background":'bg-personal-details'},scopedSlots:_vm._u([{key:"contents",fn:function(){return [_c('div',{staticClass:"app-table fadeIn-2"},[_c('table',{staticClass:"assets-liabilities-table"},[_c('thead',{staticClass:"gifts-wrap-title-main"},[_c('tr',[_c('th',{staticClass:"beneficiary-name"},[_vm._v("Asset/Liability")]),_c('th',{staticClass:"beneficiary-percentage"},[_vm._v("Total Value")])])]),_c('tbody',[(_vm.assets.residential_property)?_c('AmountTableRow',{attrs:{"text":_vm.moving
                ? 'Current Property<br><span>(Full market value of the property)</span>'
                : 'Residential Property<br><span>(Full market value of the property)</span>',"imageUrl":'https://mylastwill.s3.amazonaws.com/static/img/button-asset-residential.png',"initial":_vm.assets.residential_property_amount,"increment":10000,"property":{
              residential_property_amount: _vm.assets.residential_property_amount
            }},on:{"change":_vm.save,"error":_vm.errorHandler}}):_vm._e(),(_vm.assets.new_residential_property && _vm.moving)?_c('AmountTableRow',{attrs:{"text":'New Residential Property<br><span>(Full market value of the property that you are purchasing)</span>',"imageUrl":'https://mylastwill.s3.amazonaws.com/static/img/button-asset-residential.png',"initial":_vm.assets.new_residential_property_amount,"increment":10000,"property":{
              new_residential_property_amount:
                _vm.assets.new_residential_property_amount
            }},on:{"change":_vm.save,"error":_vm.errorHandler}}):_vm._e(),(_vm.assets.household)?_c('AmountTableRow',{attrs:{"text":'Household contents',"imageUrl":'https://mylastwill.s3.amazonaws.com/static/img/button-asset-houshold.png',"initial":_vm.assets.household_amount,"increment":1000,"property":{
              household_amount: _vm.assets.household_amount
            }},on:{"change":_vm.save,"error":_vm.errorHandler}}):_vm._e(),(_vm.assets.blt_property)?_c('AmountTableRow',{attrs:{"text":'Buy-to-Let Property(s)',"imageUrl":'https://mylastwill.s3.amazonaws.com/static/img/button-asset-btl.png',"initial":_vm.assets.blt_property_amount,"increment":10000,"property":{
              blt_property_amount: _vm.assets.blt_property_amount
            }},on:{"change":_vm.save,"error":_vm.errorHandler}}):_vm._e(),(_vm.assets.savings_bank)?_c('AmountTableRow',{attrs:{"text":'Saving/Bank Account(s)',"imageUrl":'https://mylastwill.s3.amazonaws.com/static/img/button-asset-savings.png',"initial":_vm.assets.savings_bank_amount,"increment":5000,"property":{
              savings_bank_amount: _vm.assets.savings_bank_amount
            }},on:{"change":_vm.save,"error":_vm.errorHandler}}):_vm._e(),(_vm.assets.life_insurance)?_c('AmountTableRow',{attrs:{"text":'Life Insurance Policies <br><span>(Including policies currently being underwritten)</span>',"imageUrl":'https://mylastwill.s3.amazonaws.com/static/img/button-asset-insurance.png',"initial":_vm.assets.life_insurance_amount,"increment":10000,"property":{
              life_insurance_amount: _vm.assets.life_insurance_amount
            }},on:{"change":_vm.save,"error":_vm.errorHandler}}):_vm._e(),(_vm.assets.vehicle)?_c('AmountTableRow',{attrs:{"text":'Vehicle(s)',"imageUrl":'https://mylastwill.s3.amazonaws.com/static/img/button-asset-vehicle.png',"initial":_vm.assets.vehicle_amount,"increment":5000,"property":{
              vehicle_amount: _vm.assets.vehicle_amount
            }},on:{"change":_vm.save,"error":_vm.errorHandler}}):_vm._e(),(_vm.assets.pension)?_c('AmountTableRow',{attrs:{"text":'Pension(s) <br><span>(Total fund value, enter 0 if unknown)</span>',"imageUrl":'https://mylastwill.s3.amazonaws.com/static/img/button-asset-pension.png',"initial":_vm.assets.pension_amount,"increment":10000,"property":{
              pension_amount: _vm.assets.pension_amount
            }},on:{"change":_vm.save,"error":_vm.errorHandler}}):_vm._e(),(_vm.assets.shares_investments)?_c('AmountTableRow',{attrs:{"text":'Shares and Investments',"imageUrl":'https://mylastwill.s3.amazonaws.com/static/img/button-asset-investments.png',"initial":_vm.assets.shares_investments_amount,"increment":10000,"property":{
              shares_investments_amount: _vm.assets.shares_investments_amount
            }},on:{"change":_vm.save,"error":_vm.errorHandler}}):_vm._e(),(_vm.assets.endowment)?_c('AmountTableRow',{attrs:{"text":'Endowment(s)',"imageUrl":'https://mylastwill.s3.amazonaws.com/static/img/button-asset-endowment.png',"initial":_vm.assets.endowment_amount,"increment":10000,"property":{
              endowment_amount: _vm.assets.endowment_amount
            }},on:{"change":_vm.save,"error":_vm.errorHandler}}):_vm._e(),(_vm.assets.business)?_c('AmountTableRow',{attrs:{"text":'Business Assets',"imageUrl":'https://mylastwill.s3.amazonaws.com/static/img/button-asset-business.png',"initial":_vm.assets.business_amount,"increment":10000,"property":{
              business_amount: _vm.assets.business_amount
            }},on:{"change":_vm.save,"error":_vm.errorHandler}}):_vm._e(),(_vm.assets.gold_jewellery)?_c('AmountTableRow',{attrs:{"text":'Gold/Jewellery',"imageUrl":'https://mylastwill.s3.amazonaws.com/static/img/button-asset-goldvaluables.png',"initial":_vm.assets.gold_jewellery_amount,"increment":1000,"property":{
              gold_jewellery_amount: _vm.assets.gold_jewellery_amount
            }},on:{"change":_vm.save,"error":_vm.errorHandler}}):_vm._e(),(_vm.assets.loans)?_c('AmountTableRow',{attrs:{"text":'Bank Loan(s)',"imageUrl":'https://mylastwill.s3.amazonaws.com/static/img/button-liability-loan.png',"initial":_vm.assets.loans_amount,"increment":5000,"property":{
              loans_amount: _vm.assets.loans_amount
            },"liability":true},on:{"change":_vm.save,"error":_vm.errorHandler}}):_vm._e(),(_vm.assets.credit_cards)?_c('AmountTableRow',{attrs:{"text":'Credit Card(s)',"imageUrl":'https://mylastwill.s3.amazonaws.com/static/img/button-liability-creditcard.png',"initial":_vm.assets.credit_cards_amount,"increment":1000,"property":{
              credit_cards_amount: _vm.assets.credit_cards_amount
            },"liability":true},on:{"change":_vm.save,"error":_vm.errorHandler}}):_vm._e(),(_vm.assets.residential_mortgage)?_c('AmountTableRow',{attrs:{"text":_vm.moving ? 'Current Mortgage' : 'Residential Mortgage',"imageUrl":'https://mylastwill.s3.amazonaws.com/static/img/button-liability-mortgage.png',"initial":_vm.assets.residential_mortgage_amount,"increment":10000,"property":{
              residential_mortgage_amount: _vm.assets.residential_mortgage_amount
            },"liability":true},on:{"change":_vm.save,"error":_vm.errorHandler}}):_vm._e(),(_vm.assets.new_residential_mortgage && _vm.moving)?_c('AmountTableRow',{attrs:{"text":'New Residential Mortgage',"imageUrl":'https://mylastwill.s3.amazonaws.com/static/img/button-liability-mortgage.png',"initial":_vm.assets.new_residential_mortgage_amount,"increment":10000,"property":{
              new_residential_mortgage_amount:
                _vm.assets.mew_residential_mortgage_amount
            },"liability":true},on:{"change":_vm.save,"error":_vm.errorHandler}}):_vm._e(),(_vm.assets.blt_mortgage)?_c('AmountTableRow',{attrs:{"text":'Buy-to-Let Mortgage(s)',"imageUrl":'https://mylastwill.s3.amazonaws.com/static/img/button-liability-btlmortgage.png',"initial":_vm.assets.blt_mortgage_amount,"increment":10000,"property":{
              blt_mortgage_amount: _vm.assets.blt_mortgage_amount
            },"liability":true},on:{"change":_vm.save,"error":_vm.errorHandler}}):_vm._e()],1)])])]},proxy:true},{key:"navButtons",fn:function(){return [_c('NavQuestionButtons',{attrs:{"validated":_vm.isValidated,"forwardText":'Next',"forwardLink":_vm.forwardTo,"backLink":_vm.backTo,"center":false}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }