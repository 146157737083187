var moment = require('moment')

export const TextFormat = {
  methods: {
    formatDateAndTime(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY HH:mm')
      }
    },
    formatDateAndTimeAlternative(value) {
      if (value) {
        return moment(String(value)).format('Do MMMM YY - HH:mm A')
      }
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
      }
    },
    formatDateAlternative(value) {
      if (value) {
        return moment(String(value)).format('YYYY-MM-DD')
      }
    },
    formatTime(value) {
      if (value) {
        return moment(String(value)).format('HH:mm A')
      }
    },
    getTime() {
      return moment().format('h:mm')
    },
    truncate(str, length, ending) {
      if (!str) return null
      if (length == null) {
        length = 50
      }
      if (ending == null) {
        ending = '...'
      }
      if (str.length > length) {
        return str.substring(0, length - ending.length) + ending
      } else {
        return str
      }
    },
    formatUser(value) {
      return value.split('@')[0]
    },
    amountPaid(value) {
      var string = ''
      if (value.update) string += 'Update - '
      string += 'Paid £' + value.paid_amount / 100
      return string
    },
    capitalise(value) {
      if (value.length) return value[0].toUpperCase() + value.substr(1)
    },
    span(value) {
      return '<span>' + value + '</span>'
    },
    firstWord(string, plural = false, span = true) {
      var name = String(string).split(' ')[0]
      if (plural) name += "'s"
      if (span) return this.span(name)
      return name
    },
    lastWord(string, plural = false, span = true) {
      var name = String(string).split(' ').pop()
      if (plural) name += "'s"
      if (span) return this.span(name)
      return name
    },
    timePast(startTime) {

      let x = new Date(startTime)
      let now = new Date()

      let timeDiff = Math.abs(now - x)
      timeDiff /= 1000

      let seconds = Math.round(timeDiff)
      timeDiff = Math.floor(timeDiff / 60)

      let minutes = Math.round(timeDiff % 60)
      timeDiff = Math.floor(timeDiff / 60)

      let hours = Math.round(timeDiff % 24)
      timeDiff = Math.floor(timeDiff / 24)

      let days = Math.round(timeDiff % 365)
      timeDiff = Math.floor(timeDiff / 365)

      let years = timeDiff

      if (years > 0) {
        return years + (years > 1 ? ' Years ' : ' Year ') + 'ago'
      } else if (days > 2) {
        return days + (days > 1 ? ' Days ' : ' Day ') + 'ago'
      } else if (hours > 1) {
        return hours + (hours > 1 ? ' Hours ' : ' Hour ') + 'ago'
      } else if (minutes > 1) {
        return minutes + (minutes > 1 ? ' Minutes ' : ' Min ') + 'ago'
      } else if (seconds > 1) {
        return seconds + ' Seconds ago'
      }
      return 'Just Now'
    },
  }
}
