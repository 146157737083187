<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <InputOptionSmall
        :letter="'a'"
        :option="'Yes'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-life-intrust.png'"
        :selected="assets.trust === 'yes'"
        @selected="save('yes')"
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'b'"
        :option="'No'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-life-notintrust.png'"
        :selected="assets.trust === 'no'"
        @selected="save('no')"
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'c'"
        :option="'Unsure'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-life-unsureintrust.png'"
        :selected="assets.trust === 'unsure'"
        @selected="save('unsure')"
      ></InputOptionSmall>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="backTo"
        v-bind:center="false"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputOptionSmall from '@/common/ui/InputOptionSmall'

export default {
  name: 'AssetsTrust',
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputOptionSmall
  },
  computed: {
    assets() {
      return this.$store.getters.assets
    },
    number() {
      var questionNumber = 5
      if (this.assets.residential_property) questionNumber += 1
      if (this.assets.business) questionNumber += 1
      if (
        this.assets.business &&
        this.assets.business_type === 'limited company'
      )
        questionNumber += 1
      if (this.requireJoint) questionNumber += 1
      if (this.requireAmounts) questionNumber += 1
      return questionNumber
    },
    isValidated() {
      if (this.assets.trust) return true
      return false
    },
    requireJoint() {
      if (
        this.assets.blt_property ||
        this.assets.residential_property ||
        this.assets.residential_property ||
        this.assets.blt_property ||
        this.assets.savings_bank ||
        this.assets.life_insurance ||
        this.assets.shares_investments ||
        this.assets.endowment
      )
        return true
      return false
    },
    requireAmounts() {
      if (
        this.assets.credit_cards ||
        this.assets.loans ||
        this.assets.business ||
        this.assets.household ||
        this.assets.vehicle ||
        this.assets.pension ||
        this.assets.gold_jewellery
      )
        return true
      return false
    },
    heading() {
      return 'Are any insurance policies held in trust?'
    },
    subHeading() {
      return (
        "Where a life policy has been organised by a specialist it may have been 'written in trust', this means family or friends would have signed " +
        'paperwork enabling them to act as Trustees in the event of a pay-out. Policies written in trust would no longer become a part of your estate and ' +
        'instead could be transferred directly, tax-free to beneficiaries.'
      )
    },
    forwardTo() {
      return '/assets/check_and_confirm'
    },
    backTo() {
      return '/assets/aka'
    }
  },
  methods: {
    save(value) {
      this.$store.commit('assets', {
        trust: value
      })
    }
  }
}
</script>
