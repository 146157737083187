<template>
  <tr v-bind:class="{ 'liability-row': liability }" class="app-table-row">
    <td class="beneficiary-name">
      <img
        src="https://mylastwill.s3.amazonaws.com/static/img/button-asset-residential.png"
        alt="residential"
      />
      <h5 class="inline-block align-middle">{{ title }}</h5>
    </td>
    <td class="beneficiary-percentage sole-joint">
      <select
        v-model="owner"
        class="input-standard-white validation"
        placeholder="Day"
        required
      >
        <option :value="null" disabled>Select Owner</option>
        <option
          v-for="(option, index) in options"
          :key="index"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>
    </td>
  </tr>
</template>
<script>
export default {
  name: 'JointTableRow',
  props: ['initial', 'liability', 'title'],
  watch: {
    initial: {
      immediate: true,
      handler(value) {
        if (this.owner === null) this.owner = value
      }
    },
    owner(value) {
      this.$emit('ownerSet', this.owner)
    }
  },
  computed: {
    partner() {
      return this.$store.getters.partner
    },
    options() {
      if (this.partner) {
        return [
          { value: 'myself', label: 'Myself' },
          {
            value: 'myself & my partner',
            label: 'Myself & ' + this.partner.full_name
          },
          { value: 'myself & others', label: 'Myself & Others' }
        ]
      } else {
        return [
          { value: 'myself', label: 'Myself' },
          { value: 'myself & others', label: 'Myself & Others' }
        ]
      }
    }
  },
  data() {
    return {
      owner: null
    }
  },
  methods: {}
}
</script>
