<template>
  <div class="modal modalintro is-active" id="modalintro">
    <div
      class="modal-background close-intro"
      id="close-intro"
      v-on:click="close"
    ></div>
    <div class="modal-card" :class="{ Xsmall: global }">
      <header class="intro-card-head">
        <div class="intro-thumb">
          <img
            alt="icon"
            src="https://mylastwill.s3.amazonaws.com/static/img/info-box3.png"
          />
        </div>
        <h2 class="intro-title">{{ introTitle }}</h2>
        <button
          aria-label="Close modal"
          class="delete modal-close close-intro"
          type="button"
        >
          <svg
            style="display: block; height: 26px; width: 26px"
            viewbox="0 0 384 512"
          >
            <path
              d="M217.5 256l137.2-137.2c4.7-4.7 4.7-12.3 0-17l-8.5-8.5c-4.7-4.7-12.3-4.7-17 0L192 230.5 54.8 93.4c-4.7-4.7-12.3-4.7-17 0l-8.5 8.5c-4.7 4.7-4.7 12.3 0 17L166.5 256 29.4 393.2c-4.7 4.7-4.7 12.3 0 17l8.5 8.5c4.7 4.7 12.3 4.7 17 0L192 281.5l137.2 137.2c4.7 4.7 12.3 4.7 17 0l8.5-8.5c4.7-4.7 4.7-12.3 0-17L217.5 256z"
            ></path>
          </svg>
        </button>
      </header>
      <section class="intro-body">
        <div>
          <slot name="contents"></slot>
        </div>
      </section>
      <button
        v-on:click="accept"
        id="js-intro-modal"
        :class="{ show: show }"
        class="btn btn-arrow close-intro modal-card-footer validated"
      >
        <span class="text">{{ buttonText || 'Continue' }}</span>
        <i class="fa fa-chevron-right"></i>
      </button>
      <div
        v-if="!doNotShowTick"
        v-on:click="doNotShowAgain"
        class="css-checkbox dark"
      >
        <input
          v-bind:value="showValue"
          type="checkbox"
          name="checkintro"
          id="checkintro"
        />
        <label for="checkintro"
          >Don't show again
          <span></span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseModalIntro',
  props: [
    'introTitle',
    'showValue',
    'show',
    'global',
    'doNotShowTick',
    'buttonText'
  ],
  watch: {
    show: {
      immediate: true,
      handler(value) {
        if (value && !this.global) {
          document.documentElement.classList.add('is-clipped')
        }
      }
    }
  },
  methods: {
    accept() {
      if (this.buttonText) {
        this.$emit('confirm')
        this.clearClipped()
      } else {
        this.close()
      }
    },
    close() {
      this.$emit('close')
      this.clearClipped()
    },
    doNotShowAgain() {
      this.$emit('doNotShowAgain')
      this.clearClipped()
    },
    clearClipped() {
      document.documentElement.classList.remove('is-clipped')
    }
  }
}
</script>

<style></style>
