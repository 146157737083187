<template>
  <div v-show="show.input" class="input-container">
    <select
      v-model="child_born_to"
      v-on:change="$emit('selected', [child_born_to, child(), stepChild()])"
      id="child_born_to"
      class="nice-select validation"
      required
    >
      <option value="" selected>Select parent/s</option>
      <option
        v-for="(value, index) in options"
        v-bind:key="index"
        v-bind:value="value"
      >
        {{ value }}
      </option>
    </select>
    <label for="child_born_to">Child Born To</label>
  </div>
</template>

<script>
export default {
  name: 'ChildBornToSelect',
  props: ['value'],
  watch: {
    value: {
      immediate: true,
      handler(newVal, oldVal) {
        this.child_born_to = newVal
      }
    }
  },
  data() {
    return {
      child_born_to: '',
      show: {
        input: true
      }
    }
  },
  computed: {
    fullName() {
      return this.$store.getters.personalDetails.full_name
    },
    options() {
      var options = []
      options.push(this.fullName)
      if (this.partner && this.partner.full_name) {
        options.push(this.partner.full_name)
        options.push(this.fullName + ' & ' + this.partner.full_name)
      }
      if (this.partner_ex && this.partner_ex.full_name) {
        options.push(this.partner_ex.full_name)
        options.push(this.fullName + ' & ' + this.partner_ex.full_name)
      }
      if (options.length === 1) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.child_born_to = options[0]
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.show.input = false
        this.$emit('selected', [
          this.child_born_to,
          this.child(),
          this.stepChild()
        ])
      }
      return options
    },
    partner() {
      return this.$store.getters.partner
    },
    partner_ex() {
      return this.$store.getters.partner_ex
    }
  },
  methods: {
    child() {
      return true
    },
    stepChild() {
      if (!this.child_born_to.includes(this.fullName)) return true
      return false
    },
    relationship() {}
  }
}
</script>
