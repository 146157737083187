<template>
  <div
    class="question-nav"
    id="js-question-nav"
    v-bind:class="{ validated: validated, center: center }"
  >
    <div class="button-wrapper">
      <button
        v-if="externalBackUrl"
        v-on:click.stop="gotoBackUrl"
        class="btn btn-medium btn-backwards btn--dark"
      >
        <i class="fa fa-chevron-left"></i>
        <span v-if="backText" class="text">{{ backText }}</span>
      </button>
      <router-link
        v-else-if="backLink"
        v-bind:to="backLink"
        tag="button"
        type="button"
        class="btn btn-medium btn-backwards btn--dark"
      >
        <i class="fa fa-chevron-left"></i>
        <span v-if="backText" class="text">{{ backText }}</span>
      </router-link>
      <button
        v-on:keyup.enter="console.log('enter key pressed')"
        v-if="externalForwardUrl"
        v-on:click.stop="gotoForwardUrl"
        class="btn btn-arrow btn-medium btn--dark"
        :disabled="forwardDisabled"
        v-bind:class="{
          'btn-forwards': externalBackUrl || backLink,
          disabled: forwardDisabled
        }"
      >
        <span class="text">{{ forwardText }}</span>
        <i v-if="checkConfirm" class="fa fa-check-double"></i>
        <i v-else class="fa fa-chevron-right"></i>
      </button>
      <router-link
        v-else-if="forwardLink"
        v-bind:to="forwardLink"
        tag="button"
        id="js-next"
        ref="jsNext"
        class="btn btn-arrow btn-medium btn--dark"
        :disabled="forwardDisabled"
        v-bind:class="{
          'btn-forwards': backLink || externalBackUrl,
          disabled: forwardDisabled
        }"
      >
        <span class="text">{{ forwardText }}</span>
        <i v-if="loading" class="fas fa-spinner fa-spin"></i>
        <i v-else-if="checkConfirm" class="fa fa-check-double"></i>

        <i v-else class="fa fa-chevron-right"></i>
      </router-link>
      <button
        v-else
        v-on:click.stop="$emit('forwardClick')"
        class="btn btn-arrow btn-medium btn--dark"
        id="js-next"
        ref="jsNext"
        :disabled="forwardDisabled"
        v-bind:class="{
          'btn-forwards': externalBackUrl || backLink,
          disabled: forwardDisabled
        }"
      >
        <span class="text">{{ forwardText }}</span>
        <i v-if="loading" class="fas fa-spinner fa-spin"></i>
        <i v-else-if="checkConfirm" class="fa fa-check-double"></i>

        <i v-else class="fa fa-chevron-right"></i>
      </button>
    </div>
    <div class="text-enter">press <strong>ENTER</strong></div>

    <router-link
      v-if="forwardLinkAlternative"
      v-bind:to="forwardLinkAlternative"
      tag="a"
      id="js-next"
      ref="jsNext"
      class="alternative-link"
      :disabled="forwardDisabled"
      v-bind:class="{ disabled: forwardDisabled }"
    >
      {{ forwardTextAlternative }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'NavQuestionButtons',
  props: [
    'validated',
    'loading',
    'backText',
    'forwardText',
    'backLink',
    'forwardLink',
    'forwardParams',
    'heading',
    'center',
    'externalForwardUrl',
    'externalBackUrl',
    'forwardDisabled',
    'checkConfirm',
    'forwardLinkAlternative',
    'forwardTextAlternative'
  ],
  methods: {
    gotoForwardUrl() {
      if (this.externalForwardUrl) {
        window.location = this.externalForwardUrl
      } else {
        this.$emit('forwardClick')
      }
    },
    gotoBackUrl() {
      window.location = this.externalBackUrl
    }
  }
}
</script>

<style scoped>
.center {
  text-align: center;
  float: none;
}

.alternative-link {
  position: relative;
  bottom: -13px;
  left: 50px;
  color: #6a6a6a;
  text-decoration: underline;
}
</style>
