<template>
  <tr v-bind:class="{ 'liability-row': liability }" class="app-table-row">
    <td class="beneficiary-name">
      <img :src="imageUrl" alt="residential" />
      <h5 class="inline-block align-middle">{{ text }}</h5>
    </td>
    <td class="beneficiary-percentage sole-joint">
      <InputSwitch :checked="initial" @toggle="save"></InputSwitch>
    </td>
  </tr>
</template>
<script>
import InputSwitch from '@/common/ui/InputSwitch'

export default {
  name: 'JointTableRow',
  props: ['text', 'imageUrl', 'initial', 'property', 'liability'],
  components: {
    InputSwitch
  },
  methods: {
    save() {
      var objectCopy = Object.assign({}, this.property)
      objectCopy[Object.keys(this.property)[0]] = !this.initial
      this.$emit('change', objectCopy)
    }
  }
}
</script>
